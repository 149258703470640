import React, { useMemo } from 'react';

import withQuery from '../FilterWithQuery';

import styles from './FilterFlatList.pcss';
import FilterFlatListItems from './FilterFlatListItems';

import { FilterFormItemInputList } from 'Components/FiltersForm/FilterForm';

interface ListProps {}

const FilterFlatList: React.FC<FilterFormItemInputList & ListProps> = (props) => {
  const { itemsQuery } = props;

  const ListComponent = useMemo(() => {
    if (itemsQuery) {
      return React.memo(withQuery(FilterFlatListItems));
    }
    return FilterFlatListItems;
  }, [itemsQuery]);

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        <ListComponent {...props} />
      </div>
    </div>
  );
};

export default FilterFlatList;
