import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { Link } from 'react-router-dom';

import styles from './Profile.pcss';

import { getCreatorData } from 'Util/creatorData';
import { getAges } from 'Util/dateCreator';
import { getContractorProfileLink } from 'Util/links';
import Text from 'Atoms/Text/Text';
import PlatformIcon from 'Molecules/PlatformIcon/PlatformIcon';
import Avatar from 'Atoms/Avatar/Avatar';
import { Profile_creator$key } from 'GraphTypes/Profile_creator.graphql';

interface Props {
  creator: Profile_creator$key;
}

const Profile: React.FC<Props> = (props) => {
  const { creator } = props;

  const data = useFragment(
    graphql`
      fragment Profile_creator on Creator {
        id
        type
        username
        profile {
          birthdate
          cities {
            name
            countryName
          }
          profilePictureFile {
            url
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
        }
        ... on YoutubeCreator {
          channel {
            name
          }
        }
        ... on TiktokCreator {
          user {
            name
          }
        }
      }
    `,
    creator
  );

  const bDate = data.profile?.birthdate;
  const cities = data.profile?.cities;
  const avatarUrl = data.profile?.profilePictureFile?.transformations?.collageThumbnailUrl;

  const city =
    cities && cities.length > 0 ? `${cities[0].name}, ${cities[0].countryName}` : undefined;

  const age = bDate ? getAges(bDate) : undefined;

  const { username } = getCreatorData(data);

  return (
    <Link to={getContractorProfileLink({ id: data.id })} className={styles.root}>
      <Avatar size="48" src={avatarUrl} />
      <div className={styles.info}>
        <Text tag="h3" weight="500" text={username} />
        <div className={styles.descr}>
          <PlatformIcon size={16} platform={data.type} className={styles.infoItem} />
          {Number(age) > 0 && <Text color="grayDog" text={age} className={styles.infoItem} />}
          {city && <Text color="grayDog" text={city} className={styles.infoItem} />}
        </div>
      </div>
    </Link>
  );
};

export default Profile;
