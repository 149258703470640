import React, { ComponentType, Suspense } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { GraphQLTaggedNode, OperationType } from 'relay-runtime';

import { FilterFormItemWithQuery } from '../FilterForm';

interface QueryProps {
  queryRef: PreloadedQuery<OperationType, Record<string, unknown>>;
  setQueryVariables: (variables: Record<string, unknown>) => void;
}

const withQueryData = <P extends object>(
  WrappedComponent: ComponentType<P>
): React.FC<P & FilterFormItemWithQuery & QueryProps> => {
  return (props: P & FilterFormItemWithQuery & QueryProps) => {
    const { itemsQuery, queryRef } = props;
    const queryData = usePreloadedQuery(itemsQuery as GraphQLTaggedNode, queryRef);
    return (
      <Suspense fallback={null}>
        <WrappedComponent {...(props as P)} queryData={queryData} />
      </Suspense>
    );
  };
};

export default withQueryData;
