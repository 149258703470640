import { graphql } from 'react-relay';

const FilterFormLanguageListQuery = graphql`
  query FilterFormLanguageListQuery {
    languages {
      id
      name
    }
  }
`;

export default FilterFormLanguageListQuery;
