import React, { useContext, useState, useEffect } from 'react';
import ls from 'Util/localStorage';
import { scroller } from 'react-scroll';

import Field from '../../components/Field/Field';
import { BriefContext } from '../../../../Brief.Context';

import styles from './ProductImage.pcss';

import UpdateProductImage from 'Modal/advertiser/UpdateProductImage/UpdateProductImage';
import { ImageData } from 'Api/uploadFile';
import { useCampaignActions } from 'Hooks/useCampaignActions';
import { amplitude } from 'Helpers/amplitude';
import Button from 'Atoms/Button/Button';
import Text from 'Components/ui/Text/Text';
import FileUploader from 'Atoms/FileUploader/FileUploader';
import Icon from 'Components/ui/Icon/Icon';
import ImageCropper from 'Modal/advertiser/ImageCropper/ImageCropper';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  campaignId: string;
  coverUrl?: string;
  width?: number | null;
  height?: number | null;
}

const ProductImage: React.FC<Props> = (props) => {
  const { campaignId, coverUrl, width, height } = props;

  const updateInfoShown = ls.get('product_image_update_warning_brief');

  const [localfileSrc, setLocalfileSrc] = useState('');

  const [briefState] = useContext(BriefContext);
  const { openDrawer, closeDrawer } = useContext(DrawerContext);

  const { showErrors, shownElementsWithPossibleError } = briefState;

  const { addNewCampaignCover } = useCampaignActions();

  const isSquare = width === height;
  const shouldUpdate = coverUrl && !isSquare;

  const handleCropClose = () => {
    closeDrawer('image-cropper');
  };

  useEffect(() => {
    if (shouldUpdate && !updateInfoShown) {
      scroller.scrollTo('productInfo', {
        duration: 500,
        smooth: true,
        offset: -100,
      });
      openDrawer('update-product-image');
    }
  }, []);

  const handleFileCrop = (imageData: ImageData) => {
    addNewCampaignCover({ imageData, campaignId, onSuccess: handleCropClose });
    amplitude.sendEvent<80>({
      id: '80',
      category: 'brief',
      name: 'product_image_load_success',
      param: {
        campaignId,
        width: imageData.width,
        height: imageData.height,
        isSquare: imageData.width === imageData.height,
      },
    });
  };

  const handleLocalFileUpdate = (src: string) => {
    setLocalfileSrc(src);
  };

  const handleCoverChoose = (files: FileList | null) => {
    if (!files || files.length === 0) return;
    const fileSrc = URL.createObjectURL(files[0]);
    setLocalfileSrc(fileSrc);
    openDrawer('image-cropper');
  };

  const error =
    showErrors && !(coverUrl && isSquare) && shownElementsWithPossibleError?.includes('cover');

  const warning = (
    <div className={styles.warning}>
      <Icon name="Info" size={16} />
      <Text type="label" msg="brief_template.field.product.image.warning" />
    </div>
  );

  return (
    <div className={styles.imageWrap}>
      <div
        className={styles.image}
        style={coverUrl ? { backgroundImage: `url(${coverUrl})` } : undefined}
      />
      <Field
        title="brief_template.field.product.image.title"
        description="brief_template.field.product.image.descr"
        additionalDescriptonEl={warning}
      >
        <div className={styles.uploaderWrap}>
          <Button
            leftIconName="download"
            msg="brief_template.field.product.image.upload"
            color="normal"
            className={error ? styles.uploadError : ''}
          />
          <FileUploader
            typeList=".png, .jpg, .jpeg"
            className={styles.uploader}
            onChange={handleCoverChoose}
          />
        </div>
        {error && (
          <Text
            type="md"
            msg={
              shouldUpdate
                ? 'brief_template.field.product.image.upload_square_error'
                : 'brief_template.field.product.image.upload_error'
            }
            className={styles.errorText}
          />
        )}
      </Field>
      <ImageCropper localfileSrc={localfileSrc} onImageChange={handleFileCrop} />
      <UpdateProductImage
        campaignId={campaignId}
        onImageUploaded={handleLocalFileUpdate}
        place="brief"
      />
    </div>
  );
};

export default ProductImage;
