import React, { useState, useEffect, useCallback, useContext, MouseEventHandler } from 'react';

import styles from './ShipmentTabItem.pcss';

import { amplitude } from 'Helpers/amplitude';
import shopifyTutorialVideo from 'Videos/ShopifyTutorial/tutorial.mp4';
import Text from 'Components/ui/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Icon from 'Components/ui/Icon/Icon';
import { useShopifyOnboarding } from 'Hooks/useShopifyOnboarding';
import { SHIPMENT } from 'Constants/general';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  section: string;
  campaignId?: string;
  hasShopifyAuth?: boolean;
  onSectionChange: (section: string) => void;
}

const ShipmentTabItem: React.FC<Props> = (props) => {
  const { hasShopifyAuth, campaignId = '', section, onSectionChange } = props;
  const { isPassed, onStatePassed } = useShopifyOnboarding();

  const isShipmentTab = section === SHIPMENT;

  const [showShipmentTutorialTooltip, setShowShipmentTutorialTooltip] = useState<boolean>(
    !isPassed('shipment_tab') && isShipmentTab
  );
  const [showShipmentTooltip, setShowShipmentTooltip] = useState<boolean>(
    !isPassed('campaign_tab') && !isShipmentTab
  );

  const { openDrawer } = useContext(DrawerContext);

  useEffect(() => {
    if (section === SHIPMENT) {
      onStatePassed('campaign_tab');
      setShowShipmentTooltip(false);
      setShowShipmentTutorialTooltip(!isPassed('shipment_tab'));
    } else {
      setShowShipmentTutorialTooltip(false);
    }
  }, [section]);

  const handleTooltipExploreClick = useCallback(() => {
    onSectionChange(SHIPMENT);
    setShowShipmentTutorialTooltip(!isPassed('shipment_tab'));
    setShowShipmentTooltip(false);
    onStatePassed('campaign_tab');
  }, []);

  const handleCloseTooltip = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e?.stopPropagation();
    setShowShipmentTooltip(false);
    onStatePassed('campaign_tab');
  }, []);

  const handleCloseTutorialClick = useCallback(() => {
    onStatePassed('shipment_tab');
    setShowShipmentTutorialTooltip(false);
  }, []);

  const handleConnectClick = () => {
    amplitude.sendEvent<316>({
      id: '316',
      category: 'shipment',
      name: 'connect_to_shopify_click',
      param: { campaign_id: campaignId },
    });
    localStorage.setItem('shopify-campaign-id', campaignId);
    openDrawer('shopify-auth');
  };

  return (
    <>
      <Tooltip
        showByDefault={showShipmentTooltip}
        id={'shipment_onboarding_tabs_tooltip'}
        clickable
        place={'top'}
        tooltipClassName={styles.tooltipContainer}
        customTooltip={
          <div className={styles.shipmentTooltip}>
            <Text msg="filter_mark.shipment.onboarding.proposal" type="md" />
            <div onClick={handleTooltipExploreClick} className={styles.actionContainer}>
              <Text msg="filter_mark.shipment.onboarding.action" type="md" />
              <div className={styles.closeIcon} onClick={handleCloseTooltip}>
                <Icon name="Close-small" />
              </div>
            </div>
          </div>
        }
      />
      {!hasShopifyAuth && (
        <Tooltip
          id={'onboarding_shipment_tutorial'}
          place={'left'}
          tooltipClassName={styles.tutorialTooltip}
          noArrow
          clickable
          customTooltip={
            <div className={styles.tutorialTooltipContent}>
              <Text type={'h1'} msg={'shipment.tutorial.title'} className={styles.tooltipTitle} />
              <Text type="md" msg={'shipment.tutorial.description'} />
              <video src={shopifyTutorialVideo} className={styles.gif} autoPlay muted loop />
              <div onClick={handleConnectClick} className={styles.link}>
                <Text type="md" msg={'shipment.tutorial.action'} />
              </div>
              <div className={styles.closeIcon} onClick={handleCloseTutorialClick}>
                <Icon name="Close-small" />
              </div>
            </div>
          }
          showByDefault={showShipmentTutorialTooltip}
        />
      )}
    </>
  );
};

export default ShipmentTabItem;
