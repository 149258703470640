import React from 'react';

import GmailSettings from '../GmailSettings/GmailSettings';
import CreatorsListSelector from '../CreatorsListSelector/CreatorsListSelector';
import SequenceSelector from '../SequenceSelector/SequenceSelector';
import TimezoneSelector from '../TimezoneSelector/TimezoneSelector';
import { OutreachSettingsTabs } from '../InviteOutreachCreators';

import styles from './OutreachSettings.pcss';

import { SequenceSelectorForOutreach_list$key } from 'GraphTypes/SequenceSelectorForOutreach_list.graphql';
import { GmailSettingsForOutreach_campaign$key } from 'GraphTypes/GmailSettingsForOutreach_campaign.graphql';

interface Props {
  campaignQuery: GmailSettingsForOutreach_campaign$key;
  query: SequenceSelectorForOutreach_list$key;
  userQuery: SequenceSelectorForOutreach_list$key;
  setActiveTab: (tab: OutreachSettingsTabs) => void;
  openedField?: string | null;
}

const OutreachSettings: React.FC<Props> = (props) => {
  const { query, userQuery, campaignQuery, setActiveTab, openedField } = props;
  return (
    <div className={styles.root}>
      <GmailSettings query={campaignQuery} />
      <CreatorsListSelector isOpened={openedField === 'creatorList'} />
      <SequenceSelector
        setActiveTab={setActiveTab}
        query={query as SequenceSelectorForOutreach_list$key}
        userQuery={userQuery}
      />
      <TimezoneSelector />
    </div>
  );
};

export default OutreachSettings;
