import React from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import styles from './DiscoveryGridSearchResultContent.pcss';
import SearchResultControls from './DiscoveryGridSearchResultControls/DiscoveryGridSearchResultControls';
import CreatorMedias from './DiscoveryGridSearchResultMedias/DiscoveryGridSearchResultMedias';

import { DiscoveryGridSearchResultContent_result$key } from 'GraphTypes/DiscoveryGridSearchResultContent_result.graphql';

interface Props {
  result: DiscoveryGridSearchResultContent_result$key;
  isHidden: boolean;
  campaignId: string;
  isAdmin: boolean;
  organizationId?: string;
  checked: boolean;
}

const DiscoveryGridSearchResultContent: React.FC<Props> = (props) => {
  const { result, campaignId, isHidden, isAdmin, organizationId, checked } = props;

  const data = useFragment(
    graphql`
      fragment DiscoveryGridSearchResultContent_result on CreatorSearchResult {
        ...DiscoveryGridSearchResultControls_result
        creator {
          type
          id
          ...DiscoveryGridSearchResultMedias_creator
        }
      }
    `,
    result
  );

  const creator = data?.creator;

  return (
    <div
      className={classnames(styles.root, {
        [styles.isHidden]: isHidden,
      })}
    >
      <CreatorMedias className={styles.medias} creator={creator || undefined} />
      <SearchResultControls
        result={data}
        isHidden={isHidden}
        campaignId={campaignId}
        loading={false}
        isAdmin={isAdmin}
        organizationId={organizationId}
        checked={checked}
      />
    </div>
  );
};

export default DiscoveryGridSearchResultContent;
