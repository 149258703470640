/**
 * @generated SignedSource<<ace76c3984a62ffc40d24d5cca8a2761>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type InviteOutreachCreatorsQuery$variables = {
  campaignId: string;
};
export type InviteOutreachCreatorsQuery$data = {
  readonly campaign: {
    readonly organization: {
      readonly " $fragmentSpreads": FragmentRefs<"SequenceSelectorForOutreach_list">;
    } | null | undefined;
    readonly platform: CampaignPlatform | null | undefined;
    readonly sampleOutreachTemplateVariables: ReadonlyArray<{
      readonly name: string;
      readonly value: string | null | undefined;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"GmailSettingsForOutreach_campaign">;
  } | null | undefined;
  readonly currentUser: {
    readonly organization: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"SequenceSelectorForOutreach_list">;
    } | null | undefined;
  } | null | undefined;
};
export type InviteOutreachCreatorsQuery = {
  response: InviteOutreachCreatorsQuery$data;
  variables: InviteOutreachCreatorsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "SequenceSelectorForOutreach_list"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "TemplateVariable",
  "kind": "LinkedField",
  "name": "sampleOutreachTemplateVariables",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "OutreachSequenceBlueprintConnection",
  "kind": "LinkedField",
  "name": "outreachSequenceBlueprints",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OutreachSequenceBlueprintEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OutreachSequenceBlueprint",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "OutreachStepBlueprintConnection",
              "kind": "LinkedField",
              "name": "steps",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OutreachStepBlueprintEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OutreachStepBlueprint",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startsAtHour",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "delayInDays",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectEventType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "OutreachTemplate",
                          "kind": "LinkedField",
                          "name": "template",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "subject",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "htmlBody",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteOutreachCreatorsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GmailSettingsForOutreach_campaign"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteOutreachCreatorsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GmailAuthorizationConnection",
                "kind": "LinkedField",
                "name": "gmailAuthorizations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GmailAuthorizationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GmailAuthorization",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reauthenticationRequired",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "availableEmails",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "availableNames",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OutreachCounters",
                            "kind": "LinkedField",
                            "name": "outreachCounters",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "todayLimit",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "todayCount",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v6/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0cbe0a6a703acc288f0d3b93c07563c2",
    "id": null,
    "metadata": {},
    "name": "InviteOutreachCreatorsQuery",
    "operationKind": "query",
    "text": "query InviteOutreachCreatorsQuery(\n  $campaignId: ID!\n) {\n  currentUser {\n    organization {\n      id\n      ...SequenceSelectorForOutreach_list\n    }\n    id\n  }\n  campaign(id: $campaignId) {\n    ...GmailSettingsForOutreach_campaign\n    organization {\n      ...SequenceSelectorForOutreach_list\n      id\n    }\n    platform\n    sampleOutreachTemplateVariables {\n      name\n      value\n    }\n    id\n  }\n}\n\nfragment GmailSettingsForOutreach_campaign on Campaign {\n  organization {\n    gmailAuthorizations {\n      edges {\n        node {\n          id\n          reauthenticationRequired\n          availableEmails\n          availableNames\n          outreachCounters {\n            todayLimit\n            todayCount\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment SequenceSelectorForOutreach_list on Organization {\n  id\n  outreachSequenceBlueprints {\n    edges {\n      node {\n        id\n        name\n        steps {\n          edges {\n            node {\n              id\n              startsAtHour\n              delayInDays\n              projectEventType\n              template {\n                id\n                subject\n                htmlBody\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "28037d48afd1d694ff5bce07739d0c0d";

export default node;
