import React, { forwardRef, InputHTMLAttributes, Ref, useCallback } from 'react';
import classnames from 'classnames';

import styles from './Checkbox.pcss';

type Props = Pick<InputProps, 'className' | 'onChange' | 'checked' | 'disabled'> & {
  rootRef?: Ref<HTMLDivElement>;
  variant?: 'normal' | 'indeterminate';
  disableRootPadding?: boolean;
  dataTest?: string;
};

const Checkbox: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    onChange,
    disabled,
    rootRef,
    variant = 'normal',
    disableRootPadding = false,
    dataTest,
    checked,
  } = props;

  const handleChange = useCallback<HandleChange>(
    (e) => {
      onChange?.(e);
    },
    [onChange]
  );

  return (
    <div
      className={classnames(styles.root, className, { [styles.noPadding]: disableRootPadding })}
      ref={rootRef}
    >
      <input
        ref={ref}
        checked={checked}
        disabled={disabled}
        type="checkbox"
        className={classnames(styles.input, { [styles.unsetLeftTop]: disableRootPadding })}
        onChange={handleChange}
        data-test={dataTest}
      />
      <div className={classnames('checkboxWrapper', styles.markerWrapper)}>
        <div
          className={classnames(styles.marker, {
            [styles.indeterminate]: variant === 'indeterminate',
          })}
        />
      </div>
    </div>
  );
});

export default Checkbox;
export { Checkbox };

// types

type InputProps = InputHTMLAttributes<HTMLInputElement>;
type HandleChange = NonNullable<InputProps['onChange']>;

export type { Props as CheckboxProps };
