import React, { PropsWithChildren, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { RelayEnvironmentProvider } from 'react-relay';
import * as amplitude from '@amplitude/analytics-browser';

import enMessages from 'Locales/en.json';
import environment from 'Api/Environment';
import GuideTourContainer from 'Containers/GuideTour';
import AppRouter from 'Containers/AppRouter/AppRouter';
import FeatureFlagsContainer from 'Containers/FeatureFlags/FeatureFlagsContainer';
import { LayoutContextProvider } from 'Templates/Layout/Layout.Context';
import track from 'Analytics/track';
import { firebaseClient } from 'Containers/Auth0/Auth0Context/utils';
import { ModalContextProvider } from 'Containers/ModalContainer/ModalContainerContext';
import { intlFormatter } from 'Helpers/intlFormatter';

interface Props {
  storyBookMode?: boolean;
}

const AppRoot: React.FC<PropsWithChildren<Props>> = (props) => {
  const { storyBookMode, children } = props;
  const [app, setApp] = useState<JSX.Element | null>(null);

  useEffect(() => {
    amplitude.init(process.env.AMPLITUDE_API_KEY, {
      defaultTracking: false,
    });
    firebaseClient.authClient.onAuthStateChanged(() => {
      setApp(<AppRouter />);
    });
  }, []);

  return (
    <RelayEnvironmentProvider environment={environment}>
      <IntlProvider locale="en" messages={enMessages} defaultRichTextElements={intlFormatter}>
        <ModalContextProvider>
          <FeatureFlagsContainer>
            <LayoutContextProvider>
              <GuideTourContainer>{storyBookMode ? children : app}</GuideTourContainer>
            </LayoutContextProvider>
          </FeatureFlagsContainer>
        </ModalContextProvider>
      </IntlProvider>
    </RelayEnvironmentProvider>
  );
};

export const StorybookRoot: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children } = props;

  return (
    <RelayEnvironmentProvider environment={environment}>
      <IntlProvider locale="en" defaultLocale="en">
        {children}
      </IntlProvider>
    </RelayEnvironmentProvider>
  );
};

export default track('frontend-app')(AppRoot);
