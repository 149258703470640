import React, { FC, Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import classnames from 'classnames';

import Warning from '../../Warning/Warning';

import styles from './CreatorFilter.pcss';
import AdminCreatorsFilters from './AdminCreatorsFilters/AdminCreatorsFilters';

import useInView from 'Hooks/useInView';
import Text from 'Components/ui/Text/Text';
import { SearchParamsQuery$data } from 'GraphTypes/SearchParamsQuery.graphql';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import {
  ParamsKitElementKeyType,
  ParamsKitElementType,
} from 'AdvertiserPage/Brief/BriefTemplate/BriefTemplateContent/CreatorsDetails/SearchParams/data';
import {
  convertBooleanToData,
  convertFromCitiesToSelectOptions,
  convertFromBooleanToSelectOptions,
  convertFromKitToRadioOptions,
  convertFromKitToRadioPercentageOptions,
  convertFromKitToSelectOptions,
  convertInputToData,
  convertPrimitiveToData,
  convertRadioOptionsToData,
  convertRadioPercentOptionsToData,
  convertSelectOptionsToData,
  isPrimitiveDirty,
  isRadioDirty,
  isSelectFilterDirty,
  validatePercentInput,
} from 'AdvertiserPage/Brief/BriefTemplate/BriefTemplateContent/CreatorsDetails/CreatorFilter/utils';
import Accordion from 'Components/ui/Accordion/Accordion';
import { CreatorFilterQuery } from 'GraphTypes/CreatorFilterQuery.graphql';
import { DEBOUNCE_WAIT_TIMEOUT } from 'Constants/general';
import { Currency } from 'Types/enums';
import { UpdateSearchQueryInput } from 'GraphTypes/UpdateSearchQueryMutation.graphql';
import Tag from 'Components/ui/Tag/Tag';
import { BriefContext } from 'AdvertiserPage/Brief/Brief.Context';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

type SuggestedFilters = Pick<
  UpdateSearchQueryInput,
  'followersFrom' | 'engagementRateFrom' | 'viewsMedianFrom'
>;
type SearchQuery = NonNullable<SearchParamsQuery$data['campaign']>['searchQuery'];

export interface Props {
  filtersState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'];
  filtersKit: ParamsKitElementType;
  campaignType: NonNullable<SearchParamsQuery$data['campaign']>['type'];
  isAdmin?: boolean;
  handleParamChange: (data: any) => void;
  platform: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['platform'];
  currency: Currency;
  campaign: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>;
  handleSuggestedFilters?: () => void;
  suggestedFilters?: SuggestedFilters & { analyticId?: number };
}

const CitiesQuery = graphql`
  query CreatorFilterQuery($textQuery: String!) {
    cities(query: $textQuery, first: 10) {
      edges {
        node {
          id
          name
          fullName
        }
      }
    }
  }
`;

export const CityRequest = ({ textQuery, setCities }) => {
  const { cities } = useLazyLoadQuery<CreatorFilterQuery>(CitiesQuery, { textQuery });
  useEffect(() => {
    setCities(cities);
  }, [cities]);
  return <div />;
};

const CreatorFilter: FC<Props> = (props) => {
  const {
    filtersState,
    filtersKit,
    campaignType,
    handleParamChange,
    platform,
    isAdmin,
    campaign,
    handleSuggestedFilters,
    suggestedFilters,
  } = props;
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const hasNewCases = hasFeatureFlag('use_cases_5389');
  const isPlatformBased = campaignType !== 'CONTENT_CREATION';
  const isTiktok = platform === 'TIKTOK';
  const isInstagram = platform === 'INSTAGRAM';
  const [creatorCitySearch, setCreatorCitySearch] = useState<string>('');
  const [creatorCities, setCreatorCities] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [errors, setErrors] = useState<Partial<Record<keyof ParamsKitElementType, string | null>>>(
    {}
  );
  const [value] = useContext(BriefContext);
  const isTikTokShop = value?.useCase === 'TIKTOK_SHOP';

  const { ref, inView } = useInView();

  const suggestedFiltersApplied = useMemo(() => {
    if (!suggestedFilters || !campaign.searchQuery || !filtersState) return false;
    let isValid = true;
    const filters: SuggestedFilters = omit(suggestedFilters, 'analyticId');
    Object.keys(filters).forEach((key: keyof typeof filters) => {
      const divider = key === 'engagementRateFrom' ? 100 : 1;
      if (Number(filters[key]) > filtersState[key as keyof SearchQuery] / divider) {
        isValid = false;
      }
    });
    return isValid;
  }, [suggestedFilters, filtersState]);

  useEffect(() => {
    if (value.useCase && isTikTokShop) {
      handleParamChange({ withTiktokShop: true });
    } else {
      handleParamChange({ withTiktokShop: null });
    }
  }, [value?.useCase]);

  useEffect(() => {
    if (handleSuggestedFilters && isMounted) {
      handleSuggestedFilters();
    }
  }, [platform, campaign.type]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleSelectChange = (newValue: string, filterName: ParamsKitElementKeyType) => {
    const newData = convertSelectOptionsToData(filtersState, filterName, filtersKit, newValue);
    handleParamChange(newData);
  };

  const handleCityChange = (newValue: string, filterName: ParamsKitElementKeyType) => {
    const newData = convertSelectOptionsToData(
      filtersState,
      filterName,
      { [filterName]: creatorCities?.edges?.map((item) => item?.node) },
      newValue
    );
    handleParamChange(newData);
  };

  const handleRadioChange = (newValue: string, filterName: ParamsKitElementKeyType) => {
    const newData = convertRadioOptionsToData(filterName, filtersKit, newValue);
    handleParamChange(newData);
  };

  const handleRadioPercentChange = (newValue: string, filterName: ParamsKitElementKeyType) => {
    const newData = convertRadioPercentOptionsToData(filterName, newValue);
    handleParamChange(newData);
  };

  const handlePrimitiveValue = debounce(
    (
      newValue: number | string,
      filterName: ParamsKitElementKeyType,
      validation?: {
        validate: (value: number | string) => boolean;
        errorMsg: string;
      }
    ) => {
      if (validation) {
        const { validate, errorMsg } = validation;
        const isValid = validate(newValue);
        setErrors((prevState) => ({ ...prevState, [filterName]: isValid ? null : errorMsg }));
        if (!isValid) {
          return;
        }
      }
      const newData = convertInputToData(newValue, filterName);
      handleParamChange(newData);
    },
    DEBOUNCE_WAIT_TIMEOUT
  );

  return (
    <div className={styles.root}>
      <Suspense fallback={null}>
        <CityRequest textQuery={creatorCitySearch} setCities={setCreatorCities} />
      </Suspense>
      <div className={styles.titleRow}>
        <Text msg="brief_template.filters.basic" type="h1" />
        {isPlatformBased && (
          <Tag color="grass" needMargin={false} msg="brief_template.filters.suggest" />
        )}
      </div>
      {isPlatformBased && (
        <div ref={ref} className={styles.audienceFilter}>
          <Text msg="brief_template.filters.audience" type="h1" className={styles.title} />
          <Text
            msg="brief_template.filters.audience.descr"
            type="md"
            className={styles.titleDescr}
          />
          {suggestedFilters && (
            <div className={classnames(styles.suggestionsTooltip, { [styles.inView]: inView })}>
              <Text type="md" msg="brief_template.filters.suggest.tooltip_1" />
              <Text
                type="md"
                msg="brief_template.filters.suggest.tooltip_2"
                className={styles.greenColored}
              />
              <Text type="md" msg="brief_template.filters.suggest.tooltip_3" />
            </div>
          )}
          <div className={styles.rowContainer}>
            <div className={styles.filterWithPercent}>
              <ProgressiveInput
                type={'checkboxSelect'}
                titleMsg="search_section.form.toggle.creatorCountries"
                accent={isPlatformBased}
                inputProps={{
                  items: convertFromKitToSelectOptions(filtersState, filtersKit, 'countries', 3),
                  withSearch: true,
                  withCounter: true,
                  placeholderMsg: 'search_section.form.default_value',
                  className: styles.selectMin,
                  handleClick: (newValue) => handleSelectChange(newValue, 'countries'),
                }}
                isDirty={isSelectFilterDirty(filtersState, 'countries')}
              />
              <ProgressiveInput
                className={styles.percentFilter}
                type="radio"
                inputProps={{
                  items: convertFromKitToRadioPercentageOptions(
                    filtersState,
                    filtersKit,
                    'audienceCountryPercentage'
                  ),
                  withSearch: true,
                  withCounter: true,
                  placeholder: '%',
                  className: classnames({
                    [styles.dirtyPercent]: isRadioDirty(filtersState, 'audienceCountryPercentage'),
                  }),
                  handleClick: (newValue) =>
                    handleRadioPercentChange(newValue, 'audienceCountryPercentage'),
                }}
                isDirty={isRadioDirty(filtersState, 'audienceCountryPercentage')}
              />
            </div>
            <div className={styles.filterWithPercent}>
              <ProgressiveInput
                type="radio"
                titleMsg={'search_section.form.gender'}
                accent={isPlatformBased}
                inputProps={{
                  items: convertFromKitToRadioOptions(
                    filtersState,
                    filtersKit,
                    'audienceGender',
                    true
                  ),
                  placeholderMsg: 'search_section.form.default_value',
                  className: styles.selectMin,
                  handleClick: (newValue) => handleRadioChange(newValue, 'audienceGender'),
                }}
                isDirty={isRadioDirty(filtersState, 'audienceGender')}
              />
              <ProgressiveInput
                className={styles.percentFilter}
                type="radio"
                inputProps={{
                  items: convertFromKitToRadioPercentageOptions(
                    filtersState,
                    filtersKit,
                    'audienceGenderPercentage'
                  ),
                  placeholder: '%',
                  className: classnames({
                    [styles.dirtyPercent]: isRadioDirty(filtersState, 'audienceGenderPercentage'),
                  }),
                  handleClick: (newValue) =>
                    handleRadioPercentChange(newValue, 'audienceGenderPercentage'),
                }}
                isDirty={isRadioDirty(filtersState, 'audienceGenderPercentage')}
              />
            </div>
            <div className={styles.filterWithPercent}>
              <ProgressiveInput
                type={'checkboxSelect'}
                titleMsg="search_section.form.age"
                accent={isPlatformBased}
                inputProps={{
                  items: convertFromKitToSelectOptions(filtersState, filtersKit, 'ageRanges'),
                  withSearch: true,
                  withCounter: true,
                  hideByAlphabet: true,
                  placeholderMsg: 'search_section.form.default_value',
                  className: styles.selectMin,
                  handleClick: (newValue) => handleSelectChange(newValue, 'ageRanges'),
                }}
                isDirty={isSelectFilterDirty(filtersState, 'ageRanges')}
              />
              <ProgressiveInput
                type="radio"
                className={styles.percentFilter}
                inputProps={{
                  items: convertFromKitToRadioPercentageOptions(
                    filtersState,
                    filtersKit,
                    'audienceAgeRangePercentage'
                  ),
                  withSearch: true,
                  withCounter: true,
                  placeholder: '%',
                  className: classnames({
                    [styles.dirtyPercent]: isRadioDirty(filtersState, 'audienceAgeRangePercentage'),
                  }),
                  handleClick: (newValue) =>
                    handleRadioPercentChange(newValue, 'audienceAgeRangePercentage'),
                }}
                isDirty={isRadioDirty(filtersState, 'audienceAgeRangePercentage')}
              />
            </div>
          </div>
        </div>
      )}
      <div>
        <Text msg="brief_template.filters.creator" type="h1" className={styles.title} />
        <Text msg="brief_template.filters.creator.descr" type="md" className={styles.titleDescr} />
        <div className={styles.rowContainer}>
          <ProgressiveInput
            type={'checkboxSelect'}
            titleMsg="search_section.form.toggle.countries"
            inputProps={{
              items: convertFromKitToSelectOptions(filtersState, filtersKit, 'creatorCountries', 3),
              withSearch: true,
              withCounter: true,
              bordered: true,
              placeholderMsg: 'search_section.form.default_value',
              handleClick: (newValue) => handleSelectChange(newValue, 'creatorCountries'),
            }}
            isDirty={isSelectFilterDirty(filtersState, 'creatorCountries')}
            className={styles.selectCheckbox}
          />
          <ProgressiveInput
            className={styles.selectCheckbox}
            type="radio"
            titleMsg="search_section.form.gender"
            inputProps={{
              items: convertFromKitToRadioOptions(filtersState, filtersKit, 'creatorGender', true),
              withSearch: true,
              withCounter: true,
              placeholderMsg: 'search_section.form.default_value',
              className: styles.selectCheckbox,
              handleClick: (newValue) => handleRadioChange(newValue, 'creatorGender'),
            }}
            isDirty={isRadioDirty(filtersState, 'creatorGender')}
          />
          <ProgressiveInput
            titleMsg="search_section.form.age"
            type="range"
            inputProps={{
              first: {
                value: convertPrimitiveToData(filtersState, 'creatorBirthdateTo'),
                handleChange: (newValue) => handlePrimitiveValue(newValue, 'creatorBirthdateTo'),
                className: styles.rangeFilterInput,
                placeholderMsg: 'general.min',
              },
              second: {
                value: convertPrimitiveToData(filtersState, 'creatorBirthdateFrom'),
                handleChange: (newValue) => handlePrimitiveValue(newValue, 'creatorBirthdateFrom'),
                className: styles.rangeFilterInput,
                placeholderMsg: 'general.max',
              },
            }}
            isDirty={
              isPrimitiveDirty(filtersState, 'creatorBirthdateFrom') ||
              isPrimitiveDirty(filtersState, 'creatorBirthdateTo')
            }
          />
        </div>
      </div>
      <div className={styles.rowContainer}>
        <ProgressiveInput
          type={'checkboxSelect'}
          titleMsg="search_section.form.categories"
          className={styles.selectCheckbox}
          accent={isPlatformBased}
          inputProps={{
            items: convertFromKitToSelectOptions(filtersState, filtersKit, 'categories'),
            withSearch: true,
            withCounter: true,
            bordered: true,
            placeholderMsg: 'search_section.form.default_value',
            handleClick: (newValue) => handleSelectChange(newValue, 'categories'),
          }}
          isDirty={isSelectFilterDirty(filtersState, 'categories')}
        />
        <ProgressiveInput
          type={'checkboxSelect'}
          titleMsg="search_section.form.language"
          className={classnames(styles.selectCheckboxItem, styles.language)}
          inputProps={{
            items: convertFromKitToSelectOptions(filtersState, filtersKit, 'creatorLanguages', 5),
            withSearch: true,
            withCounter: true,
            bordered: true,
            placeholderMsg: 'search_section.form.default_value',
            handleClick: (newValue) => handleSelectChange(newValue, 'creatorLanguages'),
          }}
          isDirty={isSelectFilterDirty(filtersState, 'creatorLanguages')}
        />
      </div>
      {isPlatformBased && (
        <div>
          <div className={styles.rowContainer}>
            <ProgressiveInput
              titleMsg="brief_template.field.creators.params.followers"
              type="range"
              inputProps={{
                first: {
                  value: convertPrimitiveToData(filtersState, 'followersFrom'),
                  handleChange: (newValue) => handlePrimitiveValue(newValue, 'followersFrom'),
                  className: styles.rangeFilterInput,
                  placeholderMsg: 'general.min',
                },
                second: {
                  value: convertPrimitiveToData(filtersState, 'followersTo'),
                  handleChange: (newValue) => handlePrimitiveValue(newValue, 'followersTo'),
                  className: styles.rangeFilterInput,
                  placeholderMsg: 'general.max',
                },
              }}
              isDirty={
                isPrimitiveDirty(filtersState, 'followersFrom') ||
                isPrimitiveDirty(filtersState, 'followersTo')
              }
              shrinkIfDirty
            />
            {isInstagram && (
              <ProgressiveInput
                titleMsg="search_section.form.realFollowers"
                tooltip={{
                  tooltipMsg: 'search_section.form.realFollowers.tooltip',
                  id: 'real_followers',
                }}
                type="input"
                inputProps={{
                  value: convertPrimitiveToData(filtersState, 'realFollowersFrom'),
                  className: styles.selectCheckbox,
                  placeholderMsg: 'search_section.form.realFollowers.from.placeholder',
                  error: !!errors.realFollowersFrom,
                  type: 'number',
                  handleChange: (newValue) =>
                    handlePrimitiveValue(newValue, 'realFollowersFrom', {
                      validate: validatePercentInput,
                      errorMsg: 'search_section.seatch_params.errors.maxPercentError',
                    }),
                }}
                isDirty={isPrimitiveDirty(filtersState, 'realFollowersFrom')}
                errorMsg={errors.realFollowersFrom}
              />
            )}
            {isTiktok && (
              <ProgressiveInput
                type="rangeSelect"
                titleMsg="creator_statistic.subtitles_median_views"
                inputProps={{
                  first: {
                    items: convertFromKitToRadioPercentageOptions(
                      filtersState,
                      filtersKit,
                      'viewsMedianFrom'
                    ),
                    placeholderMsg: 'search_section.form.default_value',
                    className: classnames(styles.medianViewsFilter, {
                      [styles.dirtyPercent]: isRadioDirty(filtersState, 'viewsMedianFrom'),
                    }),
                    handleClick: (newValue) =>
                      handleRadioPercentChange(newValue, 'viewsMedianFrom'),
                  },
                  second: {
                    items: convertFromKitToRadioPercentageOptions(
                      filtersState,
                      filtersKit,
                      'viewsMedianTo'
                    ),
                    placeholderMsg: 'search_section.form.default_value',
                    className: classnames(styles.medianViewsFilter, {
                      [styles.dirtyPercent]: isRadioDirty(filtersState, 'viewsMedianTo'),
                    }),
                    handleClick: (newValue) => handleRadioPercentChange(newValue, 'viewsMedianTo'),
                  },
                }}
                isDirty={
                  isPrimitiveDirty(filtersState, 'viewsMedianFrom') ||
                  isPrimitiveDirty(filtersState, 'viewsMedianTo')
                }
              />
            )}

            <ProgressiveInput
              titleMsg="search_section.form.er"
              type="input"
              className={styles.selectCheckboxItem}
              inputProps={{
                value: convertPrimitiveToData(filtersState, 'engagementRateFrom'),
                className: handleSuggestedFilters ? styles.erInput : styles.selectCheckbox,
                placeholderMsg: 'search_section.form.er.from.placeholder',
                error: !!errors.engagementRateFrom,
                type: 'number',
                handleChange: (newValue) =>
                  handlePrimitiveValue(newValue, 'engagementRateFrom', {
                    validate: validatePercentInput,
                    errorMsg: 'search_section.seatch_params.errors.maxPercentError',
                  }),
              }}
              isDirty={isPrimitiveDirty(filtersState, 'engagementRateFrom')}
              errorMsg={errors.engagementRateFrom}
            />
          </div>
          {handleSuggestedFilters && (
            <Warning
              error={!suggestedFiltersApplied}
              titleMsg={`brief_template.filters.suggest.${campaign.type?.toLowerCase()}.${platform?.toLowerCase()}${
                !suggestedFiltersApplied ? '.error' : ''
              }.tooltip`}
              className={styles.suggestedWarning}
            />
          )}
        </div>
      )}

      <Accordion
        shrinkTitle
        className={classnames(styles.accordion, styles.advancedAccordion)}
        items={[
          {
            title: 'brief_template.filters.advanced',
            content: (
              <div className={styles.root}>
                {isPlatformBased && (
                  <div className={styles.rowContainerSpace}>
                    <Text
                      msg="brief_template.filters.audience"
                      type="h1"
                      className={styles.title}
                    />
                    <ProgressiveInput
                      type="checkboxSelect"
                      className={styles.selectCheckbox}
                      titleMsg="search_section.form.ethnicity"
                      inputProps={{
                        placeholderMsg: 'search_section.form.default_value',
                        items: convertFromKitToSelectOptions(
                          filtersState,
                          filtersKit,
                          'audienceEthnicities'
                        ),
                        bordered: true,
                        withSearch: true,
                        withCounter: true,
                        handleClick: (newValue) =>
                          handleSelectChange(newValue, 'audienceEthnicities'),
                        hideByAlphabet: true,
                      }}
                      isDirty={isSelectFilterDirty(filtersState, 'audienceEthnicities')}
                    />
                  </div>
                )}
                <Text msg="brief_template.filters.creator" type="h1" />
                <div className={styles.rowContainer}>
                  <ProgressiveInput
                    type="checkboxSelect"
                    titleMsg="creator_statistic.geography_tab_cities"
                    className={styles.selectCheckbox}
                    inputProps={{
                      placeholderMsg: 'search_section.form.default_value',
                      items: convertFromCitiesToSelectOptions(
                        filtersState,
                        creatorCities,
                        'cities'
                      ),
                      bordered: true,
                      handleSearch: (value) => setCreatorCitySearch(value),
                      withSearch: true,
                      handleClick: (newValue) => handleCityChange(newValue, 'cities'),
                    }}
                    isDirty={isSelectFilterDirty(filtersState, 'cities')}
                  />
                  <ProgressiveInput
                    type="checkboxSelect"
                    className={styles.selectCheckbox}
                    titleMsg="search_section.form.ethnicity"
                    inputProps={{
                      items: convertFromKitToSelectOptions(
                        filtersState,
                        filtersKit,
                        'creatorEthnicities'
                      ),
                      bordered: true,
                      withSearch: true,
                      withCounter: true,
                      placeholderMsg: 'search_section.form.default_value',
                      handleClick: (newValue) => handleSelectChange(newValue, 'creatorEthnicities'),
                      hideByAlphabet: true,
                    }}
                    isDirty={isSelectFilterDirty(filtersState, 'creatorEthnicities')}
                  />
                  <ProgressiveInput
                    type="checkboxSelect"
                    className={styles.selectCheckbox}
                    titleMsg="search_section.form.animals"
                    inputProps={{
                      items: convertFromKitToSelectOptions(
                        filtersState,
                        filtersKit,
                        'creatorAnimals'
                      ),
                      bordered: true,
                      withCounter: true,
                      placeholderMsg: 'search_section.form.default_value',
                      handleClick: (newValue) => handleSelectChange(newValue, 'creatorAnimals'),
                    }}
                    isDirty={isSelectFilterDirty(filtersState, 'creatorAnimals')}
                  />
                </div>
                <div className={styles.rowContainer}>
                  <ProgressiveInput
                    type="checkboxSelect"
                    titleMsg="search_section.form.onlyCreatorsWokedwith"
                    className={styles.selectCheckbox}
                    inputProps={{
                      items: convertFromBooleanToSelectOptions(
                        filtersState,
                        filtersKit,
                        'onlyCreatorsWorkedWith'
                      ),
                      bordered: true,
                      placeholderMsg: 'search_section.form.not_selected',
                      handleClick: () =>
                        handleParamChange(
                          convertBooleanToData(filtersState, 'onlyCreatorsWorkedWith')
                        ),
                      hideByAlphabet: true,
                    }}
                    isDirty={isPrimitiveDirty(filtersState, 'onlyCreatorsWorkedWith')}
                  />
                  {isPlatformBased && (
                    <ProgressiveInput
                      type="checkboxSelect"
                      titleMsg="search_section.form.includeAmazonStorefrontLink"
                      className={styles.selectCheckbox}
                      inputProps={{
                        items: convertFromBooleanToSelectOptions(
                          filtersState,
                          filtersKit,
                          'includeAmazonStorefrontLink'
                        ),
                        bordered: true,
                        placeholderMsg: 'search_section.form.not_selected',
                        handleClick: () =>
                          handleParamChange(
                            convertBooleanToData(filtersState, 'includeAmazonStorefrontLink')
                          ),
                        hideByAlphabet: true,
                      }}
                      isDirty={isPrimitiveDirty(filtersState, 'includeAmazonStorefrontLink')}
                    />
                  )}
                  {isPlatformBased && !hasNewCases && (
                    <ProgressiveInput
                      type="checkboxSelect"
                      titleMsg="search_section.form.withTiktokShop.title"
                      className={styles.selectCheckbox}
                      inputProps={{
                        items: convertFromBooleanToSelectOptions(
                          filtersState,
                          filtersKit,
                          'withTiktokShop'
                        ),
                        bordered: true,
                        placeholderMsg: 'search_section.form.not_selected',
                        handleClick: () =>
                          handleParamChange(convertBooleanToData(filtersState, 'withTiktokShop')),
                        hideByAlphabet: true,
                      }}
                      isDirty={isPrimitiveDirty(filtersState, 'withTiktokShop')}
                    />
                  )}
                </div>
              </div>
            ),
          },
        ]}
      />
      {isAdmin && (
        <AdminCreatorsFilters
          {...props}
          onHandlePrimitiveValue={handlePrimitiveValue}
          onRadioPercentChange={handleRadioPercentChange}
        />
      )}
    </div>
  );
};

export default CreatorFilter;
