/**
 * @generated SignedSource<<09dcab816b3489233bd85c28e5453f48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilterFormCountryListQuery$variables = Record<PropertyKey, never>;
export type FilterFormCountryListQuery$data = {
  readonly countries: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type FilterFormCountryListQuery = {
  response: FilterFormCountryListQuery$data;
  variables: FilterFormCountryListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Country",
    "kind": "LinkedField",
    "name": "countries",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterFormCountryListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FilterFormCountryListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b48876f388776c6c6b11966a0a61c846",
    "id": null,
    "metadata": {},
    "name": "FilterFormCountryListQuery",
    "operationKind": "query",
    "text": "query FilterFormCountryListQuery {\n  countries {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "538f1b8666919ac9ebf51eb9d39f986d";

export default node;
