import React, { useEffect } from 'react';
import { redirect, useSearchParams } from 'react-router-dom';
import { Buffer } from 'buffer';
import { graphql } from 'relay-runtime';
import { useQueryLoader } from 'react-relay';

import styles from './CampaignInvite.pcss';
import CampaignInvite from './CampaignInvite';

import { SIGNIN_ADVERTISER_ROUTE } from 'Constants/general';
import { CampaignInviteWrapperQuery as QueryType } from 'GraphTypes/CampaignInviteWrapperQuery.graphql';
import Spinner from 'Atoms/Spinner/Spinner';

export const CampaignInviteWrapperQuery = graphql`
  query CampaignInviteWrapperQuery($token: String!) {
    projectForOffer(token: $token) {
      campaign {
        id
      }
    }
  }
`;

interface CodeAdditionalData {
  email: string;
  token: string;
}

const CampaignInviteWrapper: React.FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const [queryReference, loadQuery] = useQueryLoader<QueryType>(CampaignInviteWrapperQuery);

  useEffect(() => {
    if (code) {
      try {
        const additionalData = Buffer.from(code, 'base64').toString();
        const tokenData: CodeAdditionalData = JSON.parse(additionalData);
        if (tokenData.email && tokenData.token) {
          loadQuery({ token: tokenData.token });
        }
      } catch (e) {
        redirect(SIGNIN_ADVERTISER_ROUTE);
      }
    } else {
      redirect(SIGNIN_ADVERTISER_ROUTE);
    }
  }, [code]);

  if (!queryReference) {
    return (
      <div className={styles.preloaderContainer}>
        <Spinner />
      </div>
    );
  }
  return <CampaignInvite queryRef={queryReference} />;
};

export default CampaignInviteWrapper;
