import { graphql } from 'react-relay';

const FilterFormCountryListQuery = graphql`
  query FilterFormCountryListQuery {
    countries {
      id
      name
    }
  }
`;

export default FilterFormCountryListQuery;
