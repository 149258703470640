import compact from 'lodash/compact';

import { CREATORS, AWAITING_LIST, ARCHIVED, APPLICANTS, COMPLETED } from 'Constants/general';
import { AdvertiserProjectStage } from 'GraphTypes/CreatorCardControls_project.graphql';

const getCreatorsSectionStages = (publishingRequired: boolean): AdvertiserProjectStage[] => {
  return compact([
    'LAUNCHED',
    'CONTENT_SUBMITTED',
    publishingRequired ? 'PUBLISHING_STARTED' : null,
  ]);
};

export const getArchivedStagesList = (
  isAdminView: boolean,
  canBrowseCreators: boolean
): AdvertiserProjectStage[] => {
  const acceptedOnly = !(canBrowseCreators || isAdminView);

  return acceptedOnly
    ? ['ACCEPTED', 'LAUNCHED', 'CONTENT_SUBMITTED', 'PUBLISHING_STARTED', 'COMPLETED']
    : [
        'UNSENT',
        'UNREAD',
        'READ',
        'ACCEPTED',
        'LAUNCHED',
        'CONTENT_SUBMITTED',
        'PUBLISHING_STARTED',
        'COMPLETED',
        'REJECTED',
      ];
};

export const getStagesList = (
  section: string,
  isAdminView: boolean,
  canBrowseCreators: boolean,
  publishingRequired: boolean
): AdvertiserProjectStage[] => {
  if (section === CREATORS) {
    return getCreatorsSectionStages(publishingRequired);
  } else if (section === AWAITING_LIST) {
    return compact(['UNSENT', 'UNREAD', 'READ', 'REJECTED']);
  } else if (section === APPLICANTS) {
    return ['ACCEPTED'];
  } else if (section === ARCHIVED) {
    return getArchivedStagesList(isAdminView, canBrowseCreators);
  } else if (section === COMPLETED) {
    return ['COMPLETED'];
  }

  return [];
};

type IsWaitingListCreatedByAdmin = (listType: string, isAdmin: boolean) => boolean | null;
export const isWaitingListCreatedByAdmin: IsWaitingListCreatedByAdmin = (listType, isAdmin) => {
  const isAwaitingListSection = listType === AWAITING_LIST;

  return isAwaitingListSection && !isAdmin ? false : null;
};
