import { graphql } from 'react-relay';

const FilterFormCategoryListQuery = graphql`
  query FilterFormCategoryListQuery {
    categories {
      id
      name
    }
  }
`;

export default FilterFormCategoryListQuery;
