/**
 * @generated SignedSource<<2d535397fea6846816f11853f96fe6b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilterFormBadgeListQuery$variables = Record<PropertyKey, never>;
export type FilterFormBadgeListQuery$data = {
  readonly badges: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type FilterFormBadgeListQuery = {
  response: FilterFormBadgeListQuery$data;
  variables: FilterFormBadgeListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Badge",
    "kind": "LinkedField",
    "name": "badges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterFormBadgeListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FilterFormBadgeListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4306919c7b056139cd49e48dee5e9c76",
    "id": null,
    "metadata": {},
    "name": "FilterFormBadgeListQuery",
    "operationKind": "query",
    "text": "query FilterFormBadgeListQuery {\n  badges {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "276dc17d6f63474ba8fec43b7987f551";

export default node;
