import React, { useContext } from 'react';

import { FiltersFormContext } from '../FiltersFormContext';
import Filter from '../Filter/Filter';

import styles from './FilterBar.pcss';

interface Props {
  leftComponent?: JSX.Element;
  rightComponent?: JSX.Element;
}

const FilterBar: React.FC<Props> = (props) => {
  const { leftComponent, rightComponent } = props;
  const { filterList, isAdmin, filters } = useContext(FiltersFormContext);
  return (
    <div className={styles.root}>
      <div className={styles.leftContainer}>
        {leftComponent && <div className={styles.leftComponent}>{leftComponent}</div>}
      </div>
      {filterList?.bar.map((filter) => {
        if (filter.component) {
          return filter.component;
        }
        if (filter.onlyForAdmin && !isAdmin) {
          return null;
        }
        if (filter.relatedWith) {
          const checkedRelatedFields = Object.keys(filter.relatedWith).some((relatedField) => {
            return filters[relatedField] !== filter.relatedWith[relatedField];
          });
          if (checkedRelatedFields) {
            return null;
          }
        }
        return <Filter {...filter} key={filter.parentKey || filter.fieldKey} />;
      })}
      {rightComponent && <div className={styles.rightComponent}>{rightComponent}</div>}
    </div>
  );
};

export default React.memo(FilterBar);
