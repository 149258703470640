import React, { ChangeEvent, MutableRefObject, Suspense, useContext, useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';
import classNames from 'classnames';

import styles from './DiscoverySearchResults.pcss';
import ResultStub from './ResultStub/ResultStub';
import DiscoverySearchResults from './DiscoverySearchResults';

import { DiscoverySearchResultsContainerQuery as QueryType } from 'GraphTypes/DiscoverySearchResultsContainerQuery.graphql';
import {
  DiscoveryCreatorsContext,
  DiscoveryListViewType,
} from 'Page/advertiser/Discovery/DiscoveryCreatorsContext';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import { Checkbox } from 'Components/ui/Checkbox';

export const DiscoverySearchResultsContainerQuery = graphql`
  query DiscoverySearchResultsContainerQuery($organizationId: ID!, $query: SearchQueryInput!) {
    ...DiscoverySearchResults_organization
      @arguments(query: $query, organizationId: $organizationId)
    currentUser {
      admin
      permissions
      organization {
        id
        newClient
        churned
        subscription {
          canUsePaidSocial
          paused
        }
      }
    }
  }
`;

interface Props {
  controller: MutableRefObject<AbortController | undefined>;
}

const preloader = (
  <div className={styles.preloader}>
    <ResultStub />
    <ResultStub />
    <ResultStub />
    <ResultStub />
  </div>
);

const DiscoverySearchResultsContainer: React.FC<Props> = (props) => {
  const { controller } = props;

  const {
    queryParams: searchParams,
    organizationId,
    selectAllMode,
    setSelectAllModeStatus,
    listView,
  } = useContext(DiscoveryCreatorsContext);

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(
    DiscoverySearchResultsContainerQuery
  );

  useEffect(() => {
    if (organizationId) {
      loadQuery(
        { query: searchParams, organizationId },
        {
          fetchPolicy: 'network-only',
          // networkCacheConfig: {
          //   metadata: {
          //     signal: controller?.current?.signal,
          //   },
          // },
        }
      );
    }
  }, [organizationId, searchParams]);

  const handleSelectAllChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectAllModeStatus(e.target.checked);
  };

  const preloader = (
    <div className={styles.loadingContainer}>
      <Spinner />
    </div>
  );

  if (!organizationId || !queryReference) return null;

  return (
    <>
      <div className={styles.listHeader}>
        <Checkbox checked={selectAllMode} onChange={handleSelectAllChange} />
        {listView === DiscoveryListViewType.LIST ? (
          <div className={styles.listHeaderTextWrapper}>
            <Text
              type="md"
              className={styles.listHeaderText}
              msg="discovery.dashboard.lists.header.username"
            />
            <Text
              type="md"
              className={styles.listHeaderText}
              msg="discovery.dashboard.lists.header.followers"
            />
            <Text
              type="md"
              className={styles.listHeaderText}
              msg="discovery.dashboard.lists.header.er"
            />
            <Text
              type="md"
              className={styles.listHeaderText}
              msg="discovery.dashboard.lists.header.avg_views"
            />
            <Text
              type="md"
              className={styles.listHeaderText}
              msg="discovery.dashboard.lists.header.badges"
            />
            <Text
              type="md"
              className={classNames(styles.listHeaderText, styles.profile)}
              msg="discovery.dashboard.lists.header.actions"
            />
            <div />
          </div>
        ) : (
          <Text
            type="md"
            className={styles.listHeaderText}
            msg="discovery.dashboard.lists.header.select_all"
          />
        )}
      </div>
      <Suspense fallback={preloader}>
        <DiscoverySearchResults queryReference={queryReference} />
      </Suspense>
    </>
  );
};

export default DiscoverySearchResultsContainer;
