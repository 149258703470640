/**
 * @generated SignedSource<<1c9c80b2be9a28f3d54acce30b154e80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscoveryGridSearchResultControls_result$data = {
  readonly creator: {
    readonly id: string;
    readonly username: string;
  } | null | undefined;
  readonly " $fragmentType": "DiscoveryGridSearchResultControls_result";
};
export type DiscoveryGridSearchResultControls_result$key = {
  readonly " $data"?: DiscoveryGridSearchResultControls_result$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscoveryGridSearchResultControls_result">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiscoveryGridSearchResultControls_result",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CreatorSearchResult",
  "abstractKey": null
};

(node as any).hash = "e01a35953a9527cd03ee26e40572d0d8";

export default node;
