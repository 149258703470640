/**
 * @generated SignedSource<<ca3ff55a6d899536ac7ce90014aca9d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CreatorBadge = "HIGHLY_RESPONSIVE" | "HIGH_POTENTIAL" | "TOP_RATED" | "UGC_EXPERT" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type Platform = "CONTENT_CREATION" | "INSTAGRAM" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type DateInterval = {
  after?: any | null | undefined;
  before?: any | null | undefined;
};
export type SearchQueryInput = {
  acceptsBarter?: boolean | null | undefined;
  active?: boolean | null | undefined;
  ageRangeIds?: ReadonlyArray<string> | null | undefined;
  audienceAgeRangePercentage?: number | null | undefined;
  audienceCitiesIds?: ReadonlyArray<string> | null | undefined;
  audienceCityPercentage?: number | null | undefined;
  audienceCountryPercentage?: number | null | undefined;
  audienceEthnicityIds?: ReadonlyArray<string> | null | undefined;
  audienceGenderId?: string | null | undefined;
  audienceGenderPercentage?: number | null | undefined;
  audienceInterestPercentage?: number | null | undefined;
  businessAccountEnabled?: boolean | null | undefined;
  campaignId?: string | null | undefined;
  campaignsCompletionsCountFrom?: number | null | undefined;
  campaignsCompletionsCountTo?: number | null | undefined;
  categoryIds?: ReadonlyArray<string> | null | undefined;
  cityIds?: ReadonlyArray<string> | null | undefined;
  contentCreator?: boolean | null | undefined;
  countryIds?: ReadonlyArray<string> | null | undefined;
  creatorAnimalIds?: ReadonlyArray<string> | null | undefined;
  creatorBadgeIds?: ReadonlyArray<string> | null | undefined;
  creatorBirthdateFrom?: any | null | undefined;
  creatorBirthdateTo?: any | null | undefined;
  creatorBodyTypeIds?: ReadonlyArray<string> | null | undefined;
  creatorCountryIds?: ReadonlyArray<string> | null | undefined;
  creatorEthnicityIds?: ReadonlyArray<string> | null | undefined;
  creatorGenderId?: string | null | undefined;
  creatorHairTypeIds?: ReadonlyArray<string> | null | undefined;
  creatorIdsToExclude?: ReadonlyArray<string> | null | undefined;
  creatorLanguageIds?: ReadonlyArray<string> | null | undefined;
  creatorTagIds?: ReadonlyArray<string> | null | undefined;
  creatorTypes?: ReadonlyArray<CreatorType> | null | undefined;
  currency?: Currency | null | undefined;
  customListIds?: ReadonlyArray<string> | null | undefined;
  employed?: boolean | null | undefined;
  engagementRateFrom?: number | null | undefined;
  engagementRateTo?: number | null | undefined;
  excludeBadges?: boolean | null | undefined;
  excludeCreatorsWorkedWith?: boolean | null | undefined;
  excludeHiredMoreThanOnce?: boolean | null | undefined;
  exclusions?: ReadonlyArray<SearchQueryExclusionParams> | null | undefined;
  experienced?: boolean | null | undefined;
  favorite?: boolean | null | undefined;
  followersFrom?: number | null | undefined;
  followersTo?: number | null | undefined;
  hashtags?: ReadonlyArray<string> | null | undefined;
  highAverageRating?: boolean | null | undefined;
  includeAmazonStorefrontLink?: boolean | null | undefined;
  includeHidden?: boolean | null | undefined;
  interestIds?: ReadonlyArray<string> | null | undefined;
  limit?: number | null | undefined;
  onlyCreatorsWorkedWith?: boolean | null | undefined;
  organizationId?: string | null | undefined;
  owned?: boolean | null | undefined;
  paidSocialEnabled?: boolean | null | undefined;
  platform?: Platform | null | undefined;
  postPriceFrom?: number | null | undefined;
  postPriceTo?: number | null | undefined;
  postsReachFrom?: number | null | undefined;
  postsReachTo?: number | null | undefined;
  realFollowersFrom?: number | null | undefined;
  realFollowersTo?: number | null | undefined;
  reelsPlaysMedianFrom?: number | null | undefined;
  reelsPlaysMedianTo?: number | null | undefined;
  reliable?: boolean | null | undefined;
  reviewedAtFrom?: any | null | undefined;
  reviewedAtTo?: any | null | undefined;
  searchableForOutreach?: boolean | null | undefined;
  storiesReachFrom?: number | null | undefined;
  storiesReachTo?: number | null | undefined;
  strictlyByPrimaryCategory?: boolean | null | undefined;
  textQuery?: string | null | undefined;
  viewsMedianFrom?: number | null | undefined;
  viewsMedianTo?: number | null | undefined;
  viewsPerPostFrom?: number | null | undefined;
  viewsPerPostTo?: number | null | undefined;
  withTiktokShop?: boolean | null | undefined;
};
export type SearchQueryExclusionParams = {
  advertiserProjectStages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
  campaignId: string;
};
export type CreatorsListContainerQuery$variables = {
  archived?: boolean | null | undefined;
  badges?: ReadonlyArray<CreatorBadge> | null | undefined;
  campaignId: string;
  createdByAdmin?: boolean | null | undefined;
  draftDeadlineIntervals?: ReadonlyArray<DateInterval> | null | undefined;
  invitedByOrganizationMember?: boolean | null | undefined;
  outreach?: boolean | null | undefined;
  publicationDeadlineIntervals?: ReadonlyArray<DateInterval> | null | undefined;
  screeningQuestionIds?: ReadonlyArray<string> | null | undefined;
  searchQuery?: SearchQueryInput | null | undefined;
  shortlisted?: boolean | null | undefined;
  stages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
  textQuery?: string | null | undefined;
  withPreviousCollaborations?: boolean | null | undefined;
};
export type CreatorsListContainerQuery$data = {
  readonly campaign: {
    readonly projects: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
  } | null | undefined;
};
export type CreatorsListContainerQuery = {
  response: CreatorsListContainerQuery$data;
  variables: CreatorsListContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archived"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "badges"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "createdByAdmin"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "draftDeadlineIntervals"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "invitedByOrganizationMember"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "outreach"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "publicationDeadlineIntervals"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "screeningQuestionIds"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQuery"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shortlisted"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stages"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "textQuery"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withPreviousCollaborations"
},
v14 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "archived",
      "variableName": "archived"
    },
    {
      "kind": "Variable",
      "name": "badges",
      "variableName": "badges"
    },
    {
      "kind": "Variable",
      "name": "createdByAdmin",
      "variableName": "createdByAdmin"
    },
    {
      "kind": "Variable",
      "name": "draftDeadlineIntervals",
      "variableName": "draftDeadlineIntervals"
    },
    {
      "kind": "Variable",
      "name": "invitedByOrganizationMember",
      "variableName": "invitedByOrganizationMember"
    },
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": "LAST_ADDED"
    },
    {
      "kind": "Variable",
      "name": "outreach",
      "variableName": "outreach"
    },
    {
      "kind": "Variable",
      "name": "publicationDeadlineIntervals",
      "variableName": "publicationDeadlineIntervals"
    },
    {
      "kind": "Variable",
      "name": "screeningQuestionIds",
      "variableName": "screeningQuestionIds"
    },
    {
      "kind": "Variable",
      "name": "searchQuery",
      "variableName": "searchQuery"
    },
    {
      "kind": "Variable",
      "name": "shortlisted",
      "variableName": "shortlisted"
    },
    {
      "kind": "Variable",
      "name": "stages",
      "variableName": "stages"
    },
    {
      "kind": "Variable",
      "name": "textQuery",
      "variableName": "textQuery"
    },
    {
      "kind": "Variable",
      "name": "withPreviousCollaborations",
      "variableName": "withPreviousCollaborations"
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Project",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v15/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatorsListContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v10/*: any*/),
      (v12/*: any*/),
      (v11/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v13/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreatorsListContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "526b24fbc018521b674ccc248e73d577",
    "id": null,
    "metadata": {},
    "name": "CreatorsListContainerQuery",
    "operationKind": "query",
    "text": "query CreatorsListContainerQuery(\n  $campaignId: ID!\n  $shortlisted: Boolean\n  $textQuery: String\n  $stages: [AdvertiserProjectStage!]\n  $archived: Boolean\n  $badges: [CreatorBadge!]\n  $createdByAdmin: Boolean\n  $outreach: Boolean\n  $invitedByOrganizationMember: Boolean\n  $withPreviousCollaborations: Boolean\n  $draftDeadlineIntervals: [DateInterval!]\n  $publicationDeadlineIntervals: [DateInterval!]\n  $screeningQuestionIds: [ID!]\n  $searchQuery: SearchQueryInput\n) {\n  campaign(id: $campaignId) {\n    projects(shortlisted: $shortlisted, textQuery: $textQuery, stages: $stages, archived: $archived, badges: $badges, createdByAdmin: $createdByAdmin, invitedByOrganizationMember: $invitedByOrganizationMember, outreach: $outreach, withPreviousCollaborations: $withPreviousCollaborations, draftDeadlineIntervals: $draftDeadlineIntervals, publicationDeadlineIntervals: $publicationDeadlineIntervals, screeningQuestionIds: $screeningQuestionIds, searchQuery: $searchQuery, orderBy: LAST_ADDED) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e6b39c179cff778f50adfdd7ad196cf2";

export default node;
