import React from 'react';

import styles from './FilterFormLoader.pcss';

import Spinner from 'Atoms/Spinner/Spinner';

const FilterFormLoader: React.FC = () => {
  return (
    <div className={styles.root}>
      <Spinner size={'sm'} />
    </div>
  );
};

export default FilterFormLoader;
