import React, { useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useIntl } from 'react-intl';

import { getAvatar } from '../util';
import Message from '../MessageEvent/Message/Message';
import SimpleProjectEvent from '../SimpleProjectEvent/SimpleProjectEvent';

import { escapeText } from 'Util/textFormatter';
import { createSum } from 'Util/numberFormatter';
import { normalizeFragmentRef } from 'Util/normalizeFragmentRef';
import { CONTRACTOR } from 'Constants/general';
import { OfferAcceptanceEvent_event$key } from 'GraphTypes/OfferAcceptanceEvent_event.graphql';
import { UserType } from 'GraphTypes/ProjectEventsQuery.graphql';
import { CampaignType } from 'GraphTypes/BriefQuery.graphql';

const OfferAcceptanceEventFragment = graphql`
  fragment OfferAcceptanceEvent_event on ProjectEvent {
    createdAt
    id
    ... on OfferAcceptance {
      price
      currency
      comment
      readByAdvertiser
      readByContractor
      screeningResponses {
        edges {
          node {
            id
            booleanAnswer
            preferred
            question {
              type
              text
              preferredValue
            }
            stringAnswer
          }
        }
      }
      actor {
        __typename
        id
        ... on InstagramCreator {
          profile {
            profilePictureFile {
              url
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
          }
        }
        ... on YoutubeCreator {
          profile {
            profilePictureFile {
              url
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
          }
        }
        ... on TiktokCreator {
          profile {
            profilePictureFile {
              url
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
          }
        }
        ... on Brand {
          logo {
            ... on Image {
              transformations {
                brandLogoUrl
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  event: OfferAcceptanceEvent_event$key;
  userType?: UserType;
  campaignType?: CampaignType | null;
}

const OfferAcceptanceEvent: React.FC<Props> = (props) => {
  const { event, userType, campaignType } = props;
  const fragment = useFragment(
    OfferAcceptanceEventFragment,
    normalizeFragmentRef<OfferAcceptanceEvent_event$key>(event)
  );

  const intl = useIntl();

  const { price, currency, comment, readByAdvertiser, screeningResponses, actor } = fragment;

  const priceValue = currency ? createSum(price, currency) : '';

  const createdAt = String(fragment.createdAt);

  const isOwner = userType === CONTRACTOR;

  const userPostfix = useMemo(() => {
    if (userType === CONTRACTOR) {
      return '_creator';
    }
    return '';
  }, [campaignType, userType]);

  const hasPrice = campaignType !== 'PRODUCT_SEEDING';

  const avaSrc =
    getAvatar(actor) ?? `https://www.gravatar.com/avatar/${actor?.id}?s=180&d=mp&r=x`;

  return (
    <div>
      <SimpleProjectEvent
        createdAt={createdAt}
        titleMsg={
          hasPrice
            ? `chat.creator_has_accepted_brief${userPostfix}`
            : `chat.creator_has_accepted_brief_without_price${userPostfix}`
        }
        titleValues={{ price: priceValue }}
      />
      <Message
        id={fragment.id}
        avaSrc={avaSrc}
        createdAt={createdAt}
        comment={comment}
        isOwner={isOwner}
        isRead={!!readByAdvertiser}
      />
      {screeningResponses?.edges?.map((item) => {
        if (!item?.node) return null;
        const { id, question, stringAnswer, booleanAnswer, preferred } = item?.node;
        const { type, text } = question;
        let screeningQuestionFormatted = '';
        if (type === 'OPEN_ENDED') {
          screeningQuestionFormatted = `<strong>${escapeText(text)}</strong>\n${escapeText(
            stringAnswer
          )}`;
        } else if (type === 'BINARY') {
          const markStyle = preferred ? 'rightAnswer' : 'falseAnswer';
          const mark = userType === 'ADVERTISER' ? `<div class="${markStyle}"></div>` : '';
          screeningQuestionFormatted = `<strong>${escapeText(text)}</strong>\n${mark}${escapeText(
            booleanAnswer
              ? intl.formatMessage({ id: 'general.yes' })
              : intl.formatMessage({ id: 'general.no' })
          )}`;
        }
        return (
          <Message
            key={id}
            id={fragment.id}
            avaSrc={avaSrc}
            createdAt={createdAt}
            comment={screeningQuestionFormatted}
            isOwner={isOwner}
            isRead={!!readByAdvertiser}
          />
        );
      })}
    </div>
  );
};

export default OfferAcceptanceEvent;
