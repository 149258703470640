import React, { useState, useCallback, useContext } from 'react';

import { InviteOutreachCreatorsContextType, SequenceStep } from '../InviteOutreachCreatorsContext';

import styles from './CreateSequenceDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import InputPreset from 'Components/ui/InputPreset/InputPreset';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { useCreateOutreachSequenceBlueprintMutation } from 'Api/mutations/CreateOutreachSequenceBlueprint.Mutation';
import {
  CreateOutreachSequenceBlueprintMutation$data,
  OutreachStepBlueprintInput,
} from 'GraphTypes/CreateOutreachSequenceBlueprintMutation.graphql';

interface Props {
  organizationId: string;
  onCreateCompleted: (data?: CreateOutreachSequenceBlueprintMutation$data | Error) => void;
  sequenceSteps: SequenceStep[];
  saveEmailTemplates: InviteOutreachCreatorsContextType['saveEmailTemplates'];
}

const CreateSequenceDrawer: React.FC<Props> = (props) => {
  const { onCreateCompleted, sequenceSteps, saveEmailTemplates, organizationId } = props;
  const { closeDrawer } = useContext(DrawerContext);
  const [inputValue, setInputValue] = useState<string>('');
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [createOutreachSequenceBlueprintMutation] =
    useCreateOutreachSequenceBlueprintMutation(organizationId);

  const isFormValid = Boolean(inputValue);

  const handleInputValueChange = useCallback<HandleInputValueChange>((e) => {
    const value = e.target.value;
    setInputValue(value);
    setHasError(false);
  }, []);

  const handleInputValueReset = useCallback<HandleInputValueReset>(() => {
    setInputValue('');
    setHasError(false);
  }, []);

  const handleButtonClick = useCallback(() => {
    if (isSubmitLoading) return;
    const customListName = inputValue.trim();
    if (!customListName) {
      setHasError(true);
      setInputValue(customListName);
      return;
    }
    setIsSubmitLoading(true);
    saveEmailTemplates(sequenceSteps, (newSteps) => {
      saveSequence(customListName, newSteps);
    });
  }, [inputValue, isSubmitLoading]);

  const saveSequence = (name: string, steps: OutreachStepBlueprintInput[]) => {
    createOutreachSequenceBlueprintMutation({
      variables: {
        input: {
          name,
          steps,
        },
      },
      onCompleted: (response) => {
        onCreateCompleted(response);
        setIsSubmitLoading(false);
        closeDrawer('sequence-create');
      },
      onError: (error) => {
        onCreateCompleted(error);
        setIsSubmitLoading(false);
        closeDrawer('sequence-create');
      },
    });
  };

  const handleOpenDrawer = useCallback<NonNullable<DrawerProps['onOpen']>>(() => {
    setInputValue('');
  }, []);

  return (
    <Drawer
      removeWhenClosed
      className={styles.drawer}
      rootKey="sequence-create"
      onOpen={handleOpenDrawer}
    >
      <div className={styles.contentRoot}>
        <div>
          <TextPreset
            className={styles.titleTextPreset}
            header={<Text type="d2" msg="invite_outreach_creators.sequence_create.title" />}
          />
          <InputPreset>
            <Input
              bordered
              forceLabelShow
              name="createSequenceName"
              dataTest="input:createSequenceName"
              error={hasError}
              value={inputValue}
              className={styles.input}
              labelMsg="invite_outreach_creators.sequence_create.input.label"
              placeholderMsg="invite_outreach_creators.sequence_create.input.placeholder"
              onChange={handleInputValueChange}
              onResetValue={handleInputValueReset}
            />
          </InputPreset>
        </div>
        <ButtonPreset className={styles.submitButtonWrapper}>
          <Button
            fluid
            color="black"
            dataTest="button:createSequenceButton"
            className={styles.submitButton}
            disabled={!isFormValid}
            loading={isSubmitLoading}
            onClick={handleButtonClick}
            msg="invite_outreach_creators.sequence_create.submit_button"
          />
        </ButtonPreset>
      </div>
    </Drawer>
  );
};

export default CreateSequenceDrawer;

// types

export type { Props as CustomListCreateDrawerProps };

type DrawerProps = React.ComponentProps<typeof Drawer>;
type InputProps = React.ComponentProps<typeof Input>;
type HandleInputValueChange = NonNullable<InputProps['onChange']>;
type HandleInputValueReset = NonNullable<InputProps['onResetValue']>;
