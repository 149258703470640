import React from 'react';

import styles from './VettedCreatorsInfoText.pcss';

import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';

const VettedCreatorsInfoText: React.FC = () => {
  return (
    <div className={styles.root}>
      <Text type="label" msg="filter.form.vetted_creators.info" />
      <Icon name="Info" size={16} />
    </div>
  );
};

export default VettedCreatorsInfoText;
