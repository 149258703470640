import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import styles from './DiscoveryGridSearchResultControls.pcss';

import { getCreatorProfileLink } from 'Util/links';
import { CreatorType } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import Icon from 'Atoms/Icon/Icon';
import Button from 'Atoms/Button/Button';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { DiscoveryCreatorsContext } from 'Page/advertiser/Discovery/DiscoveryCreatorsContext';
import { DiscoveryGridSearchResultControls_result$key } from 'GraphTypes/DiscoveryGridSearchResultControls_result.graphql';

interface Props {
  result: DiscoveryGridSearchResultControls_result$key;
  campaignId: string;
  isHidden: boolean;
  creatorType?: CreatorType | null;
  loading: boolean;
  isAdmin: boolean;
  organizationId?: string;
  checked: boolean;
}

const DiscoveryGridSearchResultControls: React.FC<Props> = (props) => {
  const { result, checked } = props;

  const { removeCreatorFromList, addCreatorToList } = useContext(DiscoveryCreatorsContext);

  const data = useFragment(
    graphql`
      fragment DiscoveryGridSearchResultControls_result on CreatorSearchResult {
        creator {
          id
          username
        }
      }
    `,
    result
  );

  const creatorId = data?.creator?.id;
  const link = getCreatorProfileLink({ creatorId });

  if (!creatorId) return null;

  const handleAddClick = () => {
    if (!creatorId) return;
    if (checked) {
      removeCreatorFromList(creatorId);
    } else {
      addCreatorToList({
        id: creatorId,
        name: data.creator.username,
      });
    }
  };

  return (
    <div className={`${styles.root}`}>
      <div className={styles.back} />
      <div className={styles.actions}>
        <div onClick={handleAddClick} className={classnames(styles.statusIcon, styles.add)}>
          <Tooltip
            id={`addToMediaplan_${creatorId}`}
            tooltipMsg={checked ? undefined : 'creator_card.mediaplan.add'}
            place="top"
          >
            <Icon name={checked ? 'check' : 'add'} color="white" />
          </Tooltip>
        </div>
      </div>
      <SmartLink path={link} target="_blank" className={styles.link}>
        <Button color="normal" theme="dark" msg="browse_creators.mediaplan_view_profile" />
      </SmartLink>
    </div>
  );
};

export default DiscoveryGridSearchResultControls;
