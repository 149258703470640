import React, { useState, useContext, ChangeEvent, Suspense, useEffect } from 'react';
import classnames from 'classnames';

import styles from './SelectCustomListForCreator.pcss';
import CustomListForCreatorDrawer from './CustomListForCreatorDrawer';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import InputPreset from 'Components/ui/InputPreset/InputPreset';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import Spinner from 'Atoms/Spinner/Spinner';

interface Props {
  drawerProps?: Partial<Omit<DrawerProps, 'children'>>;
  handleAddCreatorsToList: (listIds: string[]) => void;
  preselectedListId?: string;
}

const listPreloader = (
  <div className={styles.loaderWrapper}>
    <Spinner />
  </div>
);

const SelectCustomListForCreatorDrawer: React.FC<Props> = (props) => {
  const { drawerProps, handleAddCreatorsToList, preselectedListId } = props;
  const { openDrawer, closeDrawer } = useContext(DrawerContext);
  const [filterValue, setFilterValue] = useState<string>('');
  const [selectedLists, setSelectedLists] = useState<string[]>([]);

  useEffect(() => {
    setSelectedLists(preselectedListId ? [preselectedListId] : []);
  }, [preselectedListId]);

  const handleInputFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  const handleInputFilterReset = () => {
    setFilterValue('');
  };

  const handleAddCreatorsToListClick = () => {
    handleAddCreatorsToList(selectedLists);
    closeDrawer('select-custom-list-for-creator');
  };

  const handleCreateNewListClick = () => {
    openDrawer('custom-list-create-for-creator');
  };

  return (
    <Drawer
      rootKey="select-custom-list-for-creator"
      {...drawerProps}
      removeWhenClosed
      className={classnames(styles.drawer, drawerProps?.className)}
    >
      <div className={styles.contentRoot}>
        <div className={styles.content}>
          <Text type="d2" className={styles.titleText} msg={'custom_list_add_modal.title'} />
          <InputPreset>
            <Input
              name="customListAddCreatorName"
              dataTest="input:customListAddCreatorName"
              rightIcon="Search-loop"
              value={filterValue}
              className={styles.input}
              placeholderMsg="custom_list_add_modal.input.placeholder"
              onChange={handleInputFilterChange}
              onResetValue={handleInputFilterReset}
            />
          </InputPreset>
          <Suspense fallback={listPreloader}>
            <CustomListForCreatorDrawer
              selectedLists={selectedLists}
              setSelectedLists={setSelectedLists}
              filterValue={filterValue}
            />
          </Suspense>
        </div>
        <div>
          <ButtonPreset className={styles.submitButtonWrapper}>
            <Button
              fluid
              color="black"
              dataTest="button:addCreatorToCustomList"
              className={styles.submitButton}
              disabled={!selectedLists.length}
              loading={false}
              onClick={handleAddCreatorsToListClick}
              msg="custom_list_add_modal.add_button"
            />
            <Button
              fluid
              color="white"
              dataTest="button:addCreatorToNewCustomList"
              className={styles.submitButton}
              msg="custom_list_add_modal.new_list_button"
              onClick={handleCreateNewListClick}
            />
          </ButtonPreset>
        </div>
      </div>
    </Drawer>
  );
};

export default SelectCustomListForCreatorDrawer;

// types

export type { Props as CustomListCreateDrawerProps };

type DrawerProps = React.ComponentProps<typeof Drawer>;
