import React, { PropsWithChildren, useContext } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import {
  DASHBOARD_ROUTE,
  DOWNLOAD_APP_ROUTE,
  SIGNIN_ADVERTISER_ROUTE,
  SIGNIN_CREATOR_ROUTE,
  SIGNUP_ADVERTISER_ROUTE,
  SIGNUP_CREATOR_ROUTE,
  SIGNUP_COMPLETE_ADVERTISER_ROUTE,
  SIGNUP_COMPLETE_CREATOR_ROUTE,
  OUTCOME_PAGE_ROUTE,
  ADVERTISER,
} from 'Constants/general';
import { HistoryReferrerListener } from 'Hooks/useHistoryReferrer';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';
import { ButtonHandlerTypeEnum } from 'PublicPage/OutcomePage/OutcomePage';
import CreatorMobileBanner from 'Molecules/CreatorMobileBanner/CreatorMobileBanner';
import { FirebaseAuth } from 'Components/FirebaseAuth/FirebaseAuth';
import FullscreenLoader from 'Molecules/FullscreenLoader/FullscreenLoader';

const SessionRoute: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { isRegisteredUser, isAuthenticatedUser } = useContext(Auth0Context);
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const location = useLocation();
  const isInvitedByOutreach = Boolean(sessionStorage.getItem('outreachToken'));

  const signInRoutes = new Set([SIGNIN_ADVERTISER_ROUTE, SIGNIN_CREATOR_ROUTE]);
  const signUpRoutes = new Set([SIGNUP_ADVERTISER_ROUTE, SIGNUP_CREATOR_ROUTE]);
  const signUpCompleteRoutes = new Set([
    SIGNUP_COMPLETE_ADVERTISER_ROUTE,
    SIGNUP_COMPLETE_CREATOR_ROUTE,
  ]);
  const creatorSignInSignUpRoutes = new Set([SIGNIN_CREATOR_ROUTE, SIGNUP_CREATOR_ROUTE]);
  const advertiserSignInSignUpRoutes = new Set([SIGNIN_ADVERTISER_ROUTE, SIGNUP_ADVERTISER_ROUTE]);

  if (isAuthenticatedUser === null) {
    return <FullscreenLoader />;
  }

  const errorParam = searchParams.get('error');

  if (!isAuthenticatedUser && errorParam) {
    const sp = new URLSearchParams(searchParams);
    sp.set('status', 'error');
    sp.set('handler', ButtonHandlerTypeEnum.BackToSignIn);
    return <Navigate to={`${OUTCOME_PAGE_ROUTE}?${sp.toString()}`} />;
  }

  if (!isAuthenticatedUser && signInRoutes.has(pathname)) {
    if (pathname === SIGNIN_ADVERTISER_ROUTE) {
      return <FirebaseAuth />;
    }
    if (pathname === SIGNIN_CREATOR_ROUTE) {
      if (isMobile) return <CreatorMobileBanner />;
      return <Navigate to={DOWNLOAD_APP_ROUTE} />;
    }
  }

  if (isAuthenticatedUser && isRegisteredUser === false && pathname === SIGNUP_CREATOR_ROUTE) {
    return <FirebaseAuth userType="CONTRACTOR" />;
  }

  if (!isAuthenticatedUser && signUpRoutes.has(pathname)) {
    const popupParam = searchParams.get('popup');
    const popup = Boolean(popupParam);
    if (pathname === SIGNUP_ADVERTISER_ROUTE) {
      return (
        <Navigate
          to={SIGNIN_ADVERTISER_ROUTE}
          state={{ isInvite: true, popup, userType: ADVERTISER }}
        />
      );
    }
    if (pathname === SIGNUP_CREATOR_ROUTE) {
      return <FirebaseAuth userType="CONTRACTOR" />;
    }
    return null;
  }

  if (isAuthenticatedUser && isRegisteredUser === false && !signUpCompleteRoutes.has(pathname)) {
    if (advertiserSignInSignUpRoutes.has(pathname)) {
      return <Navigate to={`${SIGNUP_COMPLETE_ADVERTISER_ROUTE}${location?.search}`} />;
    }
  }

  const redirectUrl = searchParams.get('redirect_url');
  if (isRegisteredUser && redirectUrl) {
    window.location.href = redirectUrl;
  }

  return isRegisteredUser && !isInvitedByOutreach ? (
    <Navigate to={DASHBOARD_ROUTE} />
  ) : (
    <HistoryReferrerListener>{children}</HistoryReferrerListener>
  );
};

export default SessionRoute;

// types

export type SearchParamsType = {
  searchParams: URLSearchParams;
  searchParamsObject: {
    error?: string;
    error_description?: string;
    state?: string;
    redirect_url?: string;
  };
};
