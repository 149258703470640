/**
 * @generated SignedSource<<2c66e8c272ff533891732b5b91f56a66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type FileType = "IMAGE" | "OTHER" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DiscoveryGridSearchResultMedias_creator$data = {
  readonly insightsMedias?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly file: {
          readonly id: string;
          readonly thumbnailUrl: string;
          readonly type: FileType;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly portfolioItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly file: {
          readonly id: string;
          readonly thumbnailUrl: string;
          readonly transformations?: {
            readonly autoUrl?: string;
            readonly collageThumbnailUrl: string;
          } | null | undefined;
          readonly type: FileType;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  };
  readonly type: CreatorType;
  readonly " $fragmentType": "DiscoveryGridSearchResultMedias_creator";
};
export type DiscoveryGridSearchResultMedias_creator$key = {
  readonly " $data"?: DiscoveryGridSearchResultMedias_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscoveryGridSearchResultMedias_creator">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "last",
    "value": 9
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collageThumbnailUrl",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "file",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiscoveryGridSearchResultMedias_creator",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "PortfolioItemConnection",
      "kind": "LinkedField",
      "name": "portfolioItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PortfolioItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PortfolioItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "file",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v0/*: any*/),
                    (v3/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ImageTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Image",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "VideoTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "autoUrl",
                              "storageKey": null
                            },
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Video",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "portfolioItems(last:9)"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "TiktokInsightsMediaConnection",
          "kind": "LinkedField",
          "name": "insightsMedias",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TiktokInsightsMediaEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TiktokInsightsMedia",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "insightsMedias(last:9)"
        }
      ],
      "type": "TiktokCreator",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "InstagramInsightsMediaConnection",
          "kind": "LinkedField",
          "name": "insightsMedias",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramInsightsMediaEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InstagramInsightsMedia",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "insightsMedias(last:9)"
        }
      ],
      "type": "InstagramCreator",
      "abstractKey": null
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};
})();

(node as any).hash = "330200b0898cedc0b5ec490222a52891";

export default node;
