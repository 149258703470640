import React, { useEffect, useMemo, useState, useCallback, forwardRef } from 'react';
import type { Ref, FocusEventHandler, ChangeEventHandler, InputHTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import IconNew from '../Icon/Icon';
import Text from '../Text/Text';
import { IconName } from '../types';

import styles from './Input.pcss';

type InputPlaceholder = string;
type InputErrorState = boolean;
type InputFluid = boolean;
type InputHandleChange = (text: string) => void;
type InputSqueezed = boolean;
type InputBorderedState = boolean;

export interface Props extends InputPropsType {}

export type InputPropsType = {
  placeholderMsg?: InputPlaceholder;
  error?: InputErrorState;
  fluid?: InputFluid;
  handleChange?: InputHandleChange;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  squeezed?: InputSqueezed;
  label?: InputPlaceholder;
  labelMsg?: InputPlaceholder;
  bordered?: InputBorderedState;
  borderless?: InputBorderedState;
  withCounter?: boolean;
  active?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onResetValue?: () => void;
  autoFocus?: boolean;
  leftIcon?: IconName;
  rightIcon?: IconName;
  rightSymbol?: string | JSX.Element;
  hideCloseIcon?: boolean;
  forceLabelShow?: boolean;
  dataTest?: string;
} & Partial<InputHTMLAttributes<HTMLInputElement>>;

const Input: React.FC<Props> = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    placeholderMsg,
    error,
    fluid,
    handleChange,
    squeezed,
    labelMsg,
    bordered,
    borderless,
    inputClassName,
    labelClassName,
    hideCloseIcon,
    withCounter,
    leftIcon,
    rightIcon,
    rightSymbol,
    forceLabelShow,
    dataTest,
    active,
    ...nativeProps
  } = props;

  const {
    placeholder,
    value,
    type,
    pattern,
    className,
    label,
    maxLength,
    inputRef,
    onFocus,
    onBlur,
    onChange,
    onResetValue,
    autoFocus,
    required,
    disabled,
    name,
    id,
  } = nativeProps;

  const intl = useIntl();
  const [currentValue, setCurrentValue] = useState(value);
  const [hasError, setErrorStatus] = useState(error);

  const usedPlaceholder = useMemo<string>(() => {
    if (placeholderMsg) {
      const result = intl.formatMessage({ id: placeholderMsg });
      if (result) {
        return result;
      }
    } else if (placeholder) {
      return placeholder;
    }

    return '';
  }, [placeholder, placeholderMsg]);

  useEffect(() => {
    if (!value) {
      setCurrentValue('');
    } else {
      setCurrentValue(value);
    }
  }, [value]);

  useEffect(() => {
    setErrorStatus(error);
  }, [error]);

  const resetValue = () => {
    setCurrentValue('');
    onResetValue?.();
    if (handleChange) {
      handleChange('');
    }
  };

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.target.validity.valid || !pattern) {
        setCurrentValue(e.target.value);
        onChange?.(e);
        handleChange?.(e.target.value);
      }
    },
    [handleChange, onChange]
  );

  const valueLength = useMemo(() => {
    if (typeof currentValue === 'string') {
      return currentValue.length;
    } else if (typeof currentValue === 'number') {
      return currentValue.toString().length;
    }
    return 0;
  }, [currentValue]);

  const valueLengthCounter =
    typeof maxLength === 'number' ? `${valueLength}/${maxLength}` : valueLength;

  const classList = classnames(
    styles.root,
    {
      [styles.fluid]: fluid,
      [styles.squeezed]: squeezed && !valueLength,
      [styles.withLabel]: label || labelMsg,
      [styles.bordered]: bordered,
      [styles.active]: active,
      [styles.withValue]: currentValue,
      [styles.error]: hasError,
      [styles.withCounter]: withCounter,
      [styles.disabled]: disabled,
      [styles.withLeftIcon]: leftIcon,
      [styles.borderless]: borderless,
    },
    className
  );

  return (
    <div className={classList} ref={ref}>
      {(((label || labelMsg) && currentValue) || forceLabelShow) && (
        <Text
          type="md"
          className={classnames(styles.label, labelClassName)}
          text={label}
          msg={labelMsg}
        />
      )}
      {leftIcon && (!currentValue || valueLength === 0) && (
        <div className={styles.icon}>
          <IconNew name={leftIcon} className={styles.leftIcon} />
        </div>
      )}
      <input
        {...nativeProps}
        autoFocus={autoFocus}
        type={type}
        pattern={pattern}
        value={currentValue === null ? undefined : currentValue}
        onChange={handleInputChange}
        placeholder={usedPlaceholder}
        className={classnames(classList, inputClassName)}
        maxLength={maxLength}
        required={required}
        ref={inputRef}
        onFocus={onFocus}
        onBlur={onBlur}
        name={name}
        id={id}
        disabled={disabled}
        data-test={dataTest}
      />
      {rightIcon && (!currentValue || valueLength === 0) && (
        <div className={styles.icon}>
          <IconNew name={rightIcon} className={styles.rightIcon} />
        </div>
      )}
      {rightSymbol && <div className={styles.icon}>{rightSymbol}</div>}
      {currentValue && valueLength > 0 && !rightSymbol && !hideCloseIcon ? (
        <div className={styles.icon}>
          <IconNew name={'Close-small'} className={styles.resetIcon} onClick={resetValue} />
        </div>
      ) : null}
      {withCounter && <div className={styles.lengthCounter}>{valueLengthCounter}</div>}
    </div>
  );
});

export default Input;
