import React from 'react';
import classnames from 'classnames';

import styles from './Toggle.pcss';

import Text from 'Components/ui/Text/Text';
import { ToggleType } from 'Types/common';
import modsClasses from 'Util/modsClasses';
import Tooltip from 'Atoms/Tooltip/Tooltip';

const MODS = [
  'labelSide', // Caption side of toggle component, it can be: left / right
];

const Toggle: React.FC<ToggleType> = (props) => {
  const { on, msg, className, disabled, onChange, bgColor = 'black', tooltipMsg, id, size } = props;

  const handleClick = () => {
    onChange?.(!on);
  };

  const classes = modsClasses(MODS, props, styles);
  const classNameList = classnames(className, styles.wrapper, classes);

  return (
    <div className={classNameList}>
      {msg && <Text type="md" msg={msg} className={styles.title} />}
      <Tooltip id={id} tooltipMsg={tooltipMsg} place="top">
        <div
          className={classnames(
            styles.root,
            { [styles.disabled]: disabled, [styles.on]: on },
            styles[bgColor]
          )}
          style={size ? { height: size, width: size * 2 } : {}}
          onClick={disabled ? undefined : handleClick}
        >
          <div
            className={styles.pointer}
            style={size ? { height: size - 4, width: size - 4 } : {}}
          />
        </div>
      </Tooltip>
    </div>
  );
};

Toggle.defaultProps = {
  className: styles.wrapper,
  labelSide: 'left',
};

export default Toggle;
