import React, { ComponentType, Suspense, useEffect, useState } from 'react';
import { useQueryLoader } from 'react-relay';

import { FilterFormItemWithQuery } from '../FilterForm';
import FilterFormLoader from '../FilterFormLoader/FilterFormLoader';

import withQueryData from './FilterWithQueryData';

const withQuery = <P extends object>(
  WrappedComponent: ComponentType<P>
): React.FC<P & FilterFormItemWithQuery> => {
  return (props: P & FilterFormItemWithQuery) => {
    const { itemsQuery, filterQueryVariable } = props;
    const [queryRef, queryLoader] = useQueryLoader(itemsQuery);
    const [variables, setQueryVariables] = useState(
      filterQueryVariable ? { [filterQueryVariable]: '' } : {}
    );
    useEffect(() => {
      queryLoader({});
    }, []);
    useEffect(() => {
      queryLoader({ ...variables });
    }, [variables]);

    const handleChangeQueryVariables = (newVariables: { [key: string]: string }) => {
      if (variables[filterQueryVariable] !== newVariables[filterQueryVariable]) {
        setQueryVariables(newVariables);
      }
    };

    if (!queryRef) {
      return <FilterFormLoader />;
    }
    const WrappedComponentWithQueryData = React.memo(withQueryData(WrappedComponent));
    return (
      <Suspense fallback={<FilterFormLoader />}>
        <WrappedComponentWithQueryData
          {...(props as P)}
          queryRef={queryRef}
          setQueryVariables={handleChangeQueryVariables}
        />
      </Suspense>
    );
  };
};

export default withQuery;
