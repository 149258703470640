import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { parse, ParseResult } from 'papaparse';
import { v4 as uuid } from 'uuid';

import {
  InviteOutreachCreatorsContextType,
  InviteOutreachCreatorsContext,
  InviteOutreachImportCsvDataRow,
  InviteOutreachTemplateData,
  InviteOutreachImportCsvDataErrorRow,
  InviteOutreachImportCsvDataRowsMetrics,
  InviteOutreachTemplateItemData,
  InviteOutreachCreatorsError,
  InviteOutreachCreatorsSource,
  InviteOutreachCreatorsSelectedLists,
  SequenceStep,
} from './InviteOutreachCreatorsContext';

import { checkEmailValid } from 'Util/validate';
import { CampaignPlatform } from 'GraphTypes/CampaignOutreachContentQuery.graphql';
import { useCreateOutreachBatchMutation } from 'Api/mutations/CreateOutreachBatch.Mutation';
import { OutreachTransport } from 'GraphTypes/CreateOutreachBatchMutation.graphql';
import {
  Locale,
  OutreachStepBlueprint_ProjectEventType,
  OutreachStepBlueprintInput,
} from 'GraphTypes/CreateOutreachSequenceBlueprintMutation.graphql';
import { useUpdateOutreachTemplateMutation } from 'Api/mutations/UpdateOutreachTemplate.Mutation';
import ErrorHandler from 'Util/errorHandler';
import { NotificationsContext } from 'Containers/Notifications/Notifications.Context';

type Props = {
  handleUploadCsv: () => void;
};

const defaultDate = new Date();
defaultDate.setHours(12);
export const sequenceDefaultEmptyStep = {
  delayInDays: 0,
  projectEventType: 'MESSAGE' as OutreachStepBlueprint_ProjectEventType,
  startsAtHour: defaultDate,
  validTemplate: false,
  template: {
    id: uuid(),
    htmlBody: '',
    subject: '',
    locale: 'EN' as Locale,
  },
};

export const MIN_SUGGESTED_CREATORS_COUNT = 2000;

const InviteOutreachCreatorsProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, handleUploadCsv } = props;
  const [createOutreachBatch, createOutreachBatchLoading] = useCreateOutreachBatchMutation();
  const [updateOutreachTemplate, updatingTemplateLoading] = useUpdateOutreachTemplateMutation();

  const { showNotification, hideNotification } = useContext(NotificationsContext);

  const [source, setSource] = useState<InviteOutreachCreatorsSource | null>(
    InviteOutreachCreatorsSource.LISTS
  );
  const [rows, setRows] = useState<InviteOutreachImportCsvDataRow[]>([]);
  const [fileError, setFileError] = useState('');
  const [fileName, setFilename] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [locale, setLocale] = useState('EN');
  const [platform, setPlatform] = useState<CampaignPlatform>('INSTAGRAM');
  const [selectedLists, setSelectedLists] = useState<InviteOutreachCreatorsSelectedLists>([]);
  const [templateData, setTemplateData] = useState<InviteOutreachTemplateData>({
    id: '',
    name: '',
  });
  const [sequenceId, setSequenceId] = useState<string | null>(null);
  const [templatePreview, setTemplatePreview] = useState<InviteOutreachTemplateItemData | null>(
    null
  );
  const [isTemplateChanged, setTemplateChangedStatus] = useState(false);
  const [transport, setTransportProvider] = useState<OutreachTransport>('GMAIL');
  const [batchId, setBatchId] = useState('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<InviteOutreachCreatorsError | null>(null);

  const [senderName, setSenderName] = useState('');
  const [senderActiveAuth, setSenderActiveAuth] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [isTemplateCreating, setCreatingTemplateStatus] = useState(false);
  const [rowMetrics, setRowMetrics] = useState<InviteOutreachImportCsvDataRowsMetrics | undefined>(
    undefined
  );
  const [sequenceList, setSequenceList] = useState<SequenceStep[]>([sequenceDefaultEmptyStep]);
  const [activeStep, setActiveStep] = useState(0);
  const [stepsWithError, setStepsWithErrorList] = useState<number[]>([]);
  const [timezone, setTimezone] = useState<number>((defaultDate.getTimezoneOffset() / 60) * -1);

  const [rowErrors, setRowErrors] = useState<InviteOutreachImportCsvDataErrorRow[]>([]);
  const selectedCreatorsCount = useRef(0);

  useEffect(() => {
    if (error) {
      showNotification({
        id: `sequence-error-${error}`,
        text: `invite_outreach_creators_modal.title.error.${error}`,
        position: 'center',
      });
    }
  }, [error]);

  useEffect(() => {
    selectedCreatorsCount.current = selectedLists.reduce(
      (creators, list) => (creators += Number(list.count)),
      0
    );
    if (
      selectedCreatorsCount.current > 0 &&
      selectedCreatorsCount.current < MIN_SUGGESTED_CREATORS_COUNT
    ) {
      hideNotification('selected-creators-count');
      showNotification({
        id: 'selected-creators-count',
        text: 'invite_outreach_creators_modal.banner.selected_creators_count',
        position: 'center',
        color: 'red',
        values: { count: selectedCreatorsCount.current },
      });
    }
  }, [selectedLists]);

  const handleParseComplete = ({ data, errors }: ParseResult<string>) => {
    if (errors.length !== 0 || data.length === 0) {
      return;
    }

    const rows: InviteOutreachImportCsvDataRow[] = [];
    const rowErrors: InviteOutreachImportCsvDataErrorRow[] = [];

    let totalCount = 0;
    let parsedCount = 0;

    data.forEach((item, index) => {
      if (item.length === 1 && item[0] === '' && data.length < 2) {
        setFileError('invite_outreach_creators_modal.file.invalid');
        return;
      }
      const itemData = item[0].split(/[,;| ]/);
      const username = itemData[0]?.trim();
      const email = itemData[1]?.trim();
      if (username && email && checkEmailValid(email)) {
        parsedCount++;
        rows.push({ username, email });
      } else {
        rowErrors.push({ index: index + 1, email, username });
      }
      totalCount++;
    });

    setRowMetrics({ totalCount, parsedCount });

    setRowErrors(rowErrors);
    setRows(rows);
  };

  const handleListChange = (files: FileList | null) => {
    if (!files) return;

    setFileError('');
    setFilename(files[0].name);
    setFileSize(files[0].size);

    parse(files[0], {
      delimiter: 'tab',
      complete: handleParseComplete,
    });
  };

  const handleFileReset = () => {
    setFilename('');
    setFileSize(0);
    setFileError('');
    setRows([]);
    setRowMetrics(undefined);
    setRowErrors([]);
    setTemplateData({
      id: '',
      name: '',
    });
    setTemplatePreview(null);
    setBatchId('');
    setProcessing(false);
    setError(null);
  };

  const handleTemplateClose = () => {
    setTemplatePreview(null);
  };

  const handleImportOutreachBatch = (campaignId: string, sequenceId?: string) => {
    setError(null);
    setProcessing(true);
    if (source === InviteOutreachCreatorsSource.LISTS) {
      // const isFavoritesUsed = selectedLists.includes('favorites');
      createBatch(campaignId, sequenceId);
    }
  };

  const createBatch = (campaignId: string, createdSequenceId?: string) => {
    const selectedListIds = selectedLists.map((item) => item.id);
    const filterredLists = selectedListIds.filter((item) => item !== 'favorites');
    const usedSequenceId = createdSequenceId || sequenceId;
    if (!usedSequenceId || !senderActiveAuth) {
      setError(InviteOutreachCreatorsError.GENERAL_ERROR);
      return;
    }
    createOutreachBatch({
      variables: {
        input: {
          campaignId,
          customListId: filterredLists[0],
          activateAfterCompletion: true,
          sequenceBlueprintId: usedSequenceId,
          transport,
          ...(transport === 'GMAIL'
            ? {
                gmailAuthorizationId: senderActiveAuth,
                senderEmail,
                senderName,
              }
            : {}),
        },
      },
      onError: (error) => {
        setProcessing(false);
        setError(InviteOutreachCreatorsError.MUTATION_ERROR);
        ErrorHandler.error('createOutreachBatch mutation failed with error', { ...error });
      },
      onCompleted: (response) => {
        if (response.createOutreachBatch?.__typename === 'CreateOutreachBatchPayload') {
          handleUploadCsv();
        } else {
          setProcessing(false);
          setError(InviteOutreachCreatorsError.MUTATION_ERROR);
          ErrorHandler.error('createOutreachBatch mutation failed', { ...response });
        }
      },
    });
  };

  const saveEmailTemplates = (
    sequenceSteps: SequenceStep[],
    callback: (steps: OutreachStepBlueprintInput[]) => void
  ) => {
    const steps: OutreachStepBlueprintInput[] = [];
    sequenceSteps.forEach((step, idx) => {
      const date = new Date(step.startsAtHour);
      const dateWithTimezone = new Date(
        `${date.getFullYear()}-${
          date.getMonth() + 1
        }-${date.getDate()} ${date.getHours()}:00:00 GMT${timezone > 0 ? '+' : ''}${timezone}00`
      );
      if (step.template && isTemplateChanged && sequenceId && step.id) {
        updateOutreachTemplate({
          variables: {
            input: {
              id: step.template.id,
              htmlBody: step.template.htmlBody,
              subject: step.template.subject,
              locale: step.template.locale,
            },
          },
          onCompleted: (response) => {
            if (response.updateOutreachTemplate?.__typename === 'UpdateOutreachTemplatePayload') {
              const savedTemplates = steps.push({
                delayInDays: step.delayInDays || 0,
                projectEventType: 'MESSAGE',
                startsAtHour: dateWithTimezone,
                templateId: response.updateOutreachTemplate.outreachTemplate.id,
              });
              if (savedTemplates === sequenceSteps.length) {
                callback(
                  steps.map((step, index) => {
                    return {
                      ...step,
                      projectEventType: (index === 0
                        ? 'OFFER'
                        : 'MESSAGE') as OutreachStepBlueprint_ProjectEventType,
                    };
                  })
                );
              }
            } else {
              ErrorHandler.error('updateOutreachTemplate mutation failed', { ...response });
              setError(InviteOutreachCreatorsError.GENERAL_ERROR);
            }
          },
          onError: (error) => {
            ErrorHandler.error('updateOutreachTemplate mutation failed with error', { ...error });
            setError(InviteOutreachCreatorsError.GENERAL_ERROR);
          },
        });
      } else {
        const savedTemplates = steps.push({
          delayInDays: step.delayInDays || 0,
          projectEventType: (idx === 0
            ? 'OFFER'
            : 'MESSAGE') as OutreachStepBlueprint_ProjectEventType,
          startsAtHour: dateWithTimezone,
          template: {
            htmlBody: step.template?.htmlBody || '',
            subject: step.template?.subject || '',
            locale: step.template?.locale || 'EN',
          },
        });
        if (savedTemplates === sequenceSteps.length) {
          callback(steps);
        }
      }
    });
  };

  const checkFormValidation = () => {
    let firstStepValidation = false;
    const transportValidation =
      transport === 'SENDGRID' || (senderEmail && senderName && transport === 'GMAIL');
    if (source === InviteOutreachCreatorsSource.LISTS) {
      firstStepValidation = selectedLists.length > 0;
    }
    const sequenceValidation = !sequenceList.find((sequence) => {
      return !sequence.validTemplate;
    });
    return Boolean(firstStepValidation && transportValidation && locale && sequenceValidation);
  };

  const isFormValid = checkFormValidation();

  const contextValue: InviteOutreachCreatorsContextType = {
    fileName,
    fileSize,
    fileError,
    locale,
    rows,
    templateData,
    transport,
    batchId,
    error,
    senderName,
    senderEmail,
    templatePreview,
    isTemplateChanged,
    setTransportProvider,
    handleFileReset,
    handleListChange,
    handleTemplateClose,
    handleImportOutreachBatch,
    handleParseComplete,
    setTemplatePreview,
    setTemplateData,
    setSenderName,
    setSenderActiveAuth,
    setSenderEmail,
    setCreatingTemplateStatus,
    setError,
    setTemplateChangedStatus,
    isFormValid,
    processing,
    isTemplateCreating,
    rowMetrics,
    rowErrors,
    setSource,
    source,
    setSelectedLists,
    selectedLists,
    platform,
    setPlatform,
    sequenceId,
    setSequenceId,
    createOutreachBatchLoading,
    sequenceList,
    setSequenceList,
    activeStep,
    setActiveStep,
    stepsWithError,
    setStepsWithErrorList,
    timezone,
    setTimezone,
    saveEmailTemplates,
    updatingTemplateLoading,
    selectedCreatorsCount: selectedCreatorsCount.current,
  };
  return (
    <InviteOutreachCreatorsContext.Provider value={contextValue}>
      {children}
    </InviteOutreachCreatorsContext.Provider>
  );
};

export { InviteOutreachCreatorsProvider };
