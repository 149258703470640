import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { DiscoveryQuery as QueryType } from 'GraphTypes/DiscoveryQuery.graphql';

import { DiscoveryCreatorsContext } from '../../DiscoveryCreatorsContext';
import { DiscoveryQuery } from '../DiscoverySearchSection';

import styles from './DiscoverySearchContent.pcss';
import DiscoverySearchResultsContainer from './SearchResults/DiscoverySearchResultsContainer';
import DiscoverySearchCounter from './SearchForm/SearchCounter/SearchCounter';
import DiscoverySaveListButton from './SearchForm/SaveListButton/SaveListButton';
import ListViewSwitch from './SearchForm/ListViewSwitch/ListViewSwitch';

import Spinner from 'Atoms/Spinner/Spinner';
import { CreatorsSearchContext } from 'Page/advertiser/Mediaplan/SearchSection/CreatorsSearch.Context';
import FilterForm from 'Components/FiltersForm/FilterForm';
import { SearchQueryInput } from 'GraphTypes/DiscoverySearchResultPaginationList.graphql';
import { FilterFormList } from 'Components/FiltersForm/utils';
import AllFiltersButton from 'Components/FiltersForm/AllFiltersButton';
import VettedCreatorsInfoText from 'Components/FiltersForm/VettedCreatorsInfoText/VettedCreatorsInfoText';
import FilterFormDivider from 'Components/FiltersForm/FilterFormDivider';

const discoverySearchFormFilters = {
  rows: [
    [
      FilterFormList.textQuery,
      FilterFormList.creatorTypes,
      FilterFormList.creatorCountryIds,
      FilterFormList.creatorLanguageIds,
      FilterFormList.creatorBirthdate,
      FilterFormList.categoryIds,
      {
        component: <AllFiltersButton />,
      },
    ],
  ],
  drawer: {
    tabs: [
      {
        title: 'filter.form.tabs.creator',
        icon: 'Profile',
        items: [
          FilterFormList.engagementRate,
          FilterFormList.followers,
          FilterFormList.realFollowers,
          FilterFormList.viewsPerPost,
          FilterFormList.creatorGenderId,
          FilterFormList.creatorEthnicityIds,
          {
            component: <FilterFormDivider />,
          },
          {
            component: <VettedCreatorsInfoText />,
          },
          FilterFormList.creatorAffiliates,
          FilterFormList.creatorBadgeIds,
          FilterFormList.creatorHairTypeIds,
          FilterFormList.creatorAnimalIds,
          FilterFormList.creatorBodyTypeIds,
          FilterFormList.creatorTagIds,
          FilterFormList.campaignsCompletionsCount,
        ],
      },
      {
        title: 'filter.form.tabs.audience',
        icon: 'Group-users',
        items: [
          FilterFormList.audienceGenderId,
          FilterFormList.ageRangeIds,
          FilterFormList.countryIds,
          FilterFormList.cityIds,
          FilterFormList.audienceEthnicityIds,
        ],
      },
    ],
  },
  bar: [FilterFormList.collaborated, /*FilterFormList.unlisted,*/ FilterFormList.vetted],
};

interface Props {
  queryReference: PreloadedQuery<QueryType, Record<string, unknown>>;
}

const DiscoverySearchContent: React.FC<Props> = (props) => {
  const { queryReference } = props;
  const controller = useRef<AbortController>();
  const { state: creatorsSearchState } = useContext(CreatorsSearchContext);

  const { updateQueryParams, setOrganizationId, setAdminStatus, setListData, counter } =
    useContext(DiscoveryCreatorsContext);
  const data = usePreloadedQuery<QueryType>(DiscoveryQuery, queryReference);

  const organizationId = data.currentUser?.organization?.id;

  useEffect(() => {
    if (organizationId) {
      setOrganizationId(data.currentUser?.organization?.id);
    }
  }, [organizationId]);

  useEffect(() => {
    setAdminStatus(data.currentUser.admin);
  }, [data.currentUser.admin]);

  useEffect(() => {
    setListData(data.customList);
  }, [data.customList]);

  const handleNewParamsChange = useCallback((params: SearchQueryInput) => {
    updateQueryParams(params);
  }, []);

  const preloader = (
    <div className={styles.loadingContainer}>
      <Spinner />
    </div>
  );

  return (
    <>
      {counter.total === null ? preloader : null}
      {counter.total === null ? null : (
        <FilterForm
          withParams
          isAdmin={data.currentUser.admin}
          filters={discoverySearchFormFilters}
          defaultFilters={{
            searchableForOutreach: true,
            owned: false,
          }}
          onParamsChange={handleNewParamsChange}
          leftBarComponent={<DiscoverySearchCounter />}
          rightBarComponent={
            <div className={styles.barItemRow}>
              <ListViewSwitch />
              <DiscoverySaveListButton />
            </div>
          }
        />
      )}

      <DiscoverySearchResultsContainer controller={controller} />
    </>
  );
};

export default DiscoverySearchContent;
