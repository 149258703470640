import React, { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import styles from './FilterFlagsList.pcss';

import { FilterFormItemInputList } from 'Components/FiltersForm/FilterForm';
import Text from 'Components/ui/Text/Text';
import {
  FiltersFormContext,
  FiltersFormContextType,
} from 'Components/FiltersForm/FiltersFormContext';

interface ListProps {}

const FilterFlagsList: React.FC<FilterFormItemInputList & ListProps> = (props) => {
  const { fields } = props;
  const { filters, setFilterValueByKey } = useContext<FiltersFormContextType>(FiltersFormContext);
  const currentValues = useMemo(() => fields.map((field) => filters[field.fieldKey]), [filters]);
  const [value, setValue] = useState<boolean[]>(currentValues);

  useEffect(() => {
    setValue(currentValues);
  }, [currentValues]);

  const handleChangeValue = (key: string, value: boolean, index: number, visibleValue: string) => {
    setFilterValueByKey(key, value, value ? visibleValue : undefined);
    setValue((prev) => {
      const newValue = [...prev];
      newValue[index] = value;
      return newValue;
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {fields.map((item, index) => {
          const isActive = value[index];

          const handleSelect = () => {
            handleChangeValue(item.fieldKey, !isActive, index, item.name);
          };
          return (
            <div
              key={item.id}
              className={classNames(styles.listItem, { [styles.active]: isActive })}
              onClick={handleSelect}
            >
              <Text type="md" text={item.name} className={styles.listItemText} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterFlagsList;
