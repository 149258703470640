/**
 * @generated SignedSource<<53d971ea7e39450a4b2ea1a90d305d37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DiscoveryGridSearchResultContent_result$data = {
  readonly creator: {
    readonly id: string;
    readonly type: CreatorType;
    readonly " $fragmentSpreads": FragmentRefs<"DiscoveryGridSearchResultMedias_creator">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"DiscoveryGridSearchResultControls_result">;
  readonly " $fragmentType": "DiscoveryGridSearchResultContent_result";
};
export type DiscoveryGridSearchResultContent_result$key = {
  readonly " $data"?: DiscoveryGridSearchResultContent_result$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscoveryGridSearchResultContent_result">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiscoveryGridSearchResultContent_result",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DiscoveryGridSearchResultControls_result"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DiscoveryGridSearchResultMedias_creator"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CreatorSearchResult",
  "abstractKey": null
};

(node as any).hash = "faa6e4bf2a6e687100b4ece0f4e00711";

export default node;
