/**
 * @generated SignedSource<<0901408200fc564c1c04cc671b9c08cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilterFormPetsListQuery$variables = Record<PropertyKey, never>;
export type FilterFormPetsListQuery$data = {
  readonly animals: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type FilterFormPetsListQuery = {
  response: FilterFormPetsListQuery$data;
  variables: FilterFormPetsListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Animal",
    "kind": "LinkedField",
    "name": "animals",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterFormPetsListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FilterFormPetsListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0b12e8b2da30b91a910b4233f6158769",
    "id": null,
    "metadata": {},
    "name": "FilterFormPetsListQuery",
    "operationKind": "query",
    "text": "query FilterFormPetsListQuery {\n  animals {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "8a3a726d8d994d56ec2653441328b0bd";

export default node;
