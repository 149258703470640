import React, { ReactNode } from 'react';

import colors from 'Styles/vars/colors.css';

export const intlFormatter = {
  b: (chunks: ReactNode[]) => <b>{chunks}</b>,
  br: () => <br />,
  p: (chunks: ReactNode[]) => <p>{chunks}</p>,
  s: (chunks: ReactNode[]) => <s>{chunks}</s>,
  colorRed: (chunks: React.ReactNode[]) => (
    <span style={{ color: colors.colorError100 }}>{chunks}</span>
  ),
};
