import React, { useEffect, useState } from 'react';

import styles from './ClientMobileInfoWidget.pcss';

import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import QRCodeSrc from 'Images/general/app-QR-code.svg';
import TextButton from 'Components/ui/TextButton/TextButton';
import { useSessionStore } from 'Hooks/useSessionStore';
import { useLocalStore } from 'Hooks/useLocalStore';
import { amplitude } from 'Helpers/amplitude';
import { LocalStoreType, SessionStoreType } from 'Types/externalStore';

interface Props {}

const ClientMobileInfoWidget: React.FC<Props> = () => {
  const [sessionData, setSessionData] = useSessionStore(
    'mobileWidget'
  ) as SessionStoreType['mobileWidget'];
  const [localData, setLocalData] = useLocalStore('mobileWidget') as LocalStoreType['mobileWidget'];
  const [isOpen, setIsOpen] = useState(!sessionData.showedInSession);

  useEffect(() => {
    if (!sessionData.show || localData?.neverShow) return;
    if (
      (localData.lastShow && localData.lastShow + 604800000 < Date.now()) ||
      !localData.lastShow
    ) {
      setLocalData({ lastShow: Date.now(), showCount: (localData.showCount || 0) + 1 });
      setSessionData({ showedInSession: true });
      amplitude.sendEvent<512>({
        id: '512',
        category: 'mobile_app_widget',
        name: 'show',
      });
    }
  }, [sessionData.show]);

  if (
    !sessionData.show ||
    localData?.neverShow ||
    (localData?.showCount && localData?.showCount > 3)
  ) {
    return null;
  }

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDontShowClick = () => {
    amplitude.sendEvent<513>({
      id: '513',
      category: 'mobile_app_widget',
      name: 'do_not_show_click',
    });
    setLocalData({ neverShow: true });
  };

  return (
    <div onClick={handleToggle} className={styles.root}>
      <Icon name="Info" size={32} color="white" />
      <div className={styles.counter}>
        <Text type="md" text="1" />
      </div>
      {isOpen && (
        <div className={styles.widgetContainer}>
          <div className={styles.widget}>
            <Text type="md" msg="client_mobile_widget.title" />
            <Text type="md" msg="client_mobile_widget.app_description" />
            <img src={QRCodeSrc} loading="lazy" className={styles.codeImg} />
            <TextButton
              msg="client_mobile_widget.close_button"
              className={styles.closeButton}
              onClick={handleDontShowClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientMobileInfoWidget;
