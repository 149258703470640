/**
 * @generated SignedSource<<f83a356eb417fed747a27c543e818690>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilterFormGenderListQuery$variables = Record<PropertyKey, never>;
export type FilterFormGenderListQuery$data = {
  readonly genders: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type FilterFormGenderListQuery = {
  response: FilterFormGenderListQuery$data;
  variables: FilterFormGenderListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "currency",
        "value": "USD"
      }
    ],
    "concreteType": "Gender",
    "kind": "LinkedField",
    "name": "genders",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": "genders(currency:\"USD\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterFormGenderListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FilterFormGenderListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "24852d7bf4c48b73a1886d546470a459",
    "id": null,
    "metadata": {},
    "name": "FilterFormGenderListQuery",
    "operationKind": "query",
    "text": "query FilterFormGenderListQuery {\n  genders(currency: USD) {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "65a4705d282870bbc0d933bf44972177";

export default node;
