/**
 * @generated SignedSource<<e32eb90eb357b2512ff46bdd466ce294>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CustomListForCreatorDrawerQuery$variables = {
  textQuery?: string | null | undefined;
};
export type CustomListForCreatorDrawerQuery$data = {
  readonly currentUser: {
    readonly organization: {
      readonly customLists: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly creators: {
              readonly totalCount: number;
            } | null | undefined;
            readonly id: string;
            readonly name: string | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly favoriteCreators: {
        readonly totalCount: number;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CustomListForCreatorDrawerQuery = {
  response: CustomListForCreatorDrawerQuery$data;
  variables: CustomListForCreatorDrawerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "textQuery"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "textQuery",
      "variableName": "textQuery"
    }
  ],
  "concreteType": "CustomListConnection",
  "kind": "LinkedField",
  "name": "customLists",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomListEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomList",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CreatorConnection",
              "kind": "LinkedField",
              "name": "creators",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CreatorConnection",
  "kind": "LinkedField",
  "name": "favoriteCreators",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomListForCreatorDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomListForCreatorDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e4a8bb0c6f1190aa5efc6f1480274b0",
    "id": null,
    "metadata": {},
    "name": "CustomListForCreatorDrawerQuery",
    "operationKind": "query",
    "text": "query CustomListForCreatorDrawerQuery(\n  $textQuery: String\n) {\n  currentUser {\n    organization {\n      customLists(textQuery: $textQuery) {\n        edges {\n          node {\n            id\n            name\n            creators {\n              totalCount\n            }\n          }\n        }\n      }\n      favoriteCreators {\n        totalCount\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2f6b251d6a0301f5b03ad40fac12a74";

export default node;
