import { graphql } from 'react-relay';

const FilterFormPetsListQuery = graphql`
  query FilterFormPetsListQuery {
    animals {
      id
      name
    }
  }
`;

export default FilterFormPetsListQuery;
