import { createContext } from 'react';

import { SearchQueryInput } from 'GraphTypes/DiscoverySearchResultPaginationList.graphql';

type FiltersFormContextType = {
  filters: SearchQueryInput;
  setFilterList: (list: unknown) => void;
  isAdmin: boolean;
  setAdminStatus: (status: boolean) => void;
  filterList: unknown;
  visibleValues: Record<string, string | string[] | boolean>;
  setFilterValueByKey: (
    key: string,
    value?: string | string[] | boolean,
    visibleValues?: string | string[]
  ) => void;
  setFiltersValue: (values: unknown, visibleValues: unknown) => void;
  resetFilters: () => void;
  filtersFormDefaultValues: SearchQueryInput;
};

const FiltersFormContext = createContext<FiltersFormContextType>({} as FiltersFormContextType);

export { FiltersFormContext };

export type { FiltersFormContextType };
