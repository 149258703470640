import React, { useState, useContext, useMemo, useEffect } from 'react';

import Filter from '../Filter/Filter';
import FilterPercentSelect from '../Filter/FilterPercentSelect/FilterPercentSelect';
import { FiltersFormContext } from '../FiltersFormContext';

import styles from './FiltersFormDrawer.pcss';

import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  onCountersChange?: (value: number[]) => void;
}

const FiltersFormDrawer: React.FC<Props> = (props) => {
  const { onCountersChange } = props;
  const { resetFilters, filterList, isAdmin, visibleValues, filters } =
    useContext(FiltersFormContext);

  const [activeTab, setActiveTab] = useState<number>(0);

  const allowedTabs = useMemo(() => {
    return filterList?.drawer.tabs.filter((tab) => {
      return tab.items.some((item) => !item.onlyForAdmin) || isAdmin;
    });
  }, [filterList?.drawer.tabs, isAdmin]);

  const tabCounters = useMemo(() => {
    return allowedTabs?.map((tab) => {
      return tab.items.filter((item) => {
        let findChildValue = false;
        const notEmptyField = visibleValues[item.fieldKey] || visibleValues[item.parentKey];
        if (!notEmptyField && item.fields) {
          findChildValue = item.fields.some((field) => visibleValues[field.fieldKey]);
        }
        return (
          findChildValue ||
          (!Array.isArray(notEmptyField) && notEmptyField) ||
          (Array.isArray(notEmptyField) &&
            notEmptyField.length > 0 &&
            notEmptyField.some((value) => value !== null && value !== undefined && value !== ''))
        );
      }).length;
    });
  }, [allowedTabs, visibleValues]);

  useEffect(() => {
    onCountersChange?.(tabCounters);
  }, [tabCounters]);

  if (!filterList?.drawer) {
    return null;
  }
  return (
    <Drawer className={styles.drawer} rootKey="filters-form-drawer">
      <div className={styles.contentRoot}>
        <div className={styles.headerContainer}>
          <Text type="d2" msg="filter.form.drawer.title" />
          <TextButton msg="filter.form.drawer.button.clear" onClick={resetFilters} />
        </div>
        <div className={styles.tabsContainer}>
          {allowedTabs.length > 1 && (
            <div className={styles.tabs}>
              {allowedTabs.map((tab, index) => {
                const handleTabSelect = () => {
                  setActiveTab(index);
                };
                return (
                  <AlterButton
                    key={tab.title}
                    type={activeTab === index ? 'black' : 'white'}
                    icon={tab.icon}
                    iconPosition="right"
                    fluid
                    bordered
                    msg={tab.title}
                    counter={tabCounters[index] > 0 ? tabCounters[index] : undefined}
                    textByCenter={false}
                    onClick={handleTabSelect}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className={styles.filtersContainer}>
          {allowedTabs[activeTab]?.items.map((filter) => {
            if (filter?.onlyForAdmin && !isAdmin) {
              return null;
            }
            if (filter?.component) {
              return filter.component;
            }
            if (filter.relatedWith) {
              const checkedRelatedFields = Object.keys(filter.relatedWith).some((relatedField) => {
                return filters[relatedField] !== filter.relatedWith[relatedField];
              });
              if (checkedRelatedFields) {
                return null;
              }
            }
            return (
              <div className={styles.filterItem} key={filter.key}>
                {filter.title && (
                  <Text type="md" className={styles.filterItemText} msg={filter.title} />
                )}
                <div className={styles.valueContainer}>
                  <Filter {...filter} key={filter.key} />
                  {filter.percentageField && <FilterPercentSelect {...filter} />}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Drawer>
  );
};

export default FiltersFormDrawer;
