import React from 'react';
import classnames from 'classnames';

import styles from './Tabs.pcss';
import Tab from './Tab/Tab';

import { TabsType } from 'Types/common';

const Tabs: React.FC<TabsType> = (props) => {
  const { items, currentItemId, filled, mute, className, onChange } = props;

  return (
    <ul className={classnames(styles.root, className)}>
      {items.map((item) => {
        const { id, isActive: isActiveValue, notificationActive } = item;
        const isActive = id === currentItemId || isActiveValue;

        return (
          <Tab
            key={id}
            {...item}
            isActive={isActive}
            filled={filled}
            mute={mute}
            notificationActive={notificationActive}
            onClick={onChange}
          />
        );
      })}
    </ul>
  );
};

export default Tabs;
