import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';

import Message from '../MessageEvent/Message/Message';
import SystemProjectEvent from '../SystemProjectEvent/SystemProjectEvent';

import { UserType } from 'GraphTypes/ProjectEventsQuery.graphql';
import { getAvatar } from 'CommonPage/Chat/Dialog/ProjectEvents/ProjectEventsList/util';
import { ADVERTISER, CONTRACTOR } from 'Constants/general';
import { PostPublication_event$key } from 'GraphTypes/PostPublication_event.graphql';

interface Props {
  userType?: UserType;
  event: PostPublication_event$key;
  campaignId?: string;
  projectId: string;
  currentUserId?: string;
  disableGuideTour?: boolean;
}

const PostPublication: React.FC<Props> = (props) => {
  const { event, userType } = props;

  const data = useFragment(
    graphql`
      fragment PostPublication_event on ProjectEvent {
        id
        __typename
        createdAt
        readByAdvertiser
        readByContractor
        actor {
          __typename
          id
          ... on InstagramCreator {
            profile {
              profilePictureFile {
                url
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
          ... on TiktokCreator {
            profile {
              profilePictureFile {
                url
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
          ... on ContentCreator {
            profile {
              profilePictureFile {
                url
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
          ... on Brand {
            logo {
              ... on Image {
                transformations {
                  brandLogoUrl
                }
              }
            }
          }
        }
        ... on PostPublication {
          id
          readByAdvertiser
          readByContractor
        }
        ... on PublicationEvent {
          id
          readByAdvertiser
          readByContractor
          postPublications {
            edges {
              node {
                id
                publicationUrl
              }
            }
          }
        }
        ...SystemProjectEvent_event
      }
    `,
    event
  );

  const publicationUrls = useMemo<string>(() => {
    return (data?.postPublications?.edges || [])
      .map((item) => item?.node?.publicationUrl)
      .filter((item) => Boolean(item))
      .join('\n');
  }, [event]);

  if (!data) {
    return null;
  }

  const actor = data.actor;
  const readByAdvertiser = data.readByAdvertiser;
  const readByContractor = data.readByContractor;
  const createdAt = String(data.createdAt);

  const avaSrc =
    getAvatar(actor) ?? `https://www.gravatar.com/avatar/${actor?.id}?s=180&d=mp&r=x`;

  const isOwner =
    (userType === ADVERTISER && actor?.__typename === 'Brand') ||
    (userType === CONTRACTOR && actor?.__typename !== 'Brand');
  const isRead =
    (userType === ADVERTISER && readByContractor) || (userType === CONTRACTOR && readByAdvertiser);

  return (
    <div>
      <SystemProjectEvent event={data} userType={userType} />
      <Message
        id={data.id}
        avaSrc={avaSrc}
        text={publicationUrls}
        createdAt={createdAt}
        isOwner={isOwner}
        isRead={isRead}
      />
    </div>
  );
};

export default PostPublication;
