import React, { useEffect, Suspense } from 'react';
import { graphql, useQueryLoader } from 'react-relay';
import track from 'react-tracking';

import styles from './Results.pcss';
import CampaignCreatives from './CampaignCreatives/CampaignCreatives';

import Spinner from 'Atoms/Spinner/Spinner';
import { ResultsQuery as QueryType } from 'GraphTypes/ResultsQuery.graphql';
import { amplitude } from 'Helpers/amplitude';
import { CampaignQuery$data } from 'GraphTypes/CampaignQuery.graphql';

export const ResultsQuery = graphql`
  query ResultsQuery($campaignId: ID!) {
    ...CampaignCreatives_campaign @arguments(campaignId: $campaignId)
  }
`;

interface Props {
  campaignId: string;
  currentUser: CampaignQuery$data['currentUser'];
  isAdminView: boolean;
  campaignPublishing: NonNullable<CampaignQuery$data['campaign']>['publishingRequired'];
}

const Results: React.FC<Props> = (props) => {
  const { campaignId, currentUser, isAdminView, campaignPublishing } = props;

  const canChatWithCreators = Boolean(currentUser?.organization?.subscription?.canChatWithCreators);

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(ResultsQuery);

  useEffect(() => {
    amplitude.sendEvent({
      id: 146,
      name: 'results',
      category: 'pageview',
      param: {
        campaign_id: campaignId,
        organicPosting: campaignPublishing,
      },
    });
  }, []);

  useEffect(() => {
    loadQuery({ campaignId }, { fetchPolicy: 'network-only' });
  }, [campaignId]);

  return (
    <div className={styles.root}>
      <Suspense fallback={<Spinner className={styles.preloader} />}>
        {queryReference && (
          <div className={styles.root}>
            <div className={styles.container}>
              <CampaignCreatives
                campaignId={campaignId}
                queryReference={queryReference}
                admin={isAdminView}
                canChatWithCreators={canChatWithCreators}
              />
            </div>
          </div>
        )}
      </Suspense>
    </div>
  );
};

export default track({ page: 'results' }, { dispatchOnMount: true })(Results);
