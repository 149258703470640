import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import Text from '../../Text/Text';

import styles from './DropdownGroup.pcss';

import TextPreset from 'Components/ui/TextPreset/TextPreset';

export type DropdownGroupPropsType = PropsWithChildren<{
  label?: string;
  className?: string;
  labelMsg?: string;
  selected?: boolean;
  align?: 'left' | 'right';
}>;

const DropdownGroup: React.FC<DropdownGroupPropsType> = (props) => {
  const { label, labelMsg, children, className, align } = props;

  return (
    <div className={classnames(styles.root, className, styles[align || 'right'])}>
      {(label || labelMsg) && (
        <TextPreset
          header={
            <Text type={'h2'} size={12} msg={labelMsg} color="grey">
              {label}
            </Text>
          }
        />
      )}
      {children}
    </div>
  );
};

export default DropdownGroup;
