import React, { useEffect } from 'react';
import classNames from 'classnames';

import styles from './Notification.pcss';

import Text from 'Atoms/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import { NotificationsActionsDataProps } from 'Containers/Notifications/Notifications.Context';
import { Color } from 'Components/ui/types';

interface Props {
  removeDelay?: number;
  onHideClick: (id: string) => void;
  onBtnClick?: () => void;
  color?: Color;
}

const DEFAULT_TIME = 5000; // ms

const Notification: React.FC<Props & NotificationsActionsDataProps> = ({
  id,
  text,
  btnCaption,
  values,
  removeDelay,
  onHideClick,
  onBtnClick,
  className,
  position = 'left',
  color = 'dark',
}) => {
  const time = removeDelay ? removeDelay : DEFAULT_TIME;

  useEffect(() => {
    // Remove notification from store after timer expires
    setTimeout(() => {
      onHideClick(id);
    }, time);
  }, []);

  const handleBtnClick = () => {
    if (onBtnClick) onBtnClick();

    onHideClick(id);
  };

  const handleCloseClick = () => onHideClick(id);

  return (
    <div key={id} className={classNames(styles.item, styles[color], styles[position], className)}>
      <div className={styles.content}>
        <Text msg={text} values={values} className={styles.text} />
        {onBtnClick && (
          <TextButton
            msg={btnCaption || 'general.undo'}
            className={styles.cancel}
            onClick={handleBtnClick}
          />
        )}
      </div>
    </div>
  );
};

export default Notification;
