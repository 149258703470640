import React, { useState, useEffect, PropsWithChildren } from 'react';
import classnames from 'classnames';

import IconNew from '../Icon/Icon';
import { IconName } from '../types';
import Text from '../Text/Text';

import styles from './Dropdown.pcss';

import useOutsideClick from 'Hooks/useClickOutside';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { TooltipType } from 'Types/common';

export type DropdownPropsType = {
  value: JSX.Element | string | null;
  openedByDefault?: boolean;
  showArrow?: boolean;
  bordered?: boolean;
  error?: boolean;
  hasValue?: boolean;
  icon?: IconName;
  className?: string;
  valueClassName?: string;
  closeBySelect?: boolean;
  mountedByDefault?: boolean;
  tooltipData?: TooltipType;
  openKey?: number;
  onOpen?: () => void;
  onClose?: () => void;
  placeholderMsg?: string;
  disabled?: boolean;
  active?: boolean;
};

export type Props = PropsWithChildren<DropdownPropsType>;

const Dropdown: React.FC<Props> = (props) => {
  const {
    value,
    children,
    icon,
    showArrow = true,
    openedByDefault = false,
    bordered,
    className,
    valueClassName,
    tooltipData,
    error,
    closeBySelect = true,
    mountedByDefault = false,
    openKey = 0,
    hasValue,
    onOpen,
    onClose,
    placeholderMsg,
    disabled,
    active,
  } = props;

  const [isMounted, setIsMounted] = useState(false);
  const [opened, setOpenState] = useState(openedByDefault);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (openKey > 0) {
      setOpenState(true);
    }
  }, [openKey]);

  useEffect(() => {
    if (!isMounted) return;
    opened ? onOpen?.() : onClose?.();
  }, [opened]);

  const dropdownRef = useOutsideClick<HTMLDivElement>(() => {
    setOpenState(false);
  });

  const classNameList = classnames(
    styles.root,
    {
      [styles.opened]: opened,
      [styles.bordered]: bordered,
      [styles.error]: error,
      [styles.withValue]: hasValue,
      [styles.disabled]: disabled,
      [styles.active]: active,
    },
    className
  );

  const handleOpenDropdown = () => {
    if (disabled) return;
    setOpenState((prev) => !prev);
  };

  const visibleValue = value ? (
    value
  ) : placeholderMsg ? (
    <Text type="md" className={styles.placeholder} msg={placeholderMsg} />
  ) : null;

  return (
    <Tooltip {...tooltipData}>
      <div
        ref={dropdownRef}
        className={classNameList}
        {...(closeBySelect ? { onClick: handleOpenDropdown } : {})}
      >
        <div
          className={classnames(styles.value, valueClassName)}
          {...(!closeBySelect ? { onClick: handleOpenDropdown } : {})}
        >
          {icon && <IconNew name={icon} />}
          <>{visibleValue}</>
          {showArrow && !disabled && (
            <IconNew
              name={'Arrow-small-down'}
              className={classnames({ [styles.openIcon]: opened })}
            />
          )}
        </div>
        {mountedByDefault ? (
          <div className={classnames({ [styles.opened]: opened }, styles.childContainer)}>
            {children}
          </div>
        ) : (
          <>{opened && children}</>
        )}
      </div>
    </Tooltip>
  );
};

export default Dropdown;
