import React, { useContext, useEffect, useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import { BriefContext } from '../../../Brief.Context';
import Field from '../components/Field/Field';
import With from '../components/With/With';
import Section from '../components/Section/Section';
import SegmentedControls from '../components/SegmentedControls/SegmentedControls';

import styles from './CreatorsDetails.pcss';
import {
  getPrivacyList,
  checkValidation,
  getViewConditions,
  getSuggestedFiltersConfig,
} from './util';
import CreatorsExclusion from './CreatorsExclusion/CreatorsExclusion';
import MediaplanDetails from './MediaplanDetails/MediaplanDetails';
import SearchParams from './SearchParams/SearchParams';
import Lookalikes from './Lookalikes/Lookalikes';
import ScreeningQuestions from './ScreeningQuestions/ScreeningQuestions';
import CustomLists from './CustomLists/CustomLists';

import updateSearchQuery from 'Mutations/UpdateSearchQuery.Mutation';
import updateBrief from 'Mutations/UpdateBrief.Mutation';
import updateCampaign from 'Mutations/UpdateCampaign.Mutation';
import { amplitude } from 'Helpers/amplitude';
import { CreatorsDetails_campaign$key } from 'GraphTypes/CreatorsDetails_campaign.graphql';

interface Props {
  isAdmin?: boolean;
  campaign: CreatorsDetails_campaign$key;
}

const CreatorsDetails: React.FC<Props> = (props) => {
  const { campaign, isAdmin } = props;

  const data = useFragment(
    graphql`
      fragment CreatorsDetails_campaign on Campaign {
        id
        postingType
        platform
        private
        type
        inviteFavorites
        inviteCustomLists {
          id
        }
        brief {
          id
          __typename
          ...ScreeningQuestions_brief
          ... on V2Brief {
            submitted
            screeningQuestion
          }
        }
        organization {
          customLists {
            ...CustomLists_customLists
          }
          favoriteCreators(first: 3) {
            ...CustomLists_Creator
          }
        }
        searchQuery {
          id
          followersFrom
          engagementRateFrom
          viewsMedianFrom
        }
        ...MediaplanDetails_campaign
        ...CreatorsExclusion_campaign
        ...Lookalikes_campaign
      }
    `,
    campaign
  );
  const {
    id: campaignId,
    private: privateValue,
    brief,
    searchQuery,
    private: isPrivate,
    platform,
    type,
    postingType,
    organization,
    inviteCustomLists,
    inviteFavorites,
  } = data;

  const [visible, setVisible] = useState(false);

  const isBriefSubmitted = brief?.submitted;

  const suggestedFilter = useMemo(
    () => getSuggestedFiltersConfig({ isPrivate, postingType, platform, type, searchQuery }),
    [isPrivate, postingType, platform]
  );

  const [briefState, briefDispatch] = useContext(BriefContext);

  useEffect(() => {
    const isVisible = !(
      !brief ||
      brief.__typename !== 'V2Brief' ||
      !briefState.productInfoFilled ||
      !searchQuery
    );
    setVisible(isVisible);
    if (!isVisible) return;
    briefDispatch({ key: 'creatorsInfoFilled', value: checkValidation(data) });
  }, [data, brief, briefState.productInfoFilled, searchQuery]);

  if (!brief || brief.__typename !== 'V2Brief') {
    return null;
  }

  const { id: briefId } = brief;

  const searchQueryId = searchQuery?.id;

  const privacyList = getPrivacyList({ campaignId });

  const hasSuggestedFilters =
    !isPrivate &&
    ((type === 'INFLUENCER' && postingType === 'ORGANIC_POSTING') || type === 'PRODUCT_SEEDING') &&
    (platform === 'INSTAGRAM' || platform === 'TIKTOK') &&
    !isBriefSubmitted;

  const viewConditions = getViewConditions({ campaign: data });

  const handleCampaignChange = (data: any) => {
    if (data.hasOwnProperty('private')) {
      amplitude.sendEvent({
        id: '76',
        category: 'brief',
        name: 'campaign_privacy_select',
        param: { campaignId, type: data.private ? 'private' : 'public' },
      });
    }
    briefDispatch({ key: 'briefIsSaving', value: true });
    updateCampaign({ id: campaignId, ...data });
  };

  const handleSearchQueryChange = (data: any) => {
    briefDispatch({ key: 'briefIsSaving', value: true });
    updateSearchQuery({ id: searchQueryId, searchQuery: data });
  };

  const sendAnalytics = (caseId: number) => {
    amplitude.sendEvent({
      id: 260,
      category: 'pageview',
      name: 'auto-filters-click',
      param: {
        campaign_id: campaignId,
        case_number: caseId,
      },
    });
  };
  const handleApplySuggestedFilters = () => {
    if (isAdmin) return;
    sendAnalytics(suggestedFilter?.analyticId);
    const suggestedWithoutId = { ...suggestedFilter };
    delete suggestedWithoutId.analyticId;

    handleSearchQueryChange(suggestedWithoutId);
  };

  return (
    <Section titleMsg="brief_template.section.creators" className={visible ? '' : styles.hidden}>
      <Field
        title="brief_template.field.creators.privacy.title"
        description="brief_template.field.creators.privacy.descr"
      >
        <SegmentedControls
          id="private"
          currentValue={privateValue}
          items={privacyList}
          onChange={handleCampaignChange}
        />
      </Field>
      <CustomLists
        customListsRoot={organization?.customLists}
        favListsRoot={organization?.favoriteCreators}
        handleCampaignChange={handleCampaignChange}
        inviteCustomLists={inviteCustomLists}
        inviteFavorites={inviteFavorites}
      />
      <With condition={viewConditions.paramsSearch} forceRerender>
        <SearchParams
          campaignId={campaignId}
          isAdmin={isAdmin}
          handleSuggestedFilters={hasSuggestedFilters ? handleApplySuggestedFilters : undefined}
          suggestedFilters={suggestedFilter}
        />
      </With>
      <With condition={viewConditions.lookalikes}>
        <div>
          <Lookalikes campaign={data} />
        </div>
      </With>
      <ScreeningQuestions screeningQuestions={brief} />
      <With condition={viewConditions.campaignExclusion}>
        <div className={styles.field}>
          <CreatorsExclusion isAdmin={isAdmin} campaign={data} onChange={handleSearchQueryChange} />
        </div>
      </With>
      <MediaplanDetails campaign={data} />
    </Section>
  );
};

export default CreatorsDetails;
