import { graphql, useMutation, UseMutationConfig } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import { AddCreatorsToCustomListMutation } from 'GraphTypes/AddCreatorsToCustomListMutation.graphql';
import { BaseMutationExecFn } from 'Types/utils';

const mutation = graphql`
  mutation AddCreatorsToCustomListMutation($input: AddCreatorsToCustomListInput!) {
    addCreatorsToCustomList(input: $input) {
      __typename
      ... on AddCreatorsToCustomListPayload {
        customList {
          creators {
            edges {
              node {
                id
                username
                customLists {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useAddCreatorsToCustomListMutation = (
  config?: UseMutationConfig<AddCreatorsToCustomListMutation>
): [BaseMutationExecFn<AddCreatorsToCustomListMutation>, boolean] => {
  const [addCreatorsToCustomList, loading] = useMutation<AddCreatorsToCustomListMutation>(mutation);

  const addCreatorsToCustomListCaller: BaseMutationExecFn<AddCreatorsToCustomListMutation> = (
    execConfig
  ) => {
    addCreatorsToCustomList({
      ...config,
      ...execConfig,
      updater: (store, response) => {
        const isValidResponse =
          response.addCreatorsToCustomList?.__typename === 'AddCreatorsToCustomListPayload';
        const creatorsCount = isValidResponse
          ? response.addCreatorsToCustomList.customList?.creators?.edges?.length
          : 0;
        const { customListId } = execConfig.variables.input;
        const list = store.get(customListId);
        const listCreatorsConnection = list?.getLinkedRecord('creators');
        if (listCreatorsConnection && typeof creatorsCount === 'number' && isValidResponse) {
          listCreatorsConnection.setValue(creatorsCount, 'totalCount');
        }
      },
    });
  };
  return [addCreatorsToCustomListCaller, loading];
};
