import React, { useState, useContext, useRef, useEffect, SyntheticEvent } from 'react';
import ReactCrop, { type Crop, makeAspectCrop, centerCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import styles from './ImageCropper.pcss';

import { ImageData } from 'Api/uploadFile';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import FileUploader from 'Atoms/FileUploader/FileUploader';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  localfileSrc?: string;
  onImageChange: (data: ImageData) => void;
}

const ImageCropper: React.FC<Props> = (props) => {
  const { localfileSrc: defaultFileSrc, onImageChange } = props;

  const [fileSrc, setFileSrc] = useState<string | undefined>(defaultFileSrc);
  const [crop, setCrop] = useState<Crop>();
  const [loading, setLoading] = useState(false);

  const { closeDrawer } = useContext(DrawerContext);

  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setLoading(false);
    setFileSrc(defaultFileSrc);
  }, [defaultFileSrc]);

  const handleUploadForm = (files: FileList | null) => {
    if (!files || files.length === 0) return;
    const src = URL.createObjectURL(files[0]);
    setFileSrc(src);
  };

  const handleChange = (newCrop: PixelCrop) => {
    setCrop(newCrop);
  };

  const handleImageLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const { width, height } = e.currentTarget;

    const sizeData = width > height ? { height, width: height } : { width, height: width };
    const tempCrop = centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          ...sizeData,
        },
        1,
        width,
        height
      ),
      width,
      height
    );
    setCrop(tempCrop);
  };

  const handleCrop = () => {
    if (!crop) return;
    setLoading(true);
    const imageData = handleImageCrop();
    if (imageData) {
      onImageChange(imageData);
    }
  };

  const handleImageCrop = () => {
    if (!crop) return;
    const containerEl = imgRef.current as HTMLDivElement;
    const naturalWidth = imgRef.current?.naturalWidth || 0;
    const naturalHeight = imgRef.current?.naturalHeight || 0;
    const imgWidth = imgRef.current?.width || 0;
    const imgHeight = imgRef.current?.height || 0;
    const canvas = document.createElement('canvas');
    const { x = 0, y = 0, width = 0, height = 0 } = crop;
    const finalX = Math.round((x / imgWidth) * naturalWidth);
    const finalY = Math.round((y / imgHeight) * naturalHeight);
    const finalWidth = Math.round((width / imgWidth) * naturalWidth);
    const finalHeight = Math.round((height / imgHeight) * naturalHeight);

    canvas.width = finalWidth;
    canvas.height = finalHeight;
    canvas
      ?.getContext('2d')
      ?.drawImage(
        containerEl as CanvasImageSource,
        finalX,
        finalY,
        finalWidth,
        finalHeight,
        0,
        0,
        finalWidth,
        finalHeight
      );

    return { src: canvas.toDataURL(), width: finalWidth, height: finalWidth };
  };

  const handleClose = () => {
    setFileSrc(undefined);
    setLoading(false);
    closeDrawer('image-cropper');
  };

  return (
    <Drawer
      fullView
      position="center"
      rootKey="image-cropper"
      className={styles.drawer}
      needCloseButton={false}
    >
      <div className={styles.content}>
        <div className={styles.closeWrap}>
          <TextButton icon="Close-small" onClick={handleClose} />
        </div>
        <div className={styles.title}>
          <div>
            <Text weight="bold" type="md" msg="product_inage_cropper.title" />
            <Text color="grey" type="s" msg="product_inage_cropper.descr" />
          </div>
          <div className={styles.changeFile}>
            <TextButton color="purple" msg="product_inage_cropper.change_file" />
            <FileUploader onChange={handleUploadForm} className={styles.uploader} />
          </div>
        </div>
        <div className={styles.cropperWrap}>
          <ReactCrop
            aspect={1}
            crop={crop}
            className={styles.crop}
            keepSelection={true}
            onChange={handleChange}
          >
            <img ref={imgRef} src={fileSrc} className={styles.image} onLoad={handleImageLoad} />
          </ReactCrop>
        </div>
        <div className={styles.buttons}>
          <AlterButton
            type="grey"
            msg="product_inage_cropper.discard"
            loading={loading}
            onClick={handleClose}
          />
          <AlterButton
            type="pink"
            msg="product_inage_cropper.save"
            loading={loading}
            onClick={handleCrop}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default ImageCropper;
