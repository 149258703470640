import { graphql } from 'react-relay';

const FilterFormBodyListQuery = graphql`
  query FilterFormBodyListQuery {
    bodyTypes {
      id
      name
    }
  }
`;

export default FilterFormBodyListQuery;
