import React from 'react';
import { useIntl } from 'react-intl';

import styles from './BadgeListItem.pcss';

import badgeResponsiveImg from 'Images/icons/badge-responsive.svg';
import badgeRisingImg from 'Images/icons/badge-rising.svg';
import badgeTopRatedImg from 'Images/icons/badge-top-rated.svg';
import badgeUGCExpertImg from 'Images/icons/badge-UGC-premium.svg';
import Text from 'Components/ui/Text/Text';
import { Checkbox } from 'Components/ui/Checkbox';

const icons = {
  TOP_RATED: badgeTopRatedImg,
  HIGHLY_RESPONSIVE: badgeResponsiveImg,
  HIGH_POTENTIAL: badgeRisingImg,
  UGC_EXPERT: badgeUGCExpertImg,
};

export interface BadgeListItemProps {
  name: keyof typeof icons;
  isActive: boolean;
  handleSelect: (name: string) => void;
}

const BadgeListItem: React.FC<BadgeListItemProps> = (props) => {
  const { name, isActive, handleSelect } = props;
  const intl = useIntl();
  const readableName = name.toLowerCase();
  const handleBadgeSelect = () => {
    const name = intl.formatMessage({ id: `filter.form.badges.${readableName}` });
    handleSelect(name);
  };
  return (
    <div className={styles.root} onClick={handleBadgeSelect}>
      <img src={icons[name]} />
      <div className={styles.textContainer}>
        <Text type="md" msg={`filter.form.badges.${readableName}`} />
        <Text type="label" msg={`filter.form.badges.${readableName}.description`} />
      </div>
      <Checkbox checked={isActive} />
    </div>
  );
};

export default BadgeListItem;
