import React, { useEffect, useState, Suspense } from 'react';
import track, { useTracking } from 'react-tracking';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './BillingPlans.pcss';
import Plans from './Plans/Plans';
import Comparison from './Comparison/Comparison';
import LeaveWithoutPurchaseDrawer from './LeaveWithoutPurchaseDrawer/LeaveWithoutPurchaseDrawer';
import { getDefaultPlans, isMonthlyPlan, ExtendedPlanId, AddonSettings } from './data';

import { amplitude } from 'Helpers/amplitude';
import { useCampaignActions } from 'Hooks/useCampaignActions';
import {
  BillingPlansQuery as QueryType,
  PlanId,
  SubscriptionInterval,
} from 'GraphTypes/BillingPlansQuery.graphql';
import Page from 'Templates/Page/Page';
import Spinner from 'Atoms/Spinner/Spinner';
import SubscriptonBought from 'Molecules/SubscriptonBought/SubscriptonBought';
import { ERROR_ROUTE, FREE_PLAN, FREE_TRIAL_PLAN } from 'Constants/general';
import { SubscriptionInterval as SubscriptionIntervalType } from 'Types/enums';
import { trackTime as custifyTrackTime } from 'Util/custify';
import Text from 'Components/ui/Text/Text';
import useDashly from 'Hooks/useDashly';
import { AddonItemInput } from 'GraphTypes/PreviewProrationMutation.graphql';
import ConfirmNewPlan from 'Modal/advertiser/ConfirmNewPlan/ConfirmNewPlan';
import Drawer from 'Components/ui/Drawer/Drawer';
import { useStripe } from 'Hooks/useStripe';
import TransactionFailed from 'Molecules/TransactionFailed/TransactionFailed';

const BillingPlansQuery = graphql`
  query BillingPlansQuery {
    currentUser {
      admin
      organization {
        newClient
        trialMonthPlanAvailable
        subscription {
          activeDiscount {
            name
          }
          availablePlanIds
          planId
          interval
          renewsAt
          effectiveLimits {
            maxLicensedCreators
            maxActiveBrands
            maxBcaConnections
            maxSeats
          }
        }
        paymentAccount {
          currency
        }
      }
    }
  }
`;

export type BillingPlansPropList = {
  [key in ExtendedPlanId]?: BillingPlansProp;
};

export type BillingPlansProp = {
  interval: SubscriptionIntervalType;
  addons: AddonSettings[];
};

export type AddonsSubscriptionType = {
  maxSeats?: number | null;
  maxBcaConnections?: number | null;
  maxActiveBrands?: number | null;
};

export interface SubscriptionParams {
  planId: PlanId;
  interval: SubscriptionInterval;
  promocode?: string | null;
  addonItems?: AddonItemInput[];
}

type BillingPlansType = React.FC<{
  hidePromoCodeButton?: boolean;
}>;
const BillingPlans: BillingPlansType = () => {
  const { planslist: defaultPlanList } = getDefaultPlans({ currentPlan: undefined });
  const intl = useIntl();
  const navigate = useNavigate();
  const { track: sendDashlyEvent } = useDashly();
  const [plansProps, setPlansProps] = useState<BillingPlansPropList>(() => {
    const result = {} as BillingPlansPropList;
    defaultPlanList.forEach((plan) => {
      result[plan] = {
        interval: SubscriptionIntervalType.TRIMONTHLY,
        addons: [],
      };
    });
    return result;
  });
  const [planBeenBought, setPlanBeenBought] = useState(false);
  const [buyingFailed, setBuyingFailed] = useState(false);
  const [buyPlanDrawerVisible, setBuyPlanDrawerVisible] = useState(false);
  const [paramsForDrawer, setParamsForDrawer] = useState<SubscriptionParams>();

  const { buyPlan } = useStripe();

  const { createNewCampaign } = useCampaignActions();

  const tracking = useTracking();

  const query = new URLSearchParams(window.location.search);
  const queryPlanId = query.get('planId');
  const queryInterval = query.get('interval');

  useEffect(() => {
    custifyTrackTime('Billing_page');

    if (queryPlanId && queryInterval) {
      setPlanBeenBought(true);
    }
    return () => {
      _ctrack.stopTrackTime();
    };
  }, []);

  const checkAbility = (availablePlanIds?: readonly PlanId[], planId?: PlanId) => {
    let showBilling = false;
    if (availablePlanIds && planId) {
      if (availablePlanIds.length > 0) {
        if (availablePlanIds.length === 1) {
          if (availablePlanIds[0] !== planId) {
            showBilling = true;
          }
        } else {
          showBilling = true;
        }
      }
    }

    return showBilling;
  };

  const data = useLazyLoadQuery<QueryType>(BillingPlansQuery, {});

  if (!data) return <Spinner style={{ margin: '80px auto' }} />;

  const organization = data.currentUser?.organization;
  const availableDiscount = organization?.subscription?.activeDiscount;
  const subscription = organization?.subscription;
  const defaultInterval = subscription?.interval as SubscriptionIntervalType;
  const maxSeats = subscription?.effectiveLimits.maxSeats;
  const maxBcaConnections = subscription?.effectiveLimits.maxBcaConnections;
  const maxActiveBrands = subscription?.effectiveLimits.maxActiveBrands;
  const paymentAccount = organization?.paymentAccount;
  const availablePlanIds = subscription?.availablePlanIds;
  const trialMonthPlanAvailable = organization?.trialMonthPlanAvailable;
  const newClient = organization?.newClient;
  const currentPlanId = subscription?.planId;
  const renewsAt = subscription?.renewsAt;

  const currency = paymentAccount?.currency;

  const isAble = checkAbility(availablePlanIds, currentPlanId);

  if (!isAble) {
    navigate(ERROR_ROUTE);
    return null;
  }

  const {
    planslist,
    plansForCompare,
    oldItem: oldPlan,
  } = getDefaultPlans({
    currentPlan: currentPlanId,
  });

  const handleChangeProps = (planId: ExtendedPlanId, props: Partial<BillingPlansProp>) => {
    setPlansProps((prevProps) => {
      return {
        ...prevProps,
        [planId]: {
          ...prevProps[planId],
          ...props,
        },
      };
    });
  };

  const handleBuyFailed = () => {
    setBuyingFailed(true);
  };

  const handlePlanBuy = ({ choosenPlanId }: { choosenPlanId: PlanId }) => {
    const isPlanUpdating = currentPlanId && ![FREE_PLAN, FREE_TRIAL_PLAN].includes(currentPlanId);
    const propAddonList = plansProps[choosenPlanId]?.addons || [];
    const propAddonInterval =
      plansProps[choosenPlanId]?.interval || SubscriptionIntervalType.MONTHLY;
    const userChangedAddons = propAddonList.reduce((list, addon) => {
      const selectedAddonCount = addon.value || addon.defaultValue;
      if (selectedAddonCount && addon.minValue !== selectedAddonCount) {
        return {
          ...list,
          [`ADDON_${addon.key}`]: selectedAddonCount,
        };
      }
      return list;
    }, {});
    const isAddonsChanged = Object.keys(userChangedAddons).length > 0;
    let addonItems: [AddonItemInput] | [] = [];
    if (isAddonsChanged) {
      sendDashlyEvent('web_billing_addon_requested', {
        planId: choosenPlanId,
        ...userChangedAddons,
      });
      addonItems = propAddonList.reduce((list, addon) => {
        const selectedAddonCount = addon.value || addon.defaultValue;
        if (selectedAddonCount && addon.minValue !== selectedAddonCount) {
          let quantity = selectedAddonCount - addon.minValue;
          if (typeof addon.stepCount === 'number') {
            quantity = selectedAddonCount / addon.stepCount - addon.minValue / addon.stepCount;
          }
          if (quantity !== Infinity) {
            return [
              ...list,
              {
                addonId: addon.key,
                quantity,
              },
            ];
          }
        }
        return list;
      }, []);
    }
    const _interval = isMonthlyPlan(choosenPlanId)
      ? SubscriptionIntervalType.MONTHLY
      : propAddonInterval;

    tracking.trackEvent({
      element: `plan_${choosenPlanId.toLowerCase()}`,
      event: 'selected',
      goal: 'plan_selected',
      payload: { choosenPlanId, interval: _interval },
    });

    amplitude.sendEvent({
      id: '94',
      category: 'billing',
      name: 'subscribe',
      param: { source: 'billing', planId: choosenPlanId, ...userChangedAddons },
    });

    const params = {
      planId: choosenPlanId,
      interval: _interval,
      addonItems,
    };
    if (isPlanUpdating) {
      setParamsForDrawer(params);
      setBuyPlanDrawerVisible(true);
    } else {
      buyPlan({ params, onFailed: handleBuyFailed });
    }
  };

  const handlePlanBeenBoughtClose = () => {
    setPlanBeenBought(false);
  };

  const handleBuyPlanDrawerClose = () => {
    setBuyPlanDrawerVisible(false);
  };

  const handleCampaignCreate = () => {
    createNewCampaign({ onSuccess: handlePlanBeenBoughtClose });
  };

  const handleRetry = () => {
    setBuyingFailed(false);
  };

  return (
    <Page className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <Text type="d2" msg="billing_plans.plans.default.title" className={styles.title} />
            <Text
              type="md"
              msg="billing_plans.plans.default.subtitle"
              className={styles.subtitle}
            />
          </div>
          {oldPlan && (
            <Text
              type="md"
              msg="billing_plans.plans.warning.old_plan"
              className={styles.updatePlansNotifications}
              formatValues={{
                plan: intl.formatMessage({
                  id: `plan.name.${(oldPlan || FREE_PLAN).toLowerCase()}`,
                }),
              }}
            />
          )}
        </div>
        <Plans
          availablePlanIds={planslist}
          availableDiscount={availableDiscount}
          planId={currentPlanId}
          renewsAt={renewsAt}
          plansProps={plansProps}
          changePlansProps={handleChangeProps}
          defaultInterval={defaultInterval}
          defaultAddonsValue={{
            maxSeats,
            maxBcaConnections,
            maxActiveBrands,
          }}
          currency={currency}
          onPlanBuyClick={handlePlanBuy}
          trialMonthPlanAvailable={trialMonthPlanAvailable}
          place="plans"
        />
        <Comparison
          availablePlanIds={plansForCompare}
          planId={currentPlanId}
          plansProps={plansProps}
          defaultInterval={defaultInterval}
          currency={currency}
          onPlanBuyClick={handlePlanBuy}
          trialMonthPlanAvailable={trialMonthPlanAvailable}
          availableDiscount={availableDiscount}
        />
      </div>
      {newClient && <LeaveWithoutPurchaseDrawer />}
      {paramsForDrawer && buyPlanDrawerVisible && (
        <Suspense fallback={null}>
          <ConfirmNewPlan
            {...paramsForDrawer}
            opened={buyPlanDrawerVisible}
            onClose={handleBuyPlanDrawerClose}
          />
        </Suspense>
      )}
      <Drawer rootKey="subscripton-bought" opened={planBeenBought} className={styles.drawer}>
        <div className={styles.drawerContainer}>
          <SubscriptonBought
            planId={queryPlanId as PlanId}
            interval={queryInterval as SubscriptionInterval}
            onSubmitClick={handleCampaignCreate}
            submitMsg="subscription_been_bought_modal.submit"
          />
        </div>
      </Drawer>
      <Drawer rootKey="buying-plan-failed" opened={buyingFailed} className={styles.drawer}>
        <TransactionFailed onRetryClick={handleRetry} />
      </Drawer>
    </Page>
  );
};

export default track(
  {
    page: 'plans',
  },
  { dispatchOnMount: () => ({ event: 'page_plans' }) }
)(BillingPlans);
