import React, { Suspense } from 'react';

import Creators from '../Creators/Creators';
import Outreach from '../Outreach/Outreach';
import Results from '../Results/Results';

import {
  CampaignQuery$data,
  CampaignType,
  CampaignPlatform,
} from 'GraphTypes/CampaignQuery.graphql';
import {
  CREATORS,
  AWAITING_LIST,
  ARCHIVED,
  RESULTS,
  APPLICANTS,
  SHIPMENT,
  OUTREACH,
  COMPLETED,
} from 'Constants/general';
import ShipmentTab from 'AdvertiserPage/Creators/tabs/Shipment/ShipmentTab';
import styles from 'AdvertiserPage/Brands/Brands.pcss';
import Spinner from 'Atoms/Spinner/Spinner';
import colors from 'Styles/vars/colors.css';

interface Props {
  className?: string;
  section: string;
  campaignId: string;
  email?: string;
  organizationId?: string;
  campaignType?: CampaignType | null;
  campaignPlatform?: CampaignPlatform | null;
  currentUser: CampaignQuery$data['currentUser'];
  isAdminView: boolean;
  publishingRequired: boolean;
  campaign: CampaignQuery$data['campaign'];
}

const CampaignContent: React.FC<Props> = (props) => {
  const {
    isAdminView,
    campaign,
    campaignId,
    section,
    campaignType,
    campaignPlatform,
    currentUser,
    publishingRequired,
    organizationId,
    email,
  } = props;

  if (
    section === CREATORS ||
    section === AWAITING_LIST ||
    section === APPLICANTS ||
    section === COMPLETED ||
    section === ARCHIVED
  ) {
    return (
      <Creators
        key={section}
        campaignId={campaignId}
        section={section}
        isAdminView={isAdminView}
        campaignType={campaignType}
        campaignPlatform={campaignPlatform}
        currentUser={currentUser}
        campaign={campaign}
        publishingRequired={publishingRequired}
        organizationId={organizationId}
      />
    );
  } else if (section === SHIPMENT) {
    return (
      <Suspense fallback={null}>
        <ShipmentTab campaignId={campaignId} />
      </Suspense>
    );
  } else if (section === OUTREACH) {
    return (
      <Suspense
        fallback={
          <div className={styles.preloaderContainer}>
            <Spinner color={colors.colorDark} className={styles.preloader} />
          </div>
        }
      >
        <Outreach campaignId={campaignId} platform={campaignPlatform} />{' '}
      </Suspense>
    );
  } else if (section === RESULTS) {
    return (
      <Results
        campaignId={campaignId}
        campaignPublishing={!!campaign?.publishingRequired}
        isAdminView={isAdminView}
        currentUser={currentUser}
      />
    );
  }
  return null;
};

export default CampaignContent;
