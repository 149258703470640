import React, { useCallback, useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useIntl } from 'react-intl';

import styles from './CurrentSubscriptionInfo.pcss';

import { amplitude } from 'Helpers/amplitude';
import { createSum } from 'Util/numberFormatter';
import { dateStringToNewYorkDate, EN_DATE_FORMAT } from 'Util/dateCreator';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import {
  CurrentSubscriptionInfo_organization$key,
  CurrentSubscriptionInfo_organization$data,
} from 'GraphTypes/CurrentSubscriptionInfo_organization.graphql';
import { FREE_PLAN, PLANS_ROUTE, TRIAL_2022_08_PLAN } from 'Constants/general';
import PauseButton from './PauseSubscription/PauseButton/PauseButton';
import ResumeButton from './PauseSubscription/ResumeButton/ResumeButton';
import moment from 'moment';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import CancelButton from './CancelSubscription/CancelButton/CancelButton';
import PauseSubscriptionDrawer from './PauseSubscription/PauseSubscriptionDrawer/PauseSubscriptionDrawer';
import ReactivateSubscriptionButton from './ReactivateSubscription/ReactivateSubscriptionButton';
import CancelOfferAcceptedSubscriptionDrawer from './CancelSubscription/CancelOfferAcceptedSubscriptionDrawer/CancelOfferAcceptedSubscriptionDrawer';
import CancelSuccessSubscriptionDrawer from './CancelSubscription/CancelSuccessSubscriptionDrawer/CancelSuccessSubscriptionDrawer';

interface Props {
  organization: CurrentSubscriptionInfo_organization$key;
}

const CurrentSubscriptionInfo: React.FC<Props> = (props) => {
  const intl = useIntl();

  const { organization } = props;
  if (!organization) return null;

  const data = useFragment(
    graphql`
      fragment CurrentSubscriptionInfo_organization on Organization {
        id
        name
        subscription {
          id
          planId
          interval
          renewsAt
          cancelAt
          priceAmount
          paused
          canApplyDiscount
          discountOfferAcceptedAt
          pauseEvent {
            pausedAt
            resumesAt
          }
          lastSchedule {
            discount {
              percentOff
            }
            trialEnd
            startDate
          }
        }
        paymentAccount {
          currency
        }
      }
    `,
    organization as any
  ) as CurrentSubscriptionInfo_organization$data;

  if (!data) return null;

  const { id: organizationId, name: organizationName } = data;
  const subscription = data.subscription;
  if (!subscription) return null;

  const {
    planId,
    interval,
    renewsAt,
    cancelAt,
    priceAmount,
    paused,
    id: subscriptionId,
    pauseEvent,
    lastSchedule,
  } = subscription;
  const isTrialPlanActive = planId === TRIAL_2022_08_PLAN;
  const currency = data.paymentAccount?.currency;
  const intervalValue = interval
    ? intl.formatMessage({ id: `general.plan.inteval.${interval.toLowerCase()}` })
    : '';

  const isFree = planId === FREE_PLAN;
  const isAllowedPause = !paused && !isTrialPlanActive;
  const resumeDate = pauseEvent?.resumesAt;
  const pauseDate = pauseEvent?.pausedAt;
  const startDiscountDate = lastSchedule?.trialEnd || lastSchedule?.startDate;

  const isAllowToPauseAgain = useMemo(() => {
    if (!pauseDate) return true;
    return moment().diff(moment(pauseDate), 'y') > 0;
  }, [pauseDate]);

  const nextPauseDate = useMemo(() => {
    if (!pauseDate) return null;
    return moment(pauseDate).add(1, 'y').format('MMM DD, YYYY');
  }, [pauseDate]);

  let renewDate = renewsAt;
  if (cancelAt) {
    renewDate = cancelAt;
  } else if (lastSchedule) {
    renewDate = startDiscountDate;
  }

  const renewAtDate = renewDate ? dateStringToNewYorkDate(renewDate, EN_DATE_FORMAT) : '';
  let renewalDescr = 'billing.current_plan.renewal';
  if (cancelAt) {
    renewalDescr = 'billing.current_plan.canceled';
  } else if (isTrialPlanActive) {
    renewalDescr = 'billing.current_plan.trial.renewal';
  }
  const price = priceAmount || 0;
  const offerDiscount = Number(lastSchedule?.discount?.percentOff) / 100 || 0;
  const priceSum = createSum(price, currency);
  const priceSumDiscount = createSum(price - price * offerDiscount, currency);

  const handleChangePlanClick = useCallback(() => {
    amplitude.sendEvent({
      id: '167',
      category: 'plans_and_billing',
      name: 'change_plan_click',
    });
  }, []);

  const pauseButton =
    isAllowedPause && !isFree && !cancelAt ? (
      <Tooltip
        id={`billing.current_plan.pause.button`}
        tooltipMsg={isAllowToPauseAgain ? undefined : 'billing.current_plan.pause.button'}
        tooltipValues={{ date: nextPauseDate }}
        place={'bottom'}
        className={styles.pauseButtonContainer}
      >
        <PauseButton disabled={!isAllowToPauseAgain} />
      </Tooltip>
    ) : null;

  const cancelButton =
    isAllowedPause && !isFree && !cancelAt ? (
      <CancelButton
        organizationId={organizationId}
        organizationName={organizationName}
        subscription={subscription}
        isAllowToPauseAgain={isAllowToPauseAgain}
      />
    ) : null;

  const resumeButton =
    paused && !isFree && !cancelAt ? <ResumeButton subscriptionId={subscriptionId} /> : null;
  const reactivateButton =
    cancelAt && !isFree ? <ReactivateSubscriptionButton subscriptionId={subscriptionId} /> : null;

  const changePlanButton =
    !pauseButton && !resumeButton && !cancelAt ? (
      <SmartLink
        className={styles.changePlanLink}
        path={PLANS_ROUTE}
        target="_blank"
        onClick={handleChangePlanClick}
      >
        <AlterButton
          type={isFree ? 'black' : 'grey'}
          msg="billing.current_plan.change_plan"
          className={styles.changePlan}
        />
      </SmartLink>
    ) : null;

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Text type="h1" msg="billing.current_plan.title" className={styles.title} />
        {isFree && (
          <Text type="md" msg="billing.current_plan.free_plan.descr" className={styles.freeDescr} />
        )}
        {!isFree && (
          <>
            <div className={styles.item}>
              {planId && <Text type="md" msg={`plan.name.${planId?.toLowerCase()}`} />}
              <Text type="label" msg="billing.current_plan.your_plan" className={styles.subtitle} />
            </div>
            <div className={styles.item}>
              {planId && priceSum && (
                <Text
                  type="md"
                  msg={
                    startDiscountDate && lastSchedule?.discount && !paused
                      ? 'billing.current_plan.price_discount'
                      : 'billing.current_plan.price'
                  }
                  formatValues={{
                    discount: priceSumDiscount,
                    sum: priceSum,
                    interval: intervalValue,
                  }}
                />
              )}
              {renewAtDate && !paused && (
                <Text
                  type="label"
                  msg={renewalDescr}
                  formatValues={{ date: renewAtDate }}
                  className={styles.subtitle}
                />
              )}
              {paused && (
                <Text
                  type="label"
                  msg={'billing.current_plan.pause_until'}
                  formatValues={{ date: moment(resumeDate).format('MMM DD, YYYY') }}
                  className={styles.subtitle}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className={styles.buttonContainer}>
        {pauseButton}
        {resumeButton}
        {cancelButton}
        {reactivateButton}
        {changePlanButton}
      </div>
      <PauseSubscriptionDrawer subscriptionId={subscriptionId} interval={interval} />
      <CancelOfferAcceptedSubscriptionDrawer subscription={subscription} />
      <CancelSuccessSubscriptionDrawer subscription={subscription} />
    </div>
  );
};

export default CurrentSubscriptionInfo;
