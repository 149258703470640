import React, { useMemo } from 'react';
import { graphql, PreloadedQuery, usePaginationFragment, usePreloadedQuery } from 'react-relay';

import styles from './CampaignCreatives.pcss';
import PublicationsReportButton from './PublicationsReportButton/PublicationsReportButton';
import { getStatData } from './utils';

import { ResultsQuery } from '../Results';

import TitleBase from 'Components/ui/TitleBase/TitleBase';
import LoaderHandlerWithHooks from 'Organisms/LoaderHandler/NewLoaderHandler';

import Creative from 'Molecules/Creative/Creative';
import Text from 'Components/ui/Text/Text';
import postStub from 'Images/general/postStub.svg';
import { getTimeFromNow } from 'Util/dateCreator';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import ReportButton, { ReportType } from 'Atoms/ReportButton/ReportButton';
import { CampaignCreatives_campaign$key } from 'GraphTypes/CampaignCreatives_campaign.graphql';
import { amplitude } from 'Helpers/amplitude';
import { INSTAGRAM, TIKTOK, FACEBOOK } from 'Constants/general';
import { ResultsQuery as QueryType } from 'GraphTypes/ResultsQuery.graphql';

const CREATIVES_COUNT = 12;
const FOOTER_OFFSET = 279;

interface Props {
  admin: boolean;
  campaignId: string;
  canChatWithCreators: boolean;
  queryReference: PreloadedQuery<QueryType>;
}

const CampaignCreatives: React.FC<Props> = (props) => {
  const { admin, canChatWithCreators, campaignId, queryReference } = props;
  const queryData = usePreloadedQuery<QueryType>(ResultsQuery, queryReference);

  const fragmentData = usePaginationFragment<QueryType, CampaignCreatives_campaign$key>(
    graphql`
      fragment CampaignCreatives_campaign on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 12 }
        cursor: { type: "String" }
        campaignId: { type: "ID!" }
      )
      @refetchable(queryName: "CampaignCreativesPaginationList") {
        campaign(id: $campaignId) {
          creatives(first: $count, after: $cursor)
            @connection(key: "Campaign_creatives", filters: []) {
            edges {
              node {
                id
                ...Creative_creative
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
          }
          type
          platform
          publishingRequired
          creativeInsightsLastCollectedAt
          counters {
            campaignPostingBudget
            publicationsEngagementSum
            publicationsViewsSum
          }
        }
      }
    `,
    queryData
  );

  const campaign = fragmentData.data.campaign;

  const items = Array.from(campaign?.creatives.edges || []);

  const totalCount = items.length;
  const platform = campaign?.platform;
  const campaignType = campaign?.type;
  const publishingRequired = campaign?.publishingRequired;

  const isShownStatsBtn = (platform === INSTAGRAM || platform === TIKTOK) && publishingRequired;

  const lastUpdatedTime = useMemo(
    () => getTimeFromNow(campaign?.creativeInsightsLastCollectedAt),
    [campaign?.creativeInsightsLastCollectedAt]
  );

  const createCreatives = () => {
    return items.map((item) => {
      if (!item?.node) return null;

      const { id } = item.node;

      return (
        <Creative
          key={id}
          creative={item.node}
          admin={admin}
          canChatWithCreators={canChatWithCreators}
        />
      );
    });
  };

  const handleSaveStats = () => {
    amplitude.sendEvent({
      id: 147,
      name: 'download_results_click',
      category: 'results',
      param: {
        campaign_id: campaignId,
      },
    });
  };

  const lastUpdatedText = (
    <div className={styles.lastUpdated}>
      <Tooltip id="lastUpdated" tooltipMsg="campaign.update_time" place="top">
        <Text
          type="label"
          msg="campaign.last_updated"
          className={styles.lastUpdatedText}
          formatValues={{ count: lastUpdatedTime }}
        />
      </Tooltip>
    </div>
  );

  const createStub = (
    <div className={styles.stubWrap}>
      <img src={postStub} />
      <Text msg="campaign.results.stub.descr" className={styles.stubDescr} />
    </div>
  );

  const statsData = useMemo(() => {
    return campaign ? getStatData(campaign) : [];
  }, [campaign]);

  return (
    <div className={styles.root}>
      {(platform === INSTAGRAM || platform === FACEBOOK || platform === TIKTOK) &&
      campaignType !== 'CONTENT_CREATION' &&
      totalCount === 0
        ? createStub
        : lastUpdatedTime && lastUpdatedText}
      {totalCount !== 0 && campaignType !== 'CONTENT_CREATION' && (
        <div className={styles.header}>
          <div className={styles.stats}>
            {statsData.map((item) => {
              const { value, titleMsg, tooltipMsg } = item;
              return (
                <TitleBase
                  key={titleMsg}
                  title={{ text: value }}
                  description={{ msg: titleMsg }}
                  tooltip={{ msg: tooltipMsg }}
                  className={styles.statItem}
                />
              );
            })}
          </div>
          <aside className={styles.action}>
            {(admin || isShownStatsBtn) && (
              <ReportButton
                type={ReportType.CAMPAIGN_PUBLICATIONS}
                onDownload={handleSaveStats}
                reportData={{ campaignId }}
              >
                <PublicationsReportButton />
              </ReportButton>
            )}
          </aside>
        </div>
      )}
      <LoaderHandlerWithHooks
        relay={fragmentData}
        offset={FOOTER_OFFSET}
        count={CREATIVES_COUNT}
        items={items}
      >
        <div className={styles.list}>{createCreatives()}</div>
      </LoaderHandlerWithHooks>
    </div>
  );
};

export default CampaignCreatives;
