import { graphql } from 'react-relay';

const FilterFormAgeListQuery = graphql`
  query FilterFormAgeListQuery {
    ageRanges {
      id
      name
    }
  }
`;

export default FilterFormAgeListQuery;
