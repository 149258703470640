import { graphql } from 'react-relay';

const FilterFormGenderListQuery = graphql`
  query FilterFormGenderListQuery {
    genders(currency: USD) {
      id
      name
    }
  }
`;

export default FilterFormGenderListQuery;
