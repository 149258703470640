import { graphql } from 'react-relay';

const FilterFormBadgeListQuery = graphql`
  query FilterFormBadgeListQuery {
    badges {
      id
      name
    }
  }
`;

export default FilterFormBadgeListQuery;
