import React, { ComponentType, useContext, useEffect } from 'react';

import { FiltersFormContext, FiltersFormContextType } from '../FiltersFormContext';

interface ListProps {
  queryData: {
    [key: string]: {
      id: string;
      name: string;
    }[];
  };
  items?: {
    id: string;
    name: string;
  }[];
}

const withSelection = <P extends object>(WrappedComponent: ComponentType<P>): React.FC<P> => {
  return (props: P & ListProps) => {
    const { fieldKey, isMultiselect } = props;
    const { filters, visibleValues, setFilterValueByKey } =
      useContext<FiltersFormContextType>(FiltersFormContext);

    const [value, changeValue] = React.useState<string | string[]>(filters[fieldKey] || '');

    useEffect(() => {
      changeValue(filters[fieldKey] || '');
    }, [filters[fieldKey]]);

    const handleChangeValue = (id: string, name: string) => {
      const isActive = isMultiselect ? value.includes(id) : value === id;
      let newValue: string | string[] = id;
      let visibleValue: string | string[] = name;
      if (isMultiselect) {
        newValue = isActive
          ? (value as string[]).filter((item) => item !== id)
          : [...(value as string[]), id];
        visibleValue = isActive
          ? (visibleValues[fieldKey] as string[]).filter((item) => item !== name)
          : [...(visibleValues[fieldKey] || []), name];
      }
      changeValue(newValue);
      setFilterValueByKey(fieldKey, newValue, visibleValue);
    };

    return (
      <WrappedComponent
        {...(props as P)}
        handleChangeValue={handleChangeValue}
        value={value}
        visibleValue={visibleValues[fieldKey] || null}
      />
    );
  };
};

export default withSelection;
