import React, { useEffect, useContext } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track, { useTracking } from 'react-tracking';
import { useNavigate } from 'react-router-dom';

import BriefContent from '../BriefContent/BriefContent';

import styles from './ListingBrief.css';
import ListingAcceptanceActions from './ListingAcceptanceActions/ListingAcceptanceActions';

import markListingAsRead from 'Mutations/MarkListingAsRead.Mutation';
import environment from 'Api/Environment';
import { ListingBriefQuery as QueryItem } from 'GraphTypes/ListingBriefQuery.graphql';
import { CONTRACTOR, ERROR_ROUTE, DASHBOARD_ROUTE } from 'Constants/general';
import { campaignPausedInfo } from 'Constants/messageData';
import { modalName } from 'Types/modals';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

const ListingBriefQuery = graphql`
  query ListingBriefQuery($id: ID!) {
    listing(id: $id) {
      name
      campaignType
      cover {
        ... on Image {
          transformations {
            collageThumbnailUrl
          }
        }
      }
      campaign {
        id
        activation {
          paused
        }
        completion {
          id
        }
        ...BriefContent_campaign
      }
      currency
      canApplyWith {
        totalCount
      }
      platform
      paymentType
      brief {
        id
        __typename
        ... on V2Brief {
          paidSocialActivationDays
          productDelivery
        }
      }
    }
    currentUser {
      type
    }
  }
`;

interface Props {
  listingId: string;
}

const ListingBrief: React.FC<Props> = (props) => {
  const { listingId } = props;

  const tracking = useTracking();
  const navigate = useNavigate();
  const { dispatch: modalDispatch } = useContext(ModalContext);

  useEffect(() => {
    markListingAsRead({ listingId });
  }, []);

  return (
    <QueryRenderer<QueryItem>
      environment={environment}
      query={ListingBriefQuery}
      variables={{
        id: listingId,
      }}
      render={({ props: queryProps }) => {
        if (!queryProps) return null;

        const userType = queryProps.currentUser?.type;
        const isCreator = userType === CONTRACTOR;

        const listing = queryProps.listing;

        if (!listing) {
          navigate(ERROR_ROUTE);

          return null;
        }

        const campaign = listing?.campaign;
        const campaignType = listing?.campaignType;
        const campaignPlatform = listing?.platform;

        const brief = listing?.brief;

        const hasProfiles =
          !!(listing?.canApplyWith?.totalCount && listing?.canApplyWith?.totalCount > 0) ||
          (campaignType === 'INFLUENCER' &&
            (campaignPlatform === 'TIKTOK' || campaignPlatform === 'YOUTUBE'));

        const paidSocialActivationDays = brief?.paidSocialActivationDays;
        const productDelivery = brief?.productDelivery;

        const campaignActivationPaused = listing.campaign?.activation?.paused;
        const campaignCompletion = listing.campaign?.completion?.id;
        const campaignId = listing.campaign?.id;

        const currency = listing.currency;

        if (campaignActivationPaused || campaignCompletion) {
          tracking.trackEvent({
            amplitude: true,
            event_id: 'd-v1-e19',
            event_cat: 'pageview',
            event_name: 'brief_of_paused_campaign',
            event_param: { type: 'listing', campaignId },
          });
          const handleViewOtherClick = () => {
            tracking.trackEvent({
              amplitude: true,
              event_id: 'd-v1-e20',
              event_cat: 'campaign',
              event_name: 'view_other_click',
              event_param: { type: 'listing', campaignId },
            });
            navigate(DASHBOARD_ROUTE);
          };
          modalDispatch({
            type: ModalTypes.SET_MODAL,
            payload: {
              name: modalName.SIMPLE,
              attach: {
                ...campaignPausedInfo,
                closeDisable: true,
                type: 'full',
                buttonData: { ...campaignPausedInfo.buttonData, onClick: handleViewOtherClick },
              },
            },
          });

          return null;
        }

        return (
          <div>
            <div className={styles.content}>
              <BriefContent campaign={campaign} currency={currency} isCreator={isCreator} />
            </div>
            {isCreator && (
              <ListingAcceptanceActions
                hasProfiles={hasProfiles}
                listingId={listingId}
                productDelivery={productDelivery}
                paidSocialActivationDays={paidSocialActivationDays}
              />
            )}
          </div>
        );
      }}
    />
  );
};

export default track()(ListingBrief);
