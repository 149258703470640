import React, { useContext } from 'react';

import { InviteOutreachSequenceWarning } from '../InviteOutreachCreatorsContext';

import styles from './SequenceSuggestionDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import Icon from 'Components/ui/Icon/Icon';
import applyLinkImg from 'Images/general/apply_link_outreach.png';
interface Props {
  onSend: () => void;
  onActionClick: (action: InviteOutreachSequenceWarning) => void;
  warnings: InviteOutreachSequenceWarning[];
}

const warningTypes = [
  InviteOutreachSequenceWarning.LOW_CREATORS,
  InviteOutreachSequenceWarning.LOW_SEQUENCE_STEPS,
  InviteOutreachSequenceWarning.NO_APPLY_LINK,
];

const SequenceSuggestionDrawer: React.FC<Props> = (props) => {
  const { warnings, onSend, onActionClick } = props;
  const { closeDrawer } = useContext(DrawerContext);

  const handleButtonClick = () => {
    onActionClick(warnings[0]);
    closeDrawer('sequence-warning');
  };

  return (
    <Drawer removeWhenClosed className={styles.drawer} rootKey="sequence-warning">
      <div className={styles.contentRoot}>
        <div className={styles.textContent}>
          <Text
            type="d2"
            msg="invite_outreach_creators.drawer.warning.title"
            className={styles.textPreset}
          />
          <Text
            type="md"
            msg="invite_outreach_creators.drawer.warning.description"
            className={styles.textPreset}
          />
          {warningTypes.map((warning) => {
            return (
              <div key={warning} className={styles.featureContainer}>
                <Icon
                  name={warnings.includes(warning) ? 'Close-small' : 'Check'}
                  color={warnings.includes(warning) ? 'red' : 'green'}
                />
                <Text type="md" msg={`invite_outreach_creators.drawer.warning.${warning}`} />
              </div>
            );
          })}
          {warnings.includes(InviteOutreachSequenceWarning.NO_APPLY_LINK) && (
            <>
              <img src={applyLinkImg} className={styles.applyImg} />
              <Text type="label" msg="invite_outreach_creators.drawer.warning.apply_link" />
            </>
          )}
          <Text
            type="md"
            msg="invite_outreach_creators.drawer.warning.description_2"
            className={styles.textPresetDescription}
          />
          <Text
            type="md"
            msg="invite_outreach_creators.drawer.warning.support"
            className={styles.textPresetDescription}
          />
        </div>
        <div className={styles.submitButtonWrapper}>
          <Button
            fluid
            color="black"
            dataTest="button:sequenceSuggestionActionButton"
            className={styles.submitButton}
            onClick={handleButtonClick}
            msg={`invite_outreach_creators.drawer.warning.action.${warnings[0]}`}
          />
          <Button
            fluid
            dataTest="button:sequenceSendActionButton"
            className={styles.submitButton}
            onClick={onSend}
            msg={`invite_outreach_creators.drawer.warning.action.send`}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default SequenceSuggestionDrawer;

// types

export type { Props as CustomListCreateDrawerProps };
