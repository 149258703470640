import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import { fetchQuery } from 'relay-runtime';

import styles from './ContractorSignUpForm.pcss';
import { AuthFormType } from './FirebaseAuth';
import { amplitude } from 'Helpers/amplitude';
import imageSrc1x from 'Images/creators/outreach_screens_1x.png';
import imageSrc2x from 'Images/creators/outreach_screens_2x.png';
import {
  INSENSE_PRIVACY_POLICY,
  TERMS_OF_SERVICE,
  CONTRACTOR_PROJECT_OFFER_ROUTE,
  FOR_CREATORS_LINK,
} from 'Constants/general';
import { CurrentUserQuery } from 'GraphTypes/CurrentUserQuery.graphql';
import environment from 'Api/Environment';
import currentUserQuery from 'Containers/Auth0/Auth0Context/CurrentUser.Query';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import Logo from 'Atoms/Logo/Logo';
import { emailValidate, passwordValidate } from 'Util/validate';
import { firebaseClient } from 'Containers/Auth0/Auth0Context/utils';
import CreatorCities from 'Molecules/CreatorCities/CreatorCities';
import { useCreatorSignUpFlow } from 'Containers/Auth0/Auth0Context';

type Props = {
  changeFormType: (formType: AuthFormType) => void;
};

export const ContractorSignUpForm: FC<Props> = () => {
  const { state } = useLocation() as {
    state: {
      isInvite?: string;
      sp?: string;
      outreachEmail?: string;
      brandName?: string;
    };
    search: string;
  };

  const brandName = state?.brandName;

  const handlePolicyClick = () => {
    window.open(INSENSE_PRIVACY_POLICY);
  };
  const handleTermsOfServiceClick = () => {
    window.open(TERMS_OF_SERVICE);
  };
  const handleInsenseClick = () => {
    window.open(FOR_CREATORS_LINK);
  };
  const { runSignUpCreatorFlow } = useCreatorSignUpFlow();
  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [cityIdValue, setCityIdValue] = useState('');
  const [email, setEmail] = useState(state?.outreachEmail || '');
  const [password, setPassword] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [error, setError] = useState<ErrorList>({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    password: undefined,
    city: undefined,
  });
  const [loading, setLoadingStatus] = useState(false);

  const token = sessionStorage.getItem('outreachToken');
  const isInvitedByOutreach = Boolean(token);

  const handleCreatorSighUpFlow = async () => {
    const result = await runSignUpCreatorFlow({
      firstName: firstNameValue,
      lastName: lastNameValue,
      cityId: cityIdValue,
    });

    setTimeout(() => {
      if (result && token) {
        window.open(`${CONTRACTOR_PROJECT_OFFER_ROUTE}?token=${token}`, '_self');
      }
    }, 100);
  };
  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstNameValue(e.target.value);
  };
  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastNameValue(e.target.value);
  };
  const handleFirstNameReset = () => {
    setFirstNameValue('');
  };
  const handleLastNameReset = () => {
    setLastNameValue('');
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleEmailReset = () => {
    setEmail('');
  };
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handlePasswordReset = () => {
    setPassword('');
  };
  const handleCityChange = (newCityIds: string[]) => {
    setCityIdValue(newCityIds[0]);
  };

  useEffect(() => {
    if (firstNameValue && email && password && cityIdValue) {
      setShowErrors(true);
    }
  }, [firstNameValue, email, password, cityIdValue]);

  const value = useMemo(() => {
    return {
      firstName: firstNameValue,
      lastName: lastNameValue,
      email,
      password,
      city: cityIdValue,
    };
  }, [firstNameValue, lastNameValue, email, password, cityIdValue]);

  const isFormValid = useMemo(() => {
    const emailErrors = emailValidate({}, email);
    const passwordErrors = passwordValidate({}, password);
    const errors = Object.keys(value).reduce((err: ErrorList, valKey: ContractorSignUpFields) => {
      const tmpErrList = { ...err };

      if (!value[valKey]) {
        tmpErrList[valKey] = true;
      }
      if (valKey === 'firstName') {
        if (!value.firstName) {
          tmpErrList[valKey] = 'form.error_required';
        }
      }
      if (valKey === 'lastName') {
        if (!value.lastName) {
          tmpErrList[valKey] = 'form.error_required';
        }
      }
      if (valKey === 'email') {
        if (emailErrors?.email) {
          tmpErrList[valKey] = emailErrors?.email;
        }
      }
      if (valKey === 'password') {
        if (passwordErrors?.password) {
          tmpErrList[valKey] = passwordErrors?.password;
        }
      }
      if (valKey === 'city') {
        if (!value.city) {
          tmpErrList[valKey] = 'form.error_required';
        }
      }

      return tmpErrList;
    }, {});
    if (Object.keys(errors).length) {
      setError(errors);
      return false;
    }

    setError({});
    return true;
  }, [value]);

  const handleRegistration = async () => {
    if (loading) return;
    if (!isFormValid) return;

    amplitude.sendEvent({
      id: '468',
      category: 'campaign',
      name: 'outreach_agreed_and_signed_up',
    });

    setLoadingStatus(true);
    try {
      await createUserWithEmailAndPassword(firebaseClient.authClient, email, password);
      setTimeout(() => {
        fetchQuery<CurrentUserQuery>(
          environment,
          currentUserQuery,
          {},
          { fetchPolicy: 'network-only' }
        ).subscribe({
          next: (data) => {
            if (data?.currentUser) {
              handleCreatorSighUpFlow();
            }
          },
        });
      }, 1000);
    } catch (e) {
      if (e.code === 'auth/email-already-in-use') {
        setError({ email: 'form.error_user_already_exists' });
      } else if (e.code === 'auth/invalid-email') {
        setError({ email: 'form.error_email_invalid' });
      } else {
        setError({ email: 'general.error' });
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const title = brandName ? 'outreach.sign_up.brandname_title' : 'outreach.sign_up.title';

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <Logo className={styles.logo} />
      </header>
      <div className={styles.imageWrapper}>
        <img srcSet={`${imageSrc1x} 1x, ${imageSrc2x} 2x`} />
      </div>
      <div className={styles.container}>
        <Text
          weight="bold"
          msg={title}
          type="d1"
          className={styles.title}
          formatValues={{ brandName }}
        />
        <Text
          type="md"
          msg="outreach.sign_up.description"
          className={styles.subtitle}
          formatValues={{
            insenseForCreatorsLink: (
              <TextButton
                msg="outreach.sign_up.insense"
                color="pink"
                size="s"
                onClick={handleInsenseClick}
                className={styles.inlineLink}
              />
            ),
          }}
        />
        <div className={styles.form}>
          <div className={styles.inputContainer}>
            <Input
              bordered
              placeholderMsg="outreach.sign_up.email.placeholder"
              type="email"
              id="email"
              autoComplete="email"
              required
              value={email}
              className={styles.input}
              onChange={handleEmailChange}
              onResetValue={handleEmailReset}
              error={showErrors && !!error?.email}
              disabled={Boolean(isInvitedByOutreach && email)}
              hideCloseIcon={Boolean(isInvitedByOutreach && email)}
            />
            {showErrors && !!error?.email && (
              <Text type="s" msg={error.email.toString()} className={styles.errorText} />
            )}
          </div>

          <div className={styles.inputContainer}>
            <Input
              bordered
              placeholderMsg="field.placeholder.password"
              type="password"
              id="new-password"
              autoComplete="new-password"
              required
              className={styles.input}
              onChange={handlePasswordChange}
              onResetValue={handlePasswordReset}
              maxLength={32}
              error={showErrors && !!error?.password}
            />
            {showErrors && !!error?.password && (
              <Text type="s" msg={error.password.toString()} className={styles.errorText} />
            )}
          </div>

          <div className={styles.inputContainer}>
            <Input
              bordered
              placeholderMsg="outreach.sign_up.first_name.placeholder"
              type="text"
              id="new-first-name"
              required
              className={styles.input}
              onChange={handleFirstNameChange}
              onResetValue={handleFirstNameReset}
              error={showErrors && !!error?.firstName}
            />
            {showErrors && !!error?.firstName && (
              <Text type="s" msg={error.firstName.toString()} className={styles.errorText} />
            )}
          </div>

          <div className={styles.inputContainer}>
            <Input
              bordered
              placeholderMsg="outreach.sign_up.last_name.placeholder"
              type="text"
              id="new-last-name"
              required
              className={styles.input}
              onChange={handleLastNameChange}
              onResetValue={handleLastNameReset}
              error={showErrors && !!error?.lastName}
            />
            {showErrors && !!error?.lastName && (
              <Text type="s" msg={error.lastName.toString()} className={styles.errorText} />
            )}
          </div>

          <div className={styles.inputContainer}>
            <CreatorCities
              isSingleSelect={true}
              className={styles.cities}
              cities={[{ id: cityIdValue, name: '' }]}
              onCitiesChange={handleCityChange}
              error={showErrors && !!error?.city}
            />
            {showErrors && !!error?.city && (
              <Text type="s" msg={error.city.toString()} className={styles.errorText} />
            )}
          </div>
          <Text
            inline
            msg="outreach.sign_up.tos_agreement"
            className={styles.tosAgreement}
            type="md"
            formatValues={{
              TOSlink: (
                <TextButton
                  msg="menu.terms_of_use"
                  color="pink"
                  size="s"
                  onClick={handleTermsOfServiceClick}
                  className={styles.inlineLink}
                />
              ),
              privacyPolicyLink: (
                <TextButton
                  msg="menu.privacy_policy"
                  color="pink"
                  size="s"
                  onClick={handlePolicyClick}
                  className={styles.inlineLink}
                />
              ),
            }}
          />
          <Button
            fluid
            color="black"
            msg="outreach.sign_up.btn"
            className={styles.submitButton}
            onClick={handleRegistration}
            loading={loading}
            disabled={!isFormValid}
          />
        </div>
      </div>
    </div>
  );
};

type ContractorSignUpProps = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  city: string;
};
type ContractorSignUpFields = keyof ContractorSignUpProps;
type ErrorList = {
  [field in ContractorSignUpFields]?: boolean | string;
};
