import React, { useContext, useState } from 'react';

import styles from './FilterForm.pcss';
import FiltersFormDrawer from './FiltersFormDrawer/FiltersFormDrawer';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  onClick?: () => void;
}

const AllFiltersButton: React.FC<Props> = (props) => {
  const { onClick } = props;
  const [active, setActive] = useState(false);
  const { openDrawer } = useContext(DrawerContext);
  const handleButtonClick = () => {
    onClick?.();
    openDrawer('filters-form-drawer');
  };

  const handleCountersChange = (value: number[]) => {
    const num = value?.reduce((acc, item) => {
      return acc + item;
    }, 0);
    setActive(num > 0);
  };

  return (
    <>
      <AlterButton
        icon={'Filter'}
        type="white"
        bordered={active}
        active={active}
        hover={false}
        msg="filter.form.button.all_filters"
        onClick={handleButtonClick}
        className={styles.allFiltersButton}
      />
      <FiltersFormDrawer onCountersChange={handleCountersChange} />
    </>
  );
};

export default React.memo(AllFiltersButton);
