import React, { useContext } from 'react';
import classNames from 'classnames';

import styles from './ListViewSwitch.pcss';

import Icon from 'Components/ui/Icon/Icon';
import {
  DiscoveryCreatorsContext,
  DiscoveryListViewType,
} from 'Page/advertiser/Discovery/DiscoveryCreatorsContext';

const ListViewSwitch: React.FC = () => {
  const { setListView, listView } = useContext(DiscoveryCreatorsContext);

  const handleSelectListView = () => {
    setListView(DiscoveryListViewType.LIST);
  };

  const handleSelectGridView = () => {
    setListView(DiscoveryListViewType.GRID);
  };
  return (
    <div className={styles.root}>
      <div
        className={classNames(styles.item, {
          [styles.active]: listView === DiscoveryListViewType.LIST,
        })}
        onClick={handleSelectListView}
      >
        <Icon name="Bullited-list" size={16} color="grey" />
      </div>
      <div
        className={classNames(styles.item, {
          [styles.active]: listView === DiscoveryListViewType.GRID,
        })}
        onClick={handleSelectGridView}
      >
        <Icon name="Assets" size={16} color="grey" />
      </div>
    </div>
  );
};

export default React.memo(ListViewSwitch);
