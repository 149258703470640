import React, { useReducer, PropsWithChildren, FC, useEffect } from 'react';

import { FiltersFormContext, FiltersFormContextType } from './FiltersFormContext';
import {
  FiltersFormActionTypes,
  FiltersFormReducer,
  FiltersFormReducerType,
} from './FiltersFormReducer';

import { CONTENT, INSTAGRAM, TIKTOK } from 'Constants/general';
import { SearchQueryInput } from 'GraphTypes/DiscoverySearchResultPaginationList.graphql';

interface Props {
  defaultFilters?: Partial<SearchQueryInput>;
  onParamsReset?: () => void;
  onParamsChange: (params: SearchQueryInput) => void;
}

const FiltersFormProvider: FC<PropsWithChildren<Props>> = (props) => {
  const { children, onParamsChange, onParamsReset, defaultFilters } = props;
  const initialValue: FiltersFormContextType = {
    filters: {
      acceptsBarter: null,
      active: null,
      ageRangeIds: [],
      audienceAgeRangePercentage: null,
      audienceCitiesIds: [],
      audienceCityPercentage: null,
      audienceCountryPercentage: null,
      audienceEthnicityIds: [],
      audienceGenderId: null,
      audienceGenderPercentage: null,
      audienceInterestPercentage: null,
      businessAccountEnabled: null,
      campaignsCompletionsCountFrom: null,
      campaignsCompletionsCountTo: null,
      categoryIds: [],
      cityIds: [],
      contentCreator: null,
      countryIds: [],
      creatorAnimalIds: [],
      creatorBadgeIds: [],
      creatorBirthdateFrom: null,
      creatorBirthdateTo: null,
      creatorBodyTypeIds: [],
      creatorCountryIds: [],
      creatorEthnicityIds: [],
      creatorGenderId: null,
      creatorHairTypeIds: [],
      creatorIdsToExclude: [],
      creatorLanguageIds: [],
      creatorTagIds: [],
      creatorTypes: [INSTAGRAM, TIKTOK, CONTENT],
      currency: 'USD',
      customListIds: [],
      employed: null,
      engagementRateFrom: null,
      engagementRateTo: null,
      excludeBadges: null,
      excludeCreatorsWorkedWith: null,
      excludeHiredMoreThanOnce: null,
      exclusions: [],
      experienced: null,
      favorite: null,
      followersFrom: null,
      followersTo: null,
      hashtags: [],
      highAverageRating: null,
      includeAmazonStorefrontLink: null,
      includeHidden: null,
      interestIds: [],
      limit: null,
      onlyCreatorsWorkedWith: null,
      owned: null,
      paidSocialEnabled: null,
      postPriceFrom: null,
      postPriceTo: null,
      postsReachFrom: null,
      postsReachTo: null,
      realFollowersFrom: null,
      realFollowersTo: null,
      reelsPlaysMedianFrom: null,
      reelsPlaysMedianTo: null,
      reliable: null,
      reviewedAtFrom: null,
      reviewedAtTo: null,
      storiesReachFrom: null,
      storiesReachTo: null,
      strictlyByPrimaryCategory: null,
      textQuery: null,
      viewsMedianFrom: null,
      viewsMedianTo: null,
      viewsPerPostFrom: null,
      viewsPerPostTo: null,
      withTiktokShop: null,
      ...defaultFilters,
    },
    visibleValues: {},
  };

  const filtersFormDefaultValues = initialValue.filters;

  const [state, dispatch] = useReducer<FiltersFormReducerType>(FiltersFormReducer, initialValue);

  useEffect(() => {
    onParamsChange(state.filters);
  }, [state.filters]);

  const setFilterValueByKey = (
    key: string,
    value: string | string[] | boolean,
    visibleValues?: string | string[]
  ) => {
    dispatch({
      type: FiltersFormActionTypes.SET_FILTER,
      payload: { name: key, value, visibleValues },
    });
  };

  const setFiltersValue = (value: unknown, visibleValue: unknown) => {
    dispatch({
      type: FiltersFormActionTypes.MERGE_FILTERS,
      payload: {
        filters: value as SearchQueryInput,
        visibleValues: visibleValue as SearchQueryInput,
      },
    });
  };

  const resetFilters = () => {
    onParamsReset?.();
    dispatch({
      type: FiltersFormActionTypes.RESET_FILTERS,
      payload: filtersFormDefaultValues,
    });
  };

  const setFilterList = (filters: unknown) => {
    dispatch({
      type: FiltersFormActionTypes.SET_LIST,
      payload: filters,
    });
  };

  const setAdminStatus = (status: boolean) => {
    dispatch({
      type: FiltersFormActionTypes.SET_ADMIN_STATUS,
      payload: status,
    });
  };

  return (
    <FiltersFormContext.Provider
      value={{
        ...state,
        setFiltersValue,
        setFilterValueByKey,
        setFilterList,
        resetFilters,
        setAdminStatus,
        filtersFormDefaultValues,
      }}
    >
      {children}
    </FiltersFormContext.Provider>
  );
};

export { FiltersFormProvider };
