import React, { useContext, useMemo } from 'react';

import { FiltersFormContext, FiltersFormContextType } from '../FiltersFormContext';
import { FilterFormFilterType, FilterFormList } from '../utils';

import styles from './FiltersParams.pcss';
import FilterParameter from './FilterParameter/FilterParameter';

import TextButton from 'Components/ui/TextButton/TextButton';
import { SearchQueryInput } from 'GraphTypes/DiscoverySearchResultPaginationList.graphql';

const FiltersParams: React.FC = () => {
  const { visibleValues, filters, setFilterValueByKey, resetFilters, filtersFormDefaultValues } =
    useContext<FiltersFormContextType>(FiltersFormContext);
  const handleResetFilterValue = (key: keyof SearchQueryInput, index?: number) => {
    if (index !== undefined) {
      const filterredValue = filters[key].filter((_, i) => i !== index);
      if (Array.isArray(visibleValues[key])) {
        const filterredVisibleValue = visibleValues[key].filter((_, i) => i !== index);
        setFilterValueByKey(key, filterredValue, filterredVisibleValue);
      }
    } else {
      if (!filters[key]) {
        if (FilterFormList[key]?.fields) {
          FilterFormList[key]?.fields.forEach((field) => {
            const fieldKey = field.fieldKey as keyof SearchQueryInput;
            setFilterValueByKey(
              fieldKey,
              filtersFormDefaultValues[fieldKey],
              filtersFormDefaultValues[fieldKey]
            );
          });
        }
      }
      setFilterValueByKey(key, filtersFormDefaultValues[key]);
    }
    if (FilterFormList[key]?.percentageField) {
      const percentKey = FilterFormList[key]?.percentageField.fieldKey as keyof SearchQueryInput;
      setFilterValueByKey(
        percentKey,
        filtersFormDefaultValues[percentKey],
        filtersFormDefaultValues[percentKey]
      );
    }
  };

  const filtersWithValues = useMemo(() => {
    return Object.keys(visibleValues).filter((key) => {
      if (Array.isArray(visibleValues[key])) {
        return (
          visibleValues[key].length &&
          visibleValues[key].some((item) => item !== '' && item !== null)
        );
      }
      return visibleValues[key] && visibleValues[key].length && key !== 'textQuery';
    });
  }, [visibleValues]);

  return (
    <div className={styles.root}>
      {filtersWithValues.map((key) => {
        if (visibleValues[key] === null || visibleValues[key] === '') {
          return null;
        }
        const filterInList = FilterFormList[key];
        const typeofFilter = filterInList?.type;
        const isReverseValues = filterInList?.reverseValues;
        const titleLabel = filterInList?.isShowTitleLabel ? filterInList?.title : undefined;
        let percentValue = null;
        if (filterInList?.percentageField && filters[filterInList?.percentageField.fieldKey]) {
          percentValue = filters[filterInList?.percentageField.fieldKey];
        }
        // Dirty, but it works
        const isAudience = filterInList?.title?.includes('audience');
        if (
          Array.isArray(visibleValues[key]) &&
          typeofFilter !== FilterFormFilterType.INPUT_RANGE
        ) {
          return visibleValues[key].map((item, index) => {
            return (
              <FilterParameter
                isAudience={isAudience}
                key={`${key}_${index}`}
                onRemove={(key) => handleResetFilterValue(key as keyof SearchQueryInput, index)}
                filterKey={key}
                value={item}
                reverseValues={isReverseValues}
                titleLabel={titleLabel}
                percentValue={percentValue}
              />
            );
          });
        }
        return (
          <FilterParameter
            isAudience={isAudience}
            key={key + visibleValues[key]}
            onRemove={handleResetFilterValue}
            filterKey={key}
            value={visibleValues[key]}
            reverseValues={isReverseValues}
            titleLabel={titleLabel}
            percentValue={percentValue}
          />
        );
      })}
      {filtersWithValues.length > 0 && (
        <TextButton onClick={resetFilters} msg="filter.form.button.reset_filters" />
      )}
    </div>
  );
};

export default FiltersParams;
