import React, { useMemo, MouseEventHandler } from 'react';
import classNames from 'classnames';

import { IconName } from '../types';

import styles from './Icon.pcss';

type Props = {
  name: IconName;
  size?: number;
  className?: string;
  color?: 'green' | 'red' | 'sun' | 'white' | 'grey' | 'pink' | 'purple';
  onClick?: MouseEventHandler<HTMLButtonElement>;
};
const Icon: React.FC<Props> = (props) => {
  const { name, size = 24, color = '', className, onClick } = props;

  const additionalStyles = useMemo(
    () => ({
      ...(size ? { fontSize: size } : {}),
    }),
    [size]
  );

  const classList = classNames(
    styles[`icon-${name}`],
    styles[color || ''],
    { [styles.withPointer]: !!onClick },
    className
  );

  return <i className={classList} onClick={onClick} style={additionalStyles} />;
};

export default Icon;
export type { Props };
