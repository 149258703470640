import React, { ChangeEvent, useDeferredValue, useMemo, useState } from 'react';

import withQuery from '../FilterWithQuery';

import styles from './FilterList.pcss';
import FilterListItems from './FilterListItems';

import { FilterFormItemInputList } from 'Components/FiltersForm/FilterForm';
import Input from 'Components/ui/Input/Input';
import Icon from 'Components/ui/Icon/Icon';

interface ListProps {
  list: {
    [key: string]: string;
  }[];
  value: string | string[];
  handleChangeValue: (id: string, name: string) => void;
}

const FilterList: React.FC<FilterFormItemInputList & ListProps> = (props) => {
  const { withSearch, itemsQuery } = props;
  const [filterText, setFilterText] = useState('');

  const filterTextValue = useDeferredValue(filterText);

  const handleFilterTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value.toLowerCase().trim() || '');
  };

  const ListComponent = useMemo(() => {
    if (itemsQuery) {
      return React.memo(withQuery(FilterListItems));
    }
    return FilterListItems;
  }, [itemsQuery]);

  return (
    <div className={styles.root}>
      {withSearch && (
        <div className={styles.filterContainer}>
          <Icon name="Search-loop" />
          <Input
            type="text"
            placeholder="Search"
            onChange={handleFilterTextChange}
            value={filterText}
            hideCloseIcon
            borderless
          />
        </div>
      )}
      <div className={styles.list}>
        <ListComponent {...props} searchQueryText={filterTextValue} />
      </div>
    </div>
  );
};

export default FilterList;
