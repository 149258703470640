/* eslint-disable max-len */

export const insenseSequencePresets = [
  {
    node: {
      name: 'UGC',
      steps: {
        edges: [
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 0,
              projectEventType: 'OFFER',
              template: {
                subject: 'Paid UGC Gig – No Posting, Just Content Creation! 😎',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />It’s <span contenteditable="false" data-variable="true">{{sender_name}}</span>&nbsp;from <span contenteditable="false" data-variable="true">{{brand_name}}</span>! We came across your account and were impressed by your content style and creative approach. We’d love to collaborate with you on a UGC campaign – no posting required!<br /><br /><b>Campaign Details</b>:<br /><br /><ul><li><b>Brand</b>: <span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;</li><li><b>Deliverables</b>: <span contenteditable="false" data-variable="true">{{deliverables}}</span>&nbsp;(with a focus on hooks, storytelling, and a strong call-to-action)<br /></li><li><b>Total Payout</b>: $<span contenteditable="false" data-variable="true">{{price_cap}}</span> + Product valued at <span contenteditable="false" data-variable="true">{{product_price}}</span>&nbsp;</li><li><b>Posting Not Required</b>: We only need your expertise in creating engaging content for ads.</li></ul><br />If you\'re interested, check out the brief and secure your spot here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Start Your Application</a>. <b><u>[Only [ ] spots are left!]</u></b><br /><br />Let me know if you’d like to discuss more. And if it’s not the right fit, no worries – we’ll keep you in mind for future campaigns.<br /><br />Looking forward to your thoughts!<br /><br />Best regards,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 4,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Quick Follow-Up – Paid UGC Content Creation!',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />I hope your day is going well! I wanted to check back regarding the UGC collaboration opportunity with <span contenteditable="false" data-variable="true">{{brand_name}}</span>. This is a great way to showcase your skills in creating high-quality ad content without posting on your own feed.<br /><br /><b>Recap of the Campaign</b>:<br /><br /><ul><li><b>Deliverables</b>: <span contenteditable="false" data-variable="true">{{deliverables}}</span>&nbsp;focused on impactful storytelling<br /></li><li><b>Total Payout</b>: $<span contenteditable="false" data-variable="true">{{price_cap}}</span> + Product valued at <span contenteditable="false" data-variable="true">{{product_price}}</span>&nbsp;</li><li><b>No Posting Needed</b>: Just create, and we’ll handle the rest.<br /></li></ul><br />We have limited spots available – only <b><u>[ ] spots left!</u></b> If you’re interested, please take a look at the brief here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a><br /><br />Let me know if you have any questions!<br /><br />Kind Regards,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 3,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Last Chance to Join Our Paid UGC Campaign! 🎯',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />I just wanted to send a quick final reminder about the exciting paid UGC opportunity with <span contenteditable="false" data-variable="true">{{brand_name}}</span>. We’d love for you to join, but <b><u>only [ ] spots are left!</u></b><br /><br />Here’s a recap:<br /><br /><b>Brand</b>: <span contenteditable="false" data-variable="true">{{brand_name}}</span><br /><b>Deliverables</b>: <span contenteditable="false" data-variable="true">{{deliverables}}</span>&nbsp;for social ads<br /><b>No Social Posting Required</b>: Just pure content creation<br /><b>Total Payout</b>: $<span contenteditable="false" data-variable="true">{{price_cap}}</span> + Product valued at <span contenteditable="false" data-variable="true">{{product_price}}</span><br /><br /><a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Start Your Application</a>&nbsp;<br /><br />This is a unique opportunity to get creative without needing to post! If you’re interested but have questions, feel free to reach out.<br /><br />Looking forward to hearing from you!<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 3,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Thanks for Considering the {{brand_name}} UGC Campaign!',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />It looks like you may not be interested in moving forward with the <span contenteditable="false" data-variable="true">{{brand_name}}</span> UGC campaign right now, and that’s completely fine!<br /><br />Thank you for considering this opportunity. We’ll keep you in mind for future campaigns that might be a better match for your goals and style.<br /><br />If you’d like to stay updated on upcoming opportunities, just let us know – we’d love to work together in the future!<br /><br />Thanks again, <span contenteditable="false" data-variable="true">{{creator_name}}</span>!<br /><br />Best wishes,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;<br>',
              },
            },
          },
        ],
      },
    },
  },
  {
    node: {
      name: 'Product Seeding',
      steps: {
        edges: [
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 0,
              projectEventType: 'OFFER',
              template: {
                subject: 'A Gift for You, {{creator_name}} – No Strings Attached! 🎁',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>&nbsp;,<br /><br />I’m <span contenteditable="false" data-variable="true">{{sender_name}}</span>&nbsp;from <span contenteditable="false" data-variable="true">{{brand_name}}</span>. We love your content on <span contenteditable="false" data-variable="true">{{platform}}</span> and would like to invite you to apply to our latest campaign, where you’ll get to try our <span contenteditable="false" data-variable="true">{{product_title}}</span>&nbsp;– completely free, no strings attached!<br /><br />This isn’t about commitments; we simply think our product aligns with your passion for {topic_of_interest: wellness, lifestyle, etc.}, and we’d love for you to experience it. If you enjoy it, this could be a first step in building a relationship with us!<br /><br />If this sounds interesting, you can apply directly here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a>&nbsp;.<br /><br />Looking forward to the possibility of working together!<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;<br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 2,
              projectEventType: 'MESSAGE',
              template: {
                subject:
                  'Quick Follow-Up – Apply for a Gift from {{brand_name}}, {{creator_name}}! 🎁',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />Just wanted to follow up in case my last message slipped through. We’re still excited to offer you the chance to try our <span contenteditable="false" data-variable="true">{{product_title}}</span> by applying to our no-commitment campaign – simply as a way for us to connect and introduce our brand to you!<br /><br />There’s absolutely no obligation to post or commit further. Just apply here if you’re interested: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a>.<br /><br />Looking forward to connecting!<br /><br />Warmly,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 4,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'A Simple Start with {{brand_name}}, {{creator_name}}?',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />Reaching out one more time to invite you to apply for our campaign with <span contenteditable="false" data-variable="true">{{brand_name}}</span>. We’d love for you to experience our <span contenteditable="false" data-variable="true">{{product_title}}</span> with zero commitment – it’s just a fun, no-strings-attached opportunity to see if we’re a good fit!<br /><br />If you’d like to take part, simply apply here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a>.<br /><br />Excited about the potential of working together!<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span>&nbsp;<br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;<br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 3,
              projectEventType: 'MESSAGE',
              template: {
                subject:
                  'Last Chance to Apply for a Free Gift from {{brand_name}}  – No Pressure! 🎁',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />This is my final follow-up to invite you to apply for our campaign with <span contenteditable="false" data-variable="true">{{product_title}}</span>. There’s no obligation to post – it’s simply a way for us to connect and see if we might be a fit for future collaborations.<br /><br />If you’re interested, you can apply here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a>. And if now’s not the right time, no problem at all – just let me know, and I’ll keep you in mind for future opportunities.<br /><br />Thank you for considering this, <span contenteditable="false" data-variable="true">{{creator_name}}</span>! Hope we get to work together someday.<br /><br />Warm regards,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span>&nbsp;<br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;<br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 5,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Thank You, {{creator_name}}  – Let’s Keep in Touch!',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />Just reaching out one last time to thank you for considering our invitation. We understand that now might not be the right time, and that’s totally fine!<br /><br />If you’d like, I’d be happy to keep you in mind for future campaigns. We’d still love the chance to connect down the line and explore how we can work together.<br /><br />Thanks again, <span contenteditable="false" data-variable="true">{{creator_name}}</span>! Don’t hesitate to reach out if you’d like to stay updated on upcoming opportunities.<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span>&nbsp;<br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;<br>',
              },
            },
          },
        ],
      },
    },
  },
  {
    node: {
      name: 'Creator Ads',
      steps: {
        edges: [
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 0,
              projectEventType: 'OFFER',
              template: {
                subject: 'Paid Collaboration with {{brand_name}} – Expand Your Reach!',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />I’m <span contenteditable="false" data-variable="true">{{sender_name}}</span> from <span contenteditable="false" data-variable="true">{{brand_name}}</span>, and we’re excited to invite you to join an exclusive campaign designed to grow your audience and showcase your creativity.<br /><br />🎥 <b>What’s the Opportunity?</b><br /><br />- Create <span contenteditable="false" data-variable="true">{{deliverables}}</span>&nbsp;and provide ad permissions.<br />- Collaborate on impactful content that resonates with a wider audience.<br />- Gain visibility: <span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;will run ads using your handle, amplifying your reach and helping you connect with new followers.<br /><br />🌍 <b>Why Work with </b><span contenteditable="false" data-variable="true">{{brand_name}}</span><b>?</b><br /><br /><span contenteditable="false" data-variable="true">{{brand_summary}}</span>&nbsp;<br /><br />We’re committed to promoting <span contenteditable="false" data-variable="true">{{product_title}}</span>&nbsp;in a way that also elevates your content to a larger audience through targeted social ads.<br /><br />If this sounds like a great fit, review the creative brief and apply here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply Now</a><br />Looking forward to working together!<br /><br />Best regards,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 2,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Gain More Exposure with {{brand_name}}!',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />I wanted to follow up about the opportunity to collaborate with <span contenteditable="false" data-variable="true">{{brand_name}}</span>. This campaign is a chance for your content to reach new audiences and expand your creative influence.<br /><br /><b>Here’s Why You’ll Love It</b>:<br /><br /><ul><li><b>Deliverables</b>: Create <span contenteditable="false" data-variable="true">{{deliverables}}</span>&nbsp;and provide ad permissions.<br />Broaden Your Reach: </li><li><b>Broaden Your Reach</b>: <span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;will run ads with your handle, introducing your content to new audiences and increasing your visibility.</li></ul><br />If you’d like to explore this collaboration, take a look at the details here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a>&nbsp;<br />Let me know if you have any questions. I’d love to discuss how we can work together!<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 4,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Last Call – Boost Your Reach with {{brand_name}}! 🎯',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />Just a quick reminder – we still have a few spots left in the <span contenteditable="false" data-variable="true">{{brand_name}}</span> campaign, and we’d love to include you!<br /><br /><b>Why Join?</b><br /><br /><ul><li><b>Create</b>: Deliver <span contenteditable="false" data-variable="true">{{deliverables}}</span>&nbsp;with ad permissions.<br /></li><li><b>Increase Visibility</b>: With ads featuring your handle, your content will be seen by a broader audience, helping you grow your reach and followers.<br /></li><li><b>Collaborate</b>: Work with <span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;to bring impactful content to life.<br /></li></ul><br />This is a unique chance to elevate your profile while creating meaningful content. Don’t wait – check out the brief and secure your spot here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a><br /><br />Looking forward to the opportunity to collaborate!<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 3,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Thanks for Considering {{brand_name}}!',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />It looks like the <span contenteditable="false" data-variable="true">{{brand_name}}</span> campaign may not align with your goals right now, and that’s completely fine!<br /><br />We truly appreciate you considering this opportunity. If you’d like to stay updated about similar collaborations in the future, just let me know. We’d love to keep the door open for future campaigns that may be a better fit for your style and schedule.<br /><br />Thanks again, and I hope we get to work together soon!<br /><br />Best regards,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
        ],
      },
    },
  },
  {
    node: {
      name: 'TikTok Shop',
      steps: {
        edges: [
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 0,
              projectEventType: 'OFFER',
              template: {
                subject: 'Make Money with {{brand_name}} on TikTok Shop!',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />I’m <span contenteditable="false" data-variable="true">{{sender_name}}</span> from <span contenteditable="false" data-variable="true">{{brand_name}}</span>, <span contenteditable="false" data-variable="true">{{brand_summary}}</span>. We’re inviting TikTok creators to join our latest campaign and review <span contenteditable="false" data-variable="true">{{product_title}}</span>.<br /><br /><b>About the Campaign</b>:<br /><ul><li><b>Earn </b><span contenteditable="false" data-variable="true">{{commission_rate}}</span><b>%&nbsp;Commission</b>: You’ll earn <span contenteditable="false" data-variable="true">{{commission_rate}}</span>% on every sale, with payments tracked and paid directly through TikTok Shop.<br /></li><li><b>Transparent, Monthly Payments</b>: TikTok tracks your sales, ensuring timely and clear monthly payments.<br /></li><li><b>Performance Rewards</b>: High-performing affiliates receive increased commission rates and early access to new products.</li></ul><br />If this sounds like a fit, check out the details and apply here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a><br />Looking forward to collaborating with you!<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span>&nbsp;<br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;<br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 2,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Earn with TikTok Shop – Proven Success with {{brand_name}}!',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />I wanted to follow up on my last message. We’re excited about the possibility of you joining our campaign on TikTok Shop to promote <span contenteditable="false" data-variable="true">{{product_title}}</span>.<br /><br /><b>Here’s Why You Should Get On Board</b>:<br /><br /><ol><li><b>Earn Commission</b>: You’ll earn $<span contenteditable="false" data-variable="true">{{product_price}}</span>&nbsp;PLUS <span contenteditable="false" data-variable="true">{{commission_rate}}</span>% commission on sales through TikTok Shop. With the product valued at $<span contenteditable="false" data-variable="true">{{product_price}}</span>, selling just 10-20 units could lead to substantial earnings.<br /></li><li><b>Success Stories</b>: <u>[For example, “Creators like [Successful Creator Name] earned $X promoting our product with just one video” or “Our product is a top seller in its category on Amazon and TikTok.”]</u></li></ol><br />You’ll only need to create one TikTok video to start earning!<br /><br />If this sounds like a great opportunity, you can apply here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a><br />Looking forward to seeing you onboard!<br /><br />Best regards,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;<br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 4,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Last Chance to Join {{brand_name}}’s TikTok Shop Campaign!',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />We’re closing applications soon, and we’d love for you to join us! This is a unique opportunity to earn monthly revenue directly through TikTok Shop, with transparent tracking and consistent payouts.<br /><br /><b>What You Could Make Monthly</b>:<br /><br />With <span contenteditable="false" data-variable="true">{{commission_rate}}</span>% commission, selling just 10-20 units per month could earn you $<u>[potential_income]</u>, with no limit on earnings. TikTok Shop handles payments, so you can focus on creating without worrying about tracking commissions.<br /><br />If you’re interested, apply here before the deadline: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a><br />Excited about the possibilities!<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 3,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Last Chance, {{creator_name}}  – Let’s Build Something Big on TikTok!',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />We’re wrapping up our creator selection, and we’d love to see you in this campaign. Our goal is to build long-term partnerships with creators who succeed in TikTok Shop.<br /><br /><b>Why This Opportunity Is Perfect for You</b>:<br /><br /><ul><li><b>Earn on Every Sale</b>: You’ll earn <span contenteditable="false" data-variable="true">{{commission_rate}}</span>% commission via TikTok Shop with monthly payments.<br /></li><li><b>Exclusive Rewards for Top Performers</b>: Successful creators receive increased commission rates and early access to new products.<br /></li><li><b>Long-Term Collaboration</b>: If the campaign goes well, we’d love to discuss ongoing opportunities for growth together.<br /></li></ul><br />If you’re ready to explore this opportunity, apply here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a><br />Thank you for considering, <span contenteditable="false" data-variable="true">{{creator_name}}</span>! Hope we can work together soon.<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
        ],
      },
    },
  },
  {
    node: {
      name: 'Influencer Posts',
      steps: {
        edges: [
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 0,
              projectEventType: 'OFFER',
              template: {
                subject: '{{brand_name}} x {{creator_handle}}: Let’s Collab',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />This is <span contenteditable="false" data-variable="true">{{sender_name}}</span> reaching out from <span contenteditable="false" data-variable="true">{{brand_name}}</span>. I came across your profile, and your content really stood out! We believe you’d be a fantastic fit for <span contenteditable="false" data-variable="true">{{brand_name}}</span>\'s upcoming campaign.<br /><br /><b>Campaign Details</b>:<br /><br /><ul><li><b>Product</b>: <span contenteditable="false" data-variable="true">{{product_description}}</span>&nbsp;</li><li><b>Deliverables</b>: <span contenteditable="false" data-variable="true">{{deliverables}}</span>&nbsp;</li><li><b>Payout</b>: $<span contenteditable="false" data-variable="true">{{price_cap}}</span> + Product<br /></li><li><b>Launch Date</b>: [<u>date]</u></li></ul><br />We’re aiming to showcase how <span contenteditable="false" data-variable="true">{{brand_name}}</span> is <u>[describe solution offered or value prop]</u> through high-quality, engaging content. We’re specifically choosing creators like you who resonate with our mission.<br /><br />If you\'re interested, please check out more details and apply here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply Now</a>&nbsp;<br /><br />Looking forward to seeing what we can create together!<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 4,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Reminder: Collaboration Opportunity with {{brand_name}}',
                htmlBody:
                  'Hey <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />I hope you’re having a great day! I wanted to follow up on my previous message about our collaboration opportunity with <span contenteditable="false" data-variable="true">{{brand_name}}</span>. We’re excited about the campaign and would love to have you on board.<br /><br /><b>Quick Recap of the Campaign</b>:<br /><br /><ul><li><b>Product</b>: <span contenteditable="false" data-variable="true">{{product_description}}</span>&nbsp;</li><li><b>Deliverables</b>: <span contenteditable="false" data-variable="true">{{deliverables}}</span>&nbsp;</li><li><b>Total Payout</b>: $<span contenteditable="false" data-variable="true">{{price_cap}}</span> + Product<br /></li></ul><br />We have limited spots available, so if you’re interested, I’d be happy to provide further details and the next steps. And if it’s not a fit right now, no worries – we’ll keep you in mind for future opportunities.<br /><br />Let me know what you think!<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span>&nbsp;<br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;<br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 4,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Last Chance to Join Our Paid Campaign with {{brand_name}}! 🎯',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />Just a quick reminder – we’d love for you to be part of our campaign with <span contenteditable="false" data-variable="true">{{brand_name}}</span>, but we’re down to only 5 slots left!<br /><br />This is a fantastic opportunity to collaborate with us, and we’re only choosing a select few creators. If you’re interested, just hit reply, and I’ll send over the next steps.<br /><br />If this campaign isn’t the right fit or timing, that’s okay too – we’ll keep you posted on upcoming opportunities.<br /><br />Looking forward to hearing from you!<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 4,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Last Call, {{creator_name}} – Let’s Collaborate with {{brand_name}}!',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />This is my final check-in to see if you’re interested in collaborating with <span contenteditable="false" data-variable="true">{{brand_name}}</span> on this campaign. We’re excited to work with creators who can represent our brand authentically and deliver engaging content.<br /><br />We’re also looking to build longer-term relationships with our top-performing creators. If this campaign goes well, we’d love to explore further collaborations together.<br /><br />If you’re interested, reply here or check out the full brief to apply: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a><br />Thanks again for considering, <span contenteditable="false" data-variable="true">{{creator_name}}</span>! I hope we get the chance to work together.<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span><br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span><br>',
              },
            },
          },
        ],
      },
    },
  },
  {
    node: {
      name: 'Affiliates',
      steps: {
        edges: [
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 0,
              projectEventType: 'OFFER',
              template: {
                subject: 'Sponsorship Opportunity with {{brand_name}}',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />I’m <span contenteditable="false" data-variable="true">{{sender_name}}</span> from <span contenteditable="false" data-variable="true">{{brand_name}}</span>, and we love your content on <span contenteditable="false" data-variable="true">{{platform}}</span>. We believe you’re a star in the eyes of your followers, and we’d love for you to introduce them to our <span contenteditable="false" data-variable="true">{{product_title}}</span>.<br /><br /><b>What’s in it for You:</b><br /><br /><ul><li><b>Product</b>: You’ll receive <span contenteditable="false" data-variable="true">{{product_title}}</span> to feature in your content.<br /></li><li><b>Earnings</b>: Earn $<span contenteditable="false" data-variable="true">{{product_price}}</span> upfront + <span contenteditable="false" data-variable="true">{{commission_rate}}</span>% commission on each sale. Just 10-20 units could lead to meaningful revenue for you!</li></ul><br />If this sounds like a fit, you can view more details and apply here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a>&nbsp;<br /><br />Please feel free to reach out with any questions. Looking forward to the opportunity to collaborate!<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span>&nbsp;<br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;<br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 2,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Why This Opportunity Can Earn You Real Income',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />I wanted to follow up on my previous message about our campaign with <span contenteditable="false" data-variable="true">{{brand_name}}</span>! We’re confident in <span contenteditable="false" data-variable="true">{{product_title}}</span> and its potential to help you earn – it’s been a proven success in our category, and we’d love for you to benefit from this.<br /><br /><b>Why You Can Make Money with This Product</b>:<br /><br /><u>[Insert social proof here, like: "Our product is the #1 bestseller in its category on Amazon with thousands of positive reviews," or "Creators like [Successful Creator Name] have earned $X promoting this product to their audience in just a few posts."]</u><br /><br />With your reach and this product’s popularity, this campaign offers a real opportunity for revenue.<br /><br />If this sounds like a good fit, you can apply here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a>&nbsp;<br /><br />Best regards,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span>&nbsp;<br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;<br />',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 4,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Potential Monthly Earnings with {{brand_name}}’s Affiliate Program',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />We’re closing applications soon, and we’d love for you to join us! This is a chance to see meaningful returns by featuring <span contenteditable="false" data-variable="true">{{product_title}}</span> in your content, with a clear path to growing monthly earnings.<br /><br /><b>Here’s What You Could Make Monthly</b>:<br /><br />With <span contenteditable="false" data-variable="true">{{commission_rate}}</span>% commission on each sale, your monthly income could add up quickly. For example:<br /><br />- Selling just 10-20 units a month could bring in $<u>[potential_income]</u> monthly, with no cap on earnings.<br /><br />Your engaged audience could help you grow this revenue steadily over time.<br /><br />If you’re interested, apply here before the deadline: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a><br />Looking forward to collaborating with you!<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span>&nbsp;<br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;<br>',
              },
            },
          },
          {
            node: {
              startsAtHour: '2025-02-04T05:00:00+00:00',
              delayInDays: 3,
              projectEventType: 'MESSAGE',
              template: {
                subject: 'Last Chance, {{creator_name}} – Let’s Build a Long-Term Partnership!',
                htmlBody:
                  'Hi <span contenteditable="false" data-variable="true">{{creator_name}}</span>,<br /><br />As we wrap up our creator selection process, we’re excited to work with creators who connect deeply with their audience. If this campaign performs well for you, we’d love to explore a long-term partnership to keep the momentum going.<br /><br /><b>Here’s How We Support Long-Term Success</b>:<br /><br />When creators succeed with our affiliate program, we work to make them an ongoing part of our brand story. If you’re seeing positive results, we’re here to help you grow that revenue month by month, with dedicated support and resources.<br /><br />Ready to explore a lasting partnership? Apply here: <a contenteditable="false" data-variable="true" href="{{action_url}}" target="_blank">Apply</a><br />Thank you for considering this opportunity, <span contenteditable="false" data-variable="true">{{creator_name}}</span>! Hope we can work together soon.<br /><br />Best,<br /><br /><span contenteditable="false" data-variable="true">{{sender_name}}</span>&nbsp;<br /><br /><span contenteditable="false" data-variable="true">{{brand_name}}</span>&nbsp;<br>',
              },
            },
          },
        ],
      },
    },
  },
];
