import React, { useContext, useState } from 'react';

import styles from './BulkMessage.pcss';

import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import Textarea from 'Components/ui/Textarea/Textarea';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { useBulkCreateMessagesMutation } from 'Mutations/BulkCreateMessages.Mutation';
import Icon from 'Components/ui/Icon/Icon';
import { BulkCreateMessagesMutation$data } from 'GraphTypes/BulkCreateMessagesMutation.graphql';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  idsForBulkAction: string[];
  onSuccess?: () => void;
  campaignId?: string;
}

type sendingMessageStateType = 'creating' | 'success' | 'failed';

const BulkMessage: React.FC<Props> = (props) => {
  const { idsForBulkAction, onSuccess, campaignId } = props;

  const { closeDrawer } = useContext(DrawerContext);
  const [message, setMessage] = useState<string>('');
  const [sendingMessageState, setSendingMessageState] =
    useState<sendingMessageStateType>('creating');
  const [createMessages, loading] = useBulkCreateMessagesMutation();

  const handleClose = () => {
    if (sendingMessageState === 'creating') {
      amplitude.sendEvent<494>({
        id: '494',
        name: 'bulk_messages_modal_close',
        category: 'campaign',
        param: {
          campaign_id: campaignId,
        },
      });
    }
    setSendingMessageState('creating');
    setMessage('');
    if (sendingMessageState === 'success') {
      onSuccess?.();
    }
    closeDrawer('bulk-message');
  };

  const handleSuccess: (response: BulkCreateMessagesMutation$data) => void = (response) => {
    if (response.bulkCreateMessages?.__typename !== 'EmptyPayload') {
      setSendingMessageState('failed');
      amplitude.sendEvent<507>({
        id: '507',
        name: 'bulk_messages_modal_failure_show',
        category: 'campaign',
        param: {
          campaign_id: campaignId,
        },
      });
      return;
    }
    amplitude.sendEvent<506>({
      id: '506',
      name: 'bulk_messages_modal_success_show',
      category: 'campaign',
      param: {
        campaign_id: campaignId,
      },
    });
    setSendingMessageState('success');
  };
  const handleFailed = () => setSendingMessageState('failed');

  const handleSendMessagesClick = () => {
    amplitude.sendEvent<493>({
      id: '493',
      name: 'bulk_messages_modal_send_clicked',
      category: 'campaign',
      param: {
        project_ids: idsForBulkAction,
        selections_size: idsForBulkAction.length,
        message_length: message.length,
      },
    });
    createMessages({
      variables: { input: { projectIds: idsForBulkAction, text: message } },
      onCompleted: handleSuccess,
      onError: handleFailed,
    });
  };

  return (
    <Drawer rootKey="bulk-message" className={styles.drawer} onClose={handleClose}>
      {sendingMessageState === 'creating' && (
        <div className={styles.drawerContainer}>
          <div className={styles.drawerContent}>
            <div>
              <Text
                type="d2"
                msg="creators.bulk_message.title"
                formatValues={{ count: idsForBulkAction.length }}
              />
              <Textarea
                value={message}
                handleChange={setMessage}
                className={styles.text}
                bordered
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button fluid msg="general.cancel" color="white" onClick={handleClose} />
            <Button
              fluid
              msg="creators.bulk_message.send"
              color="black"
              disabled={loading || !message}
              onClick={handleSendMessagesClick}
            />
          </div>
        </div>
      )}
      {sendingMessageState === 'success' && (
        <div className={styles.drawerContainer}>
          <div className={styles.drawerContent}>
            <div>
              <Text
                type="d2"
                msg="creators.bulk_message.success.title"
                formatValues={{ count: idsForBulkAction.length }}
              />
              <Text
                type="md"
                msg="creators.bulk_message.success.message"
                formatValues={{ count: idsForBulkAction.length }}
              />
            </div>
            <div className={styles.checkContainer}>
              <Icon name={'Check'} className={styles.check} size={100} />
            </div>
            <div className={styles.buttons}>
              <Button
                fluid
                msg="creators.bulk_message.success.button"
                color="white"
                onClick={handleClose}
              />
            </div>
          </div>
        </div>
      )}
      {sendingMessageState === 'failed' && (
        <div className={styles.drawerContainer}>
          <div className={styles.drawerContent}>
            <div>
              <Text
                type="d2"
                msg="creators.bulk_message.failure.title"
                formatValues={{ count: idsForBulkAction.length }}
              />
              <Text type="md" msg="creators.bulk_message.failure.message" />
            </div>
            <div className={styles.closeContainer}>
              <Icon name={'Close-small'} className={styles.close} size={100} />
            </div>
            <div className={styles.buttons}>
              <Button
                fluid
                msg="creators.bulk_message.failure.button.close"
                color="white"
                onClick={handleClose}
              />
              <Button
                fluid
                msg="creators.bulk_message.failure.button.retry"
                color="black"
                onClick={handleSendMessagesClick}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default BulkMessage;
