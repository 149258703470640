import React, { useMemo, useState, useCallback, useContext } from 'react';
import { fetchQuery, useFragment, graphql } from 'react-relay';
import classNames from 'classnames';

import BrandCampaignsModal from '../../BrandCampaignsModal/BrandCampaignsModal';
import { BrandData } from '../../util';

import styles from './Brand.pcss';

import BrandModal from 'Modal/advertiser/BrandModal/BrandModal';
import updateBrand from 'Mutations/UpdateBrand.Mutation';
import IconNew from 'Components/ui/Icon/Icon';
import Image from 'Components/ui/Image/Image';
import Text from 'Components/ui/Text/Text';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import Toggle from 'Components/ui/Toggle/Toggle';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Drawer from 'Components/ui/Drawer/Drawer';
import environment from 'Api/Environment';
import ls from 'Util/localStorage';
import { amplitude } from 'Helpers/amplitude';
import { BrandsQuery$data } from 'GraphTypes/BrandsQuery.graphql';
import { BrandQuery$data, BrandQuery as QueryType } from 'GraphTypes/BrandQuery.graphql';
import { Brand_brand$key, Brand_brand$data } from 'GraphTypes/Brand_brand.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

type Props = {
  brand: Brand_brand$key;
  isAdmin: boolean;
  openActiveCampaignModal: () => void;
  onBuyAddonToActivate: (brandId: string) => void;
  canAddNewBrand: boolean;
  disabled: boolean;
  brandCategories: BrandsQuery$data['brandCategories'];
};

const BrandQuery = graphql`
  query BrandQuery($brandIds: [ID!]) {
    campaigns(brandIds: $brandIds, stages: [ACTIVE]) {
      totalCount
    }
  }
`;

const Brand: React.FC<Props> = (props) => {
  const {
    brand,
    openActiveCampaignModal,
    canAddNewBrand,
    disabled,
    brandCategories,
    onBuyAddonToActivate,
  } = props;

  const data = useFragment(
    graphql`
      fragment Brand_brand on Brand {
        id
        active
        logo {
          id
          thumbnailUrl
          ... on Image {
            transformations {
              brandLogoUrl
            }
          }
        }
        name
        summary
        websiteUrl
        category {
          id
          name
        }
        campaigns(stages: [ACTIVE, COMPLETED]) {
          totalCount
        }
        activeCampaigns: campaigns(stages: [ACTIVE]) {
          edges {
            node {
              id
              name
              platform
              brand {
                logo {
                  id
                  thumbnailUrl
                  ... on Image {
                    transformations {
                      brandLogoUrl
                    }
                  }
                }
              }
              creator {
                id
              }
            }
          }
        }
        completedCampaigns: campaigns(stages: [COMPLETED]) {
          edges {
            node {
              id
              name
              platform
              brand {
                logo {
                  thumbnailUrl
                  ... on Image {
                    transformations {
                      brandLogoUrl
                    }
                  }
                }
              }
              creator {
                id
              }
            }
          }
        }
      }
    `,
    brand as any
  ) as Brand_brand$data;

  const brandId = data.id;
  const brandName = data.name;
  const brandActive = data.active;
  const brandCategory = data.category;
  const brandWebsiteUrl = data.websiteUrl;
  const brandSummary = data.summary;
  const brandLogo = data.logo?.transformations?.brandLogoUrl;
  const logoId = data.logo?.id;
  const campaignCount = data.campaigns?.totalCount;
  const activeCampaigns = data.activeCampaigns;
  const completedCampaigns = data.completedCampaigns;

  const [campaignsModal, setCampaignsModalStatus] = useState(false);
  const [requiredCategory, setRequiredCategory] = useState(false);

  const { openDrawer, closeDrawer } = useContext(DrawerContext);

  const editBrand = () => {
    openDrawer(`edit-brand-modal-${brandId}`);
    amplitude.sendEvent({
      id: '137',
      category: 'brand_management_dashboard',
      name: 'edit_brand',
    });
  };

  const campaignsBrand = () => {
    setCampaignsModalStatus(true);
  };

  const closeCampaignsModal = useCallback(() => {
    setCampaignsModalStatus(false);
  }, []);

  const handleActivateBrand = async () => {
    if (!brandActive && !canAddNewBrand) {
      onBuyAddonToActivate(brandId);
      return;
    }
    if (brandActive) {
      const data = await new Promise<BrandQuery$data>((resolve, reject) => {
        fetchQuery<QueryType>(environment, BrandQuery, {
          brandIds: brandId ? [brandId] : undefined,
        }).subscribe({
          next: (result) => {
            resolve(result);
          },
          error: () => {
            reject();
          },
        });
      });
      if (Number(data?.campaigns?.totalCount) > 0) {
        ls.set('filtered_brands', JSON.stringify([{ [brandId]: brandName }]));
        openActiveCampaignModal();
        return;
      }
    }
    if (!brandActive && !brandCategory) {
      setRequiredCategory(true);
      openDrawer(`edit-brand-modal-${brandId}`);
      return;
    }

    updateBrand({
      id: brandId,
      active: !brandActive,
      name: brandName,
    });
    amplitude.sendEvent({
      id: '136',
      category: 'brand_management_dashboard',
      name: 'active_toggler',
      param: {
        status: !brandActive ? 'deactivate_brand' : 'activate_brand',
        brandId: brandId,
      },
    });
  };

  const cleanUrl = useMemo(() => {
    const link = brandWebsiteUrl?.trim() || '-';
    try {
      const url = new URL(link);

      return url.host + url.pathname;
    } catch {
      if (!link.includes('://')) {
        try {
          const url = new URL(`https://${link}`);
          const path = url.pathname === '/' ? '' : url.pathname;

          return url.host + path;
        } catch {
          throw Error;
        }
      }
    }

    return `${link.slice(0, 40)}...`;
  }, [brandWebsiteUrl]);

  const classList = classNames(styles.root, {
    [styles.disabled]: disabled,
  });

  const activePrefix = brandActive ? 'deactive' : 'active';

  return (
    <div className={classList}>
      <div className={styles.brandInfo}>
        <Image size={48} type="round" src={brandLogo} />
        <div className={styles.names}>
          <Text type="h2" text={brandName || ''} className={styles.brandName} />
          <Text type="md" className={styles.brandSite} text={cleanUrl} />
        </div>
      </div>
      {!!campaignCount && (
        <Text
          type="md"
          msg="brands.dashboard.campaigns.count"
          formatValues={{ count: String(campaignCount) }}
          className={styles.campaignsCount}
        />
      )}
      <div className={styles.stub} />
      <div className={styles.controls}>
        <IconNew
          onClick={disabled ? undefined : campaignsBrand}
          name="Info"
          className={styles.info}
        />
        <IconNew
          onClick={disabled ? undefined : editBrand}
          name="Edit"
          className={styles.editBtn}
        />
        <Tooltip
          id={`activate_${brandId}`}
          tooltipMsg={!disabled ? `brands.dashboard.activate.tooltip.${activePrefix}` : undefined}
          place="top"
        >
          <Toggle
            onChange={handleActivateBrand}
            on={brandActive}
            disabled={disabled}
            bgColor="sand"
          />
        </Tooltip>
      </div>
      <Drawer
        opened={campaignsModal}
        onClose={closeCampaignsModal}
        rootKey={`brand-campaigns-${brandId}`}
        className={styles.drawer}
      >
        <BrandCampaignsModal
          brandName={brandName || ''}
          activeCampaigns={activeCampaigns}
          completedCampaigns={completedCampaigns}
        />
      </Drawer>
      <BrandModal
        id={brandId}
        brandId={brandId}
        logo={brandLogo}
        logoId={logoId}
        name={brandName}
        websiteUrl={brandWebsiteUrl}
        categoryId={brandCategory?.id}
        categoryName={brandCategory?.name}
        summary={brandSummary}
        requiredCategory={requiredCategory}
        brandCategories={brandCategories}
      />
    </div>
  );
};

export default Brand;
