import React, { FormEvent, useContext, useEffect } from 'react';

import styles from './FilterInput.pcss';

import Input from 'Components/ui/Input/Input';
import { FilterFormItemInput } from 'Components/FiltersForm/FilterForm';
import {
  FiltersFormContext,
  FiltersFormContextType,
} from 'Components/FiltersForm/FiltersFormContext';

const FilterInput: React.FC<FilterFormItemInput> = (props) => {
  const { fieldProps, fieldKey } = props;
  const { filters, setFilterValueByKey } = useContext<FiltersFormContextType>(FiltersFormContext);

  const [value, changeValue] = React.useState<string | string[]>(filters[fieldKey] || '');

  const handleChangeValue = (e: FormEvent<HTMLInputElement>) => {
    const newValue = (e.target as HTMLInputElement).value;

    changeValue(newValue);
    setFilterValueByKey(fieldKey, newValue, newValue);
  };

  useEffect(() => {
    if (!filters[fieldKey] && value) {
      changeValue('');
    }
  }, [filters[fieldKey]]);

  const handleResetValue = () => {
    changeValue('');
    setFilterValueByKey(fieldKey, '', '');
  };

  return (
    <Input
      type="text"
      className={styles.root}
      value={value}
      active={!!value}
      onInput={handleChangeValue}
      onResetValue={handleResetValue}
      {...fieldProps}
    />
  );
};

export default FilterInput;
