import React from 'react';
import classNames from 'classnames';

import styles from './NewRegistrationMark.pcss';

import Tooltip from 'Atoms/Tooltip/Tooltip';
import stubImg from 'Images/icons/vetted.png';

interface Props {
  size?: number;
  tooltipKey?: string;
  className?: string;
}

const NewRegistrationMark: React.FC<Props> = (props) => {
  const { size = 24, tooltipKey, className } = props;

  return (
    <Tooltip
      id={`registartionMark_${tooltipKey || ''}`}
      tooltipMsg="general.registration_mark"
      place="top"
    >
      <img
        src={stubImg}
        style={size ? { height: size, width: size } : {}}
        className={classNames(styles.icon, className)}
      />
    </Tooltip>
  );
};

export default NewRegistrationMark;
