import React, { useContext } from 'react';
import ls from 'Util/localStorage';

import styles from './UpdateProductImage.pcss';

import TextButton from 'Components/ui/TextButton/TextButton';
import Drawer from 'Components/ui/Drawer/Drawer';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Text from 'Components/ui/Text/Text';
import FileUploader from 'Atoms/FileUploader/FileUploader';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import updateProductImg from 'Images/modals/updateProductImage.png';
import updateProduct2xImg from 'Images/modals/updateProductImage_2x.png';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  place: 'brief' | 'campaign';
  campaignId: string;
  onImageUploaded: (src: string) => void;
  onClose?: () => void;
}

const UpdateProductImage: React.FC<Props> = (props) => {
  const { campaignId, place, onImageUploaded, onClose } = props;
  const { openDrawer, closeDrawer } = useContext(DrawerContext);

  const handleClose = () => {
    ls.set(`product_image_update_warning_${place}`, true);
    closeDrawer('update-product-image');
  };

  const handleCoverChoose = (files: FileList | null) => {
    if (!files || files.length === 0) return;
    ls.set(`product_image_update_warning_${place}`, true);
    amplitude.sendEvent<482>({
      id: '482',
      category: 'product_image_update_modal',
      name: 'upload_click',
      param: {
        campaign_id: campaignId,
        place,
      },
    });
    const fileSrc = URL.createObjectURL(files[0]);
    onImageUploaded(fileSrc);
    openDrawer('image-cropper');
  };

  const handleOpen = () => {
    amplitude.sendEvent<480>({
      id: '480',
      category: 'product_image_update_modal',
      name: 'show',
      param: {
        campaign_id: campaignId,
        place,
      },
    });
  };

  const handleCloseClick = () => {
    amplitude.sendEvent<483>({
      id: '483',
      category: 'product_image_update_modal',
      name: 'close_click',
      param: {
        campaign_id: campaignId,
        place,
      },
    });
    handleClose();
    onClose?.();
  };

  const handleDiscardClick = () => {
    amplitude.sendEvent<481>({
      id: '481',
      category: 'product_image_update_modal',
      name: 'discard_click',
      param: {
        campaign_id: campaignId,
        place,
      },
    });
    handleClose();
    onClose?.();
  };

  return (
    <Drawer
      fullView
      position="center"
      rootKey="update-product-image"
      className={styles.drawer}
      needCloseButton={false}
      onOpen={handleOpen}
    >
      <div className={styles.content}>
        <div className={styles.closeWrap}>
          <TextButton icon="Close-small" onClick={handleCloseClick} />
        </div>
        <div className={styles.imageWrap}>
          <img srcSet={`${updateProductImg} 1x, ${updateProduct2xImg} 2x`} />
        </div>
        <Text type="d2" weight="bold" msg="campaign_product_image_update.drawer.title" />
        <Text msg="campaign_product_image_update.drawer.descr" />
        <div className={styles.controls}>
          <AlterButton
            type="grey"
            msg="campaign_product_image_update.drawer.discard"
            onClick={handleDiscardClick}
          />
          <div className={styles.upload}>
            <AlterButton
              type="pink"
              icon="Arrow-big-up"
              msg="campaign_product_image_update.drawer.upload"
            />
            <FileUploader className={styles.uploader} onChange={handleCoverChoose} />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default UpdateProductImage;
