import React, { ComponentType, useContext } from 'react';

import { FilterFormItemWithDropdown } from '../FilterForm';
import { FiltersFormContext, FiltersFormContextType } from '../FiltersFormContext';

import styles from './Filter.pcss';

import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import Text from 'Components/ui/Text/Text';

const withDropdown = <P extends object>(WrappedComponent: ComponentType<P>): React.FC<P> => {
  return (props: P & FilterFormItemWithDropdown) => {
    const { dropdownProps, fieldKey } = props;
    const { filters, visibleValues } = useContext<FiltersFormContextType>(FiltersFormContext);
    const value = filters[fieldKey] || null;
    const visibleValue = visibleValues[fieldKey] || null;
    const isFieldHaveMoreThanOneVisibleValue =
      Array.isArray(visibleValue) && visibleValue.length > 1;
    if (!dropdownProps) {
      return <WrappedComponent {...(props as P)} />;
    }
    return (
      <Dropdown
        showArrow
        closeBySelect={false}
        value={
          <div className={styles.valueText}>
            <Text
              type="md"
              msg={dropdownProps.placeholderMsg}
              textPriority={Boolean(value && value.length > 0)}
              text={isFieldHaveMoreThanOneVisibleValue ? visibleValue[0] : visibleValue}
              className={styles.selectorLabel}
            />
            <Text
              type="md"
              text={isFieldHaveMoreThanOneVisibleValue ? value.length : ''}
              className={styles.selectorCounter}
            />
          </div>
        }
        className={styles.dropdown}
      >
        <DropdownGroup>
          <div className={styles.dropdownList}>
            <WrappedComponent {...(props as P)} />
          </div>
        </DropdownGroup>
      </Dropdown>
    );
  };
};

export default withDropdown;
