import React, { useState, useCallback, useContext, ChangeEvent } from 'react';
import classnames from 'classnames';

import styles from './CustomListCreateDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import InputPreset from 'Components/ui/InputPreset/InputPreset';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import { useCreateCustomListMutation } from 'Mutations/CreateCustomList.Mutation';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { CreateCustomListMutation$data } from 'GraphTypes/CreateCustomListMutation.graphql';
import { amplitude } from 'Helpers/amplitude';
import Textarea from 'Components/ui/Textarea/Textarea';

interface Props {
  drawerProps?: Partial<Omit<DrawerProps, 'children'>>;
  listId?: string;
  organizationId: string;
  onCustomListSuccessfulCreated?: (response: CreateCustomListMutation$data) => void;
}

const CustomListCreateDrawer: React.FC<Props> = (props) => {
  const { drawerProps, organizationId, onCustomListSuccessfulCreated, listId } = props;
  const { closeDrawer } = useContext(DrawerContext);
  const [inputValue, setInputValue] = useState<string>('');
  const [descriptionValue, setDescriptionValue] = useState<string>('');
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [createCustomList] = useCreateCustomListMutation();

  const isFormValid = Boolean(inputValue);

  const handleInputValueChange = useCallback<HandleInputValueChange>((e) => {
    const value = e.target.value;
    setInputValue(value);
    setHasError(false);
  }, []);

  const handleInputValueReset = useCallback<HandleInputValueReset>(() => {
    setInputValue('');
    setHasError(false);
  }, []);

  const handleDescriptionValueChange = useCallback((text: string) => {
    setDescriptionValue(text);
    setHasError(false);
  }, []);

  const handleButtonClick = useCallback(() => {
    if (isSubmitLoading) return;
    const customListName = inputValue.trim();
    const customListDescription = descriptionValue.trim();
    if (!customListName) {
      setHasError(true);
      setInputValue(customListName);
      return;
    }
    setIsSubmitLoading(true);
    createCustomList({
      variables: {
        input: {
          id: listId,
          organizationId,
          name: customListName,
          description: customListDescription,
        },
      },
      onCompleted: (response) => {
        const typename = response.createCustomList?.__typename;
        switch (typename) {
          case 'CreateCustomListPayload': {
            const listName = response.createCustomList?.customList?.name || '';
            amplitude.sendEvent<283>({
              id: '283',
              category: 'creator_lists',
              name: 'list_created',
              param: {
                list_name: listName,
              },
            });
            onCustomListSuccessfulCreated?.(response);
            closeDrawer('custom-list-create');
            break;
          }
          default: {
            setHasError(true);
            break;
          }
        }
        setIsSubmitLoading(false);
      },
      onError: () => {
        setIsSubmitLoading(false);
      },
    });
  }, [inputValue, descriptionValue, organizationId, isSubmitLoading]);

  const handleOpenDrawer = useCallback<NonNullable<DrawerProps['onOpen']>>(() => {
    setInputValue('');
    setDescriptionValue('');
    drawerProps?.onOpen?.();
  }, [drawerProps]);

  return (
    <Drawer
      rootKey="custom-list-create"
      {...drawerProps}
      removeWhenClosed
      className={classnames(styles.drawer, drawerProps?.className)}
      onOpen={handleOpenDrawer}
    >
      <div className={styles.contentRoot}>
        <div>
          <TextPreset
            className={styles.titleTextPreset}
            header={<Text type="d2" msg="custom_list_create_modal.title" />}
          />
          <InputPreset>
            <Input
              bordered
              forceLabelShow
              name="customListName"
              dataTest="input:customListName"
              error={hasError}
              value={inputValue}
              className={styles.input}
              labelMsg="custom_list_create_modal.input.label"
              placeholderMsg="custom_list_create_modal.input.placeholder"
              onChange={handleInputValueChange}
              onResetValue={handleInputValueReset}
            />
          </InputPreset>
          <InputPreset>
            <Textarea
              bordered
              forceLabelShow
              dataTest="input:customListDescription"
              value={descriptionValue}
              className={styles.textarea}
              labelMsg="custom_list_create_modal.description.label"
              handleChange={handleDescriptionValueChange}
            />
          </InputPreset>
        </div>
        <ButtonPreset className={styles.submitButtonWrapper}>
          <div className={styles.clicker} onClick={handleButtonClick} />
          <Button
            fluid
            color="black"
            dataTest="button:createCustomList"
            className={styles.submitButton}
            disabled={!isFormValid}
            loading={isSubmitLoading}
            msg="custom_list_create_modal.submit_button"
          />
        </ButtonPreset>
      </div>
    </Drawer>
  );
};

export default CustomListCreateDrawer;
export { CustomListCreateDrawer };

// types

export type { Props as CustomListCreateDrawerProps };

type DrawerProps = React.ComponentProps<typeof Drawer>;
type InputProps = React.ComponentProps<typeof Input>;
type HandleInputValueChange = NonNullable<InputProps['onChange']>;
type HandleInputValueReset = NonNullable<InputProps['onResetValue']>;
