import { graphql, useMutation } from 'react-relay';

import { UpdateOutreachSequenceBlueprintMutation } from 'GraphTypes/UpdateOutreachSequenceBlueprintMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const UpdateOutreachSequenceBlueprintMutation = graphql`
  mutation UpdateOutreachSequenceBlueprintMutation($input: UpdateOutreachSequenceBlueprintInput!) {
    updateOutreachSequenceBlueprint(input: $input) {
      __typename
      ... on UpdateOutreachSequenceBlueprintPayload {
        outreachSequenceBlueprint {
          id
        }
      }
    }
  }
`;

export const useUpdateOutreachSequenceBlueprintMutation: BaseMutation<
  UpdateOutreachSequenceBlueprintMutation
> = (config) => {
  const [updateOutreachSequenceBlueprint, loading] =
    useMutation<UpdateOutreachSequenceBlueprintMutation>(UpdateOutreachSequenceBlueprintMutation);

  const updateOutreachSequenceBlueprintMutationCaller: BaseMutationExecFn<
    UpdateOutreachSequenceBlueprintMutation
  > = (execConfig) => {
    updateOutreachSequenceBlueprint({
      ...config,
      ...execConfig,
    });
  };
  return [updateOutreachSequenceBlueprintMutationCaller, loading];
};
