import React, { useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { graphql, useLazyLoadQuery } from 'react-relay';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from '../InviteOutreachCreators.pcss';
import {
  InviteOutreachCreatorsContext,
  InviteOutreachCreatorsSelectedLists,
} from '../InviteOutreachCreatorsContext';

import Text from 'Components/ui/Text/Text';
import { CreatorsListSelectorQuery as QueryType } from 'GraphTypes/CreatorsListSelectorQuery.graphql';
import Spinner from 'Atoms/Spinner/Spinner';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { amplitude } from 'Helpers/amplitude';
import { CREATORS_LIST_ROUTE, DISCOVERY_ROUTE } from 'Constants/general';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import Icon from 'Components/ui/Icon/Icon';

interface Props {
  isOpened?: boolean;
}

const CreatorsListSelectorQuery = graphql`
  query CreatorsListSelectorQuery {
    currentUser {
      organization {
        id
        favoriteCreators {
          totalCount
        }
        customLists {
          edges {
            node {
              id
              name
              creators {
                totalCount
              }
            }
          }
        }
      }
    }
  }
`;

const CreatorsListSelector: React.FC<Props> = (props) => {
  const { isOpened } = props;
  const { selectedLists, setSelectedLists } = useContext(InviteOutreachCreatorsContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const canUseOutreachTool = hasFeatureFlag('outreach_tool');
  const [dropdownOpenKey, setDropdownOpenKey] = useState<number | undefined>(undefined);

  const createdListId = location.state?.listId || null;

  const data = useLazyLoadQuery<QueryType>(
    CreatorsListSelectorQuery,
    {},
    {
      fetchKey: createdListId,
      fetchPolicy: 'store-and-network',
    }
  );

  useEffect(() => {
    if (isOpened) {
      setDropdownOpenKey(Date.now());
    }
  }, [isOpened]);

  const handleCreateListOpen = () => {
    setDropdownOpenKey(undefined);
  };

  const listOfList = useMemo(() => {
    const notEmptyLists = (data.currentUser?.organization?.customLists?.edges || []).filter(
      (item) => item?.node?.creators?.totalCount && item?.node?.creators?.totalCount > 0
    );
    return [...notEmptyLists];
  }, [data.currentUser?.organization?.customLists?.edges]);

  const selectedListsNames = useMemo(() => {
    return listOfList
      .map((list) => {
        if (
          typeof list?.node?.id === 'string' &&
          selectedLists.some((selectedList) => selectedList.id === list?.node?.id)
        ) {
          return list?.node?.name;
        }
        return null;
      })
      .filter((list) => list)
      .join(', ');
  }, [selectedLists, listOfList]);

  const handleCreateList = () => {
    amplitude.sendEvent<440>({
      id: '440',
      category: 'campaign',
      name: 'outreach_create_list_click',
    });
    if (canUseOutreachTool) {
      const newListId = uuid();
      const discoveryUrl = generatePath(DISCOVERY_ROUTE, { listId: newListId });
      navigate(discoveryUrl, {
        state: {
          backUrl: location.pathname,
        },
      });
    } else {
      navigate(CREATORS_LIST_ROUTE, {
        state: {
          createNew: true,
        },
      });
    }
  };

  return (
    <>
      <div className={styles.subtitle}>
        <Text type="md" msg="invite_outreach_creators_modal.step_1" />
      </div>
      {listOfList ? (
        <Dropdown
          openKey={dropdownOpenKey}
          onOpen={handleCreateListOpen}
          value={
            <Text
              type="md"
              className={classNames(styles.dropdownValue, {
                [styles.placeholder]: !selectedListsNames,
              })}
              textPriority
              text={selectedListsNames}
              msg={
                listOfList.length === 0
                  ? 'invite_outreach_creators_modal.empty_lists'
                  : 'invite_outreach_creators_modal.lists.placeholder'
              }
            />
          }
          className={styles.dropdownSettings}
        >
          <DropdownGroup className={styles.dropdownMenu}>
            {listOfList.map((item) => {
              if (!item?.node?.id) return null;
              const isActive = selectedLists.some((list) => list.id === item.node?.id);

              const handleSelectList = () => {
                if (isActive) {
                  setSelectedLists((arr: InviteOutreachCreatorsSelectedLists) =>
                    arr.filter((list) => list.id !== item.node.id)
                  );
                } else {
                  if (!item.node) return;
                  setSelectedLists([
                    {
                      id: item.node.id,
                      count: item.node.creators?.totalCount || 0,
                    },
                  ]);
                }
              };

              return (
                <DropdownGroupItem key={item.node.id}>
                  <div className={styles.dropdownItemRowValue} onClick={handleSelectList}>
                    <div className={styles.dropdownItemColumnValue}>
                      <Text
                        type="md"
                        className={styles.dropdownItemText}
                        text={item.node.name || '-'}
                      />
                      <Text
                        type="label"
                        className={styles.placeholder}
                        msg={'invite_outreach_creators_modal.creators_list_count'}
                        formatValues={{
                          count: item.node.creators?.totalCount || '-',
                        }}
                      />
                    </div>
                    {isActive && <Icon name={'Check'} />}
                    <Link to={`${CREATORS_LIST_ROUTE}/${item.node.id}`} target="_blank">
                      <Icon name={'Eye'} className={styles.dropdownItemIcon} />
                    </Link>
                  </div>
                </DropdownGroupItem>
              );
            })}
            <AlterButton
              msg="invite_outreach_creators_modal.create_lists"
              fluid
              iconPosition="right"
              icon="Add-plus-circle"
              onClick={handleCreateList}
              classes={{
                buttonText: styles.addListButton,
              }}
            />
          </DropdownGroup>
        </Dropdown>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default CreatorsListSelector;
