import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import BriefContent from '../BriefContent/BriefContent';

import Spinner from 'Atoms/Spinner/Spinner';
import { MobileBriefQuery as QueryType } from 'GraphTypes/MobileBriefQuery.graphql';

const MobileBriefQuery = graphql`
  query MobileBriefQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      ...BriefContent_campaign
    }
  }
`;

interface Props {
  mobileView?: boolean;
  campaignId: string;
}

const MobileBrief: React.FC<Props> = (props) => {
  const { campaignId, mobileView = true } = props;

  const data = useLazyLoadQuery<QueryType>(
    MobileBriefQuery,
    { campaignId },
    { fetchPolicy: 'store-or-network' }
  );

  if (!data) return <Spinner style={{ margin: '40px auto' }} />;

  const campaign = data.campaign;

  return (
    <div>
      <BriefContent campaign={campaign} mobileView={mobileView} isCreator={mobileView} />
    </div>
  );
};

export default MobileBrief;
