import { graphql } from 'react-relay';

const FilterFormHairListQuery = graphql`
  query FilterFormHairListQuery {
    hairTypes {
      id
      name
    }
  }
`;

export default FilterFormHairListQuery;
