import React, { Fragment, useContext, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import classNames from 'classnames';

import styles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';
import { OutreachSettingsTabs } from '../InviteOutreachCreators';

import { insenseSequencePresets } from './insensePresets';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import {
  SequenceSelectorForOutreach_list$data,
  SequenceSelectorForOutreach_list$key,
} from 'GraphTypes/SequenceSelectorForOutreach_list.graphql';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import ls from 'Util/localStorage';
import Accordion from 'Components/ui/Accordion/Accordion';

interface Props {
  query: SequenceSelectorForOutreach_list$key;
  userQuery: SequenceSelectorForOutreach_list$key;
  setActiveTab: (tab: OutreachSettingsTabs) => void;
}

const fragmentQuery = graphql`
  fragment SequenceSelectorForOutreach_list on Organization {
    id
    outreachSequenceBlueprints {
      edges {
        node {
          id
          name
          steps {
            edges {
              node {
                id
                startsAtHour
                delayInDays
                projectEventType
                template {
                  id
                  subject
                  htmlBody
                }
              }
            }
          }
        }
      }
    }
  }
`;

type SequenceList = NonNullable<
  SequenceSelectorForOutreach_list$data['outreachSequenceBlueprints']
>['edges'];

const SequenceSelector: React.FC<Props> = (props) => {
  const { query, userQuery, setActiveTab } = props;
  const { sequenceId, setSequenceList, setTemplateChangedStatus, setSequenceId } = useContext(
    InviteOutreachCreatorsContext
  );
  const data = useFragment(fragmentQuery, query);
  const userData = useFragment(fragmentQuery, userQuery);

  const isAdmin = ls.get('admin');

  const templates = data.outreachSequenceBlueprints?.edges;
  const userTemplates = userData.outreachSequenceBlueprints?.edges;
  const isAdminMakeOutreachForClient = data.id !== userData.id;
  const sequenceName = useMemo(() => {
    let name = data.outreachSequenceBlueprints?.edges?.find(
      (sequence) => sequence?.node?.id === sequenceId
    )?.node?.name;
    if (!name) {
      name = userData.outreachSequenceBlueprints?.edges?.find(
        (sequence) => sequence?.node?.id === sequenceId
      )?.node?.name;
      if (!name) {
        name = insenseSequencePresets.find((sequence) => sequence.node.id === sequenceId)?.node
          .name;
      }
    }
    return name;
  }, [sequenceId]);

  const handleSequenceClick = () => {
    setActiveTab(OutreachSettingsTabs.SEQUENCE);
  };

  const renderList = (source: NonNullable<SequenceList>) => {
    return source.map((item) => {
      if (!item?.node) return null;

      const { id, name } = item.node;

      const isActive = sequenceId === id;

      const handleTemplateSelect = () => {
        amplitude.sendEvent<411>({
          id: '411',
          category: 'campaign',
          name: 'outreach_sequence_click',
          param: { sequence_name: name },
        });
        const steps = item.node?.steps?.edges?.map((step) => {
          return {
            ...step?.node,
            validTemplate: true,
          };
        });
        if (id) {
          setSequenceId(id);
        } else {
          setSequenceId(null);
          setActiveTab(OutreachSettingsTabs.SEQUENCE);
        }

        if (steps) {
          setSequenceList(steps);
        }
        setTemplateChangedStatus(false);
      };
      return (
        <DropdownGroupItem key={item.node.id}>
          <AlterButton
            text={item.node.name}
            onClick={handleTemplateSelect}
            fluid
            iconPosition="right"
            icon={isActive ? 'Check' : null}
          />
        </DropdownGroupItem>
      );
    });
  };

  return (
    <>
      <div className={styles.subtitle}>
        <Text type="md" msg="invite_outreach_creators_modal.step_2" />
      </div>
      <Dropdown
        closeBySelect={false}
        value={
          <Text
            type="md"
            className={classNames(styles.dropdownValue, {
              [styles.placeholder]: !sequenceId,
            })}
            text={sequenceName}
            textPriority
            msg={
              templates?.length === 0
                ? 'invite_outreach_creators_modal.empty_template_lists'
                : 'invite_outreach_creators_modal.template.placeholder'
            }
          />
        }
        className={styles.dropdownSettings}
      >
        <DropdownGroup className={styles.dropdownMenu}>
          <Accordion
            titleClassName={styles.accordionMenu}
            items={[
              {
                title: (
                  <Text
                    type="label"
                    className={styles.dropdownGroupLabel}
                    msg={'invite_outreach_creators_modal.insense_templates'}
                  />
                ),
                openedByDefault: true,
                content: renderList(insenseSequencePresets),
              },
            ]}
          />
          <Accordion
            titleClassName={styles.accordionMenu}
            items={[
              {
                title: (
                  <Text
                    type="label"
                    className={styles.dropdownGroupLabel}
                    msg={'invite_outreach_creators_modal.your_templates'}
                  />
                ),
                openedByDefault: true,
                content: renderList(userTemplates),
              },
            ]}
          />
          {Boolean(isAdmin && isAdminMakeOutreachForClient) && (
            <Accordion
              titleClassName={styles.accordionMenu}
              items={[
                {
                  title: (
                    <Text
                      type="label"
                      className={styles.dropdownGroupLabel}
                      msg={'invite_outreach_creators_modal.admin_templates'}
                    />
                  ),
                  openedByDefault: true,
                  content: renderList(templates),
                },
              ]}
            />
          )}
          <AlterButton
            type="white"
            icon="Add-plus-circle"
            iconPosition="right"
            msg="invite_outreach_creators_modal.add_sequence"
            classes={{
              buttonText: styles.addListButton,
            }}
            onClick={handleSequenceClick}
          />
        </DropdownGroup>
      </Dropdown>
    </>
  );
};

export default SequenceSelector;
