import React, { useContext } from 'react';

import styles from './CreatorsBulkActions.pcss';
import BulkMessage from './BulkMessage/BulkMessage';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  idsForBulkAction: string[];
  onClear: () => void;
  allSelected: boolean;
  campaignId?: string;
}

const CreatorsBulkActions: React.FC<Props> = (props) => {
  const { idsForBulkAction, onClear, allSelected, campaignId } = props;

  const { openDrawer } = useContext(DrawerContext);

  const notEmptyList = idsForBulkAction.length > 0;

  const handleBulkMessageClick = () => {
    amplitude.sendEvent<492>({
      id: '492',
      name: 'bulk_snackbar_send_message_click',
      category: 'campaign',
      param: {
        campaign_id: campaignId || '',
        selections_size: idsForBulkAction.length,
      },
    });
    openDrawer('bulk-message');
  };

  return (
    <div className={styles.root}>
      {notEmptyList && (
        <Text
          msg={
            allSelected
              ? 'creators.filter.selected_creators_amount.all'
              : 'creators.filter.selected_creators_amount'
          }
          formatValues={{ count: idsForBulkAction.length }}
          color="white"
        />
      )}
      <AlterButton
        icon="Chat"
        msg="creators.filter.action.message"
        hover={false}
        disabled={!notEmptyList}
        onClick={handleBulkMessageClick}
      />
      <BulkMessage
        idsForBulkAction={idsForBulkAction}
        onSuccess={onClear}
        campaignId={campaignId}
      />
    </div>
  );
};

export default CreatorsBulkActions;
