import { graphql } from 'react-relay';

const FilterFormTagListQuery = graphql`
  query FilterFormTagListQuery {
    tags(currency: USD) {
      id
      name
    }
  }
`;

export default FilterFormTagListQuery;
