import React, { useEffect, Suspense } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import { isWaitingListCreatedByAdmin, getArchivedStagesList } from '../../utils';

import CreatorsListContainer from './CreatorsListContainer/CreatorsListContainer';
import CreatorsStub from './CreatorsStub/CreatorsStub';

import {
  CreatorsListQuery as QueryType,
  ProjectOrder,
  AdvertiserProjectStage,
  CreatorBadge,
  DateInterval,
  SearchQueryInput,
} from 'GraphTypes/CreatorsListQuery.graphql';

export const CreatorsListQuery = graphql`
  query CreatorsListQuery(
    $campaignId: ID!
    $organizationId: ID!
    $textQuery: String
    $shortlisted: Boolean
    $stages: [AdvertiserProjectStage!]
    $orderBy: ProjectOrder
    $archived: Boolean
    $badges: [CreatorBadge!]
    $createdByAdmin: Boolean
    $invitedByOrganizationMember: Boolean
    $outreach: Boolean
    $withPreviousCollaborations: Boolean
    $screeningQuestionIds: [ID!]
    $publicationDeadlineIntervals: [DateInterval!]
    $draftDeadlineIntervals: [DateInterval!]
    $searchQuery: SearchQueryInput
  ) {
    ...CreatorsListContainer_campaign
      @arguments(
        campaignId: $campaignId
        textQuery: $textQuery
        shortlisted: $shortlisted
        stages: $stages
        orderBy: $orderBy
        archived: $archived
        badges: $badges
        createdByAdmin: $createdByAdmin
        invitedByOrganizationMember: $invitedByOrganizationMember
        outreach: $outreach
        organizationId: $organizationId
        withPreviousCollaborations: $withPreviousCollaborations
        screeningQuestionIds: $screeningQuestionIds
        publicationDeadlineIntervals: $publicationDeadlineIntervals
        draftDeadlineIntervals: $draftDeadlineIntervals
        searchQuery: $searchQuery
      )
  }
`;

interface Props {
  campaignId: string;
  textQuery?: string;
  isAdminView: boolean;
  isBulkAction: boolean;
  shortlisted?: boolean | null;
  invitedByOrganizationMember?: boolean | null;
  outreach?: boolean;
  isLongTermCampaign?: boolean;
  archived: boolean;
  orderBy?: ProjectOrder;
  stages?: AdvertiserProjectStage[];
  creatorsStages?: AdvertiserProjectStage[];
  defaultStages?: AdvertiserProjectStage[];
  badges?: CreatorBadge[];
  section?: string;
  portfolioTypeName: string;
  portfolioCategoryName: string;
  email?: string;
  organizationId: string;
  screeningQuestionIds?: string[];
  withPreviousCollaborations?: boolean | null;
  draftDeadlineIntervals: DateInterval[];
  publicationDeadlineIntervals: DateInterval[];
  canCreateMultipleDeals?: boolean;
  canBrowseCreators?: boolean;
  searchQuery?: SearchQueryInput;
}

const CreatorsList: React.FC<Props> = (props) => {
  const {
    campaignId,
    isAdminView,
    shortlisted,
    orderBy,
    stages,
    badges,
    textQuery,
    archived,
    section,
    creatorsStages,
    defaultStages,
    portfolioTypeName,
    portfolioCategoryName,
    isLongTermCampaign,
    email,
    isBulkAction,
    invitedByOrganizationMember,
    outreach,
    organizationId,
    withPreviousCollaborations,
    screeningQuestionIds,
    draftDeadlineIntervals,
    publicationDeadlineIntervals,
    canCreateMultipleDeals,
    canBrowseCreators,
    searchQuery,
  } = props;

  const createdByAdminValue = section ? isWaitingListCreatedByAdmin(section, isAdminView) : null;

  const archivedStages = getArchivedStagesList(isAdminView, !!canBrowseCreators);

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(CreatorsListQuery);

  useEffect(() => {
    loadQuery(
      {
        campaignId,
        shortlisted,
        orderBy,
        stages,
        badges,
        archived,
        textQuery,
        createdByAdmin: createdByAdminValue,
        invitedByOrganizationMember,
        outreach,
        organizationId,
        withPreviousCollaborations,
        screeningQuestionIds,
        draftDeadlineIntervals,
        publicationDeadlineIntervals,
        searchQuery,
      },
      { fetchPolicy: 'network-only' }
    );
  }, [
    campaignId,
    shortlisted,
    orderBy,
    stages,
    badges,
    archived,
    textQuery,
    createdByAdminValue,
    invitedByOrganizationMember,
    outreach,
    organizationId,
    withPreviousCollaborations,
    screeningQuestionIds,
    draftDeadlineIntervals,
    publicationDeadlineIntervals,
    searchQuery,
  ]);

  return (
    <div>
      <Suspense fallback={<CreatorsStub />}>
        {queryReference && (
          <CreatorsListContainer
            queryReference={queryReference}
            campaignId={campaignId}
            organizationId={organizationId}
            isAdminView={isAdminView}
            isBulkAction={isBulkAction}
            shortlisted={!!shortlisted}
            orderBy={orderBy}
            stages={stages}
            badges={badges}
            textQuery={textQuery}
            archived={archived}
            createdByAdmin={createdByAdminValue}
            creatorsStages={creatorsStages}
            defaultStages={defaultStages}
            archivedStages={archivedStages}
            portfolioTypeName={portfolioTypeName}
            portfolioCategoryName={portfolioCategoryName}
            canCreateMultipleDeals={!!canCreateMultipleDeals}
            isLongTermCampaign={isLongTermCampaign}
            email={email}
            searchQuery={searchQuery}
            invitedByOrganizationMember={!!invitedByOrganizationMember}
            outreach={outreach}
            withPreviousCollaborations={!!withPreviousCollaborations}
            screeningQuestionIds={screeningQuestionIds}
            draftDeadlineIntervals={draftDeadlineIntervals}
            publicationDeadlineIntervals={publicationDeadlineIntervals}
          />
        )}
      </Suspense>
    </div>
  );
};

export default CreatorsList;
