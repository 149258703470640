/**
 * @generated SignedSource<<77d2e35a00b2b3b399acf23050fefde8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type PaymentType = "BARTER" | "MONEY" | "%future added value";
export type ProductDeliveryOption = "BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS" | "BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT" | "COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT" | "TTS_OTHER" | "TTS_SEND_SAMPLE_TO_CREATOR" | "TTS_SEND_SAMPLE_TO_CREATOR_WITHOUT_APPROVAL" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CampaignData_campaign$data = {
  readonly brand: {
    readonly logo: {
      readonly transformations?: {
        readonly collageThumbnailUrl: string;
      } | null | undefined;
    } | null | undefined;
    readonly name: string | null | undefined;
    readonly summary: string | null | undefined;
    readonly websiteUrl: string | null | undefined;
  } | null | undefined;
  readonly brief: {
    readonly __typename: "V2Brief";
    readonly includeReferralFee: boolean | null | undefined;
    readonly paidSocial: boolean;
    readonly paidSocialActivationDays: number | null | undefined;
    readonly paidSocialBca: boolean;
    readonly priceLimitMax: number | null | undefined;
    readonly priceLimitMin: number | null | undefined;
    readonly productDelivery: ProductDeliveryOption | null | undefined;
    readonly productDescription: string | null | undefined;
    readonly productPrice: number | null | undefined;
    readonly productUrl: string | null | undefined;
    readonly referralFeeRate: number | null | undefined;
    readonly sparkAdsActivationDays: number | null | undefined;
    readonly taskDescription: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly cover: {
    readonly height: number | null | undefined;
    readonly thumbnailUrl?: string;
    readonly width: number | null | undefined;
  } | null | undefined;
  readonly description: string | null | undefined;
  readonly name: string | null | undefined;
  readonly paymentType: PaymentType | null | undefined;
  readonly platform: CampaignPlatform | null | undefined;
  readonly type: CampaignType | null | undefined;
  readonly " $fragmentType": "CampaignData_campaign";
};
export type CampaignData_campaign$key = {
  readonly " $data"?: CampaignData_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignData_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignData_campaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "cover",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "height",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "width",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnailUrl",
              "storageKey": null
            }
          ],
          "type": "Image",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentType",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Brand",
      "kind": "LinkedField",
      "name": "brand",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "summary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "websiteUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "logo",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ImageTransformation",
                  "kind": "LinkedField",
                  "name": "transformations",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "collageThumbnailUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Image",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productDescription",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productDelivery",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialBca",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocial",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialActivationDays",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sparkAdsActivationDays",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priceLimitMin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priceLimitMax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "includeReferralFee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "referralFeeRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taskDescription",
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "a115791af18985b2d31ba9198e054230";

export default node;
