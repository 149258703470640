import { useRef, useSyncExternalStore } from 'react';

import ErrorHandler from 'Util/errorHandler';

type Listener = () => void;
const listenersByKeyMap = new Map<string, Set<Listener>>();

export const useSessionStore = (key: string) => {
  const prevValueRef = useRef<unknown>({});
  if (!listenersByKeyMap.has(key)) {
    listenersByKeyMap.set(key, new Set());
  }
  const listeners = listenersByKeyMap.get(key)!;

  const getSnapshot = () => {
    try {
      const item = window.sessionStorage.getItem(key);
      const parsedItem = item ? JSON.parse(item) : {};

      if (JSON.stringify(prevValueRef.current) !== JSON.stringify(parsedItem)) {
        prevValueRef.current = parsedItem;
      }

      return prevValueRef.current;
    } catch (error) {
      ErrorHandler.error(`Error reading from sessionStore hook (${key}):`, error);
      return {};
    }
  };

  const setValue = (newValue: unknown) => {
    try {
      const prevValue = getSnapshot();
      let value = newValue;
      if (JSON.stringify(prevValue) === JSON.stringify(newValue)) return;
      if (typeof prevValue === 'object' && typeof newValue === 'object') {
        value = { ...prevValue, ...newValue };
      }
      window.sessionStorage.setItem(key, JSON.stringify(value));
      prevValueRef.current = value;
      listeners.forEach((listener) => listener());
      window.dispatchEvent(new Event(`sessionStore${key}Change`));
    } catch (error) {
      ErrorHandler.error(`Error writing to sessionStore hook (${key}):`, error);
    }
  };

  const subscribe = (listener: Listener) => {
    listeners.add(listener);
    const handler = () => listeners.forEach((l) => l());

    window.addEventListener(`sessionStore${key}Change`, handler);

    return () => {
      listeners.delete(listener);
      if (listeners.size === 0) {
        listenersByKeyMap.delete(key);
      }
      window.removeEventListener(`sessionStore${key}Change`, handler);
    };
  };

  const value = useSyncExternalStore(subscribe, getSnapshot);
  return [value, setValue];
};
