import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import classNames from 'classnames';

import styles from './SelectCustomListForCreator.pcss';

import { CustomListForCreatorDrawerQuery } from 'GraphTypes/CustomListForCreatorDrawerQuery.graphql';
import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';

interface Props {
  filterValue: string;
  selectedLists: string[];
  setSelectedLists: React.Dispatch<React.SetStateAction<string[]>>;
}

const CustomListQuery = graphql`
  query CustomListForCreatorDrawerQuery($textQuery: String) {
    currentUser {
      organization {
        customLists(textQuery: $textQuery) {
          edges {
            node {
              id
              name
              creators {
                totalCount
              }
            }
          }
        }
        favoriteCreators {
          totalCount
        }
      }
    }
  }
`;

const favoritesKey = 'favorites';

export const CustomListForCreatorDrawer: React.FC<Props> = (props) => {
  const { filterValue, setSelectedLists, selectedLists } = props;
  const { currentUser } = useLazyLoadQuery<CustomListForCreatorDrawerQuery>(CustomListQuery, {
    textQuery: filterValue,
  });

  const handleFavoriteSelect = () => {
    setSelectedLists((prev) => {
      if (prev.includes(favoritesKey)) {
        return prev.filter((item) => item !== favoritesKey);
      }
      return [...prev, favoritesKey];
    });
  };

  const list = currentUser?.organization?.customLists?.edges || [];
  return (
    <div className={styles.listWrapper}>
      {/* <div
        className={classNames(styles.listItem, {
          [styles.active]: selectedLists.includes(favoritesKey),
        })}
        onClick={handleFavoriteSelect}
      >
        <div className={styles.listItemIcon}>
          <Icon name="Heart" size={24} />
        </div>
        <Text
          type="md"
          className={styles.listItemNameText}
          msg="custom_list_add_modal.favorite_creators"
        />
        <Text
          type="md"
          msg="custom_list_add_modal.creator_count"
          className={styles.listItemCountText}
          formatValues={{
            count: currentUser?.organization?.favoriteCreators?.totalCount || 0,
          }}
        />
      </div> */}
      {list.map((item) => {
        const { id, name, creators } = item?.node || {};
        if (!id || !name) return null;
        const isSelected = selectedLists.includes(id);
        const handleSelect = () => {
          setSelectedLists((prev) => {
            if (prev.includes(id)) {
              return prev.filter((item) => item !== id);
            }
            return [...prev, id];
          });
        };
        return (
          <div
            key={id}
            className={classNames(styles.listItem, {
              [styles.active]: isSelected,
            })}
            onClick={handleSelect}
          >
            <div className={styles.listItemIcon}>
              <Icon name="Save-list" size={24} />
            </div>
            <Text type="md" className={styles.listItemNameText} text={name} />
            <Text
              type="md"
              className={styles.listItemCountText}
              msg="custom_list_add_modal.creator_count"
              formatValues={{ count: creators?.totalCount || 0 }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CustomListForCreatorDrawer;
