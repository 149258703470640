import React, { MouseEvent, Suspense, useEffect } from 'react';

import styles from './FilterSelect.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { Checkbox } from 'Components/ui/Checkbox';
import RadioButton from 'Components/ui/RadioButton/RadioButton';
import Text from 'Components/ui/Text/Text';

interface Props {}

const FilterSelectItems: React.FC<Props> = ({
  searchQueryText,
  isMultiselect,
  value,
  renderItem,
  handleChangeValue,
  itemsQuery,
  queryData,
  setQueryVariables,
  filterQueryVariable,
  items,
  additionalGroup,
}) => {
  const queryRequestEntity: string[] = itemsQuery?.default.operation.selections.map(
    (field: ListProps['queryData']) => field.name
  );

  useEffect(() => {
    if (filterQueryVariable) {
      setQueryVariables({ [filterQueryVariable]: searchQueryText });
    }
  }, [searchQueryText, filterQueryVariable]);

  const renderBySource = (source: unknown[]) => {
    return source.map((item) => {
      const isActive = isMultiselect ? value.includes(item.id) : value === item.id;
      const handleSelect = (visibleName?: string | React.MouseEvent<HTMLDivElement>) => {
        handleChangeValue(item.id, typeof visibleName === 'string' ? visibleName : item.name);
      };
      if (renderItem) {
        return renderItem({ ...item, isActive, handleSelect });
      }
      return (
        <div
          key={item.id}
          className={styles.item}
          onClick={handleSelect as (event: MouseEvent<HTMLDivElement>) => void}
        >
          <AlterButton fluid text={item.name} />
          {isMultiselect ? <Checkbox checked={isActive} /> : <RadioButton checked={isActive} />}
        </div>
      );
    });
  };

  const itemsList = itemsQuery ? queryData[queryRequestEntity[0]] : items || [];
  const listSource = itemsList.edges ? itemsList.edges.map((edge) => edge.node) : itemsList;
  const list = listSource.filter((item) => {
    return item.name.toLowerCase().includes(searchQueryText);
  });
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {Boolean(additionalGroup && !searchQueryText) && (
        <div className={styles.additionalGroup}>
          <Text type="label" className={styles.additionalGroupTitle} msg={additionalGroup.title} />
          {renderBySource(additionalGroup.items)}
          {list.length > 0 && (
            <Text
              type="label"
              className={styles.additionalGroupTitle}
              msg={'filter.form.placeholder.by_alphabet'}
            />
          )}
        </div>
      )}
      {renderBySource(list)}
    </Suspense>
  );
};

export default React.memo(FilterSelectItems);
