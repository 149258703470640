import React from 'react';

import { FilterFormItem } from '../FilterForm';
import { FilterFormFilterType } from '../utils';

import withDropdown from './FilterWithDropdown';
import FilterInput from './FilterInput/FilterInput';
import FilterSelect from './FilterSelect/FilterSelect';
import FilterRange from './FilterRange/FilterRange';
import FilterList from './FilterList/FilterList';
import FilterFlatList from './FilterFlatList/FilterFlatList';
import withSelection from './FilterWithSelection';
import FilterToggle from './FilterToggle/FilterToggle';
import FilterFlagsList from './FilterFlagsList/FilterFlagsList';

const Filter: React.FC<FilterFormItem> = (props) => {
  const { renderWithDropdown, type } = props;
  let FilterComponent = null;
  switch (type) {
    case FilterFormFilterType.INPUT:
      FilterComponent = FilterInput;
      break;
    case FilterFormFilterType.SELECT:
      FilterComponent = withSelection(FilterSelect);
      break;
    case FilterFormFilterType.INPUT_RANGE:
      FilterComponent = FilterRange;
      break;
    case FilterFormFilterType.LIST:
      FilterComponent = withSelection(FilterList);
      break;
    case FilterFormFilterType.FLAT_LIST:
      FilterComponent = withSelection(FilterFlatList);
      break;
    case FilterFormFilterType.TOGGLE:
      FilterComponent = FilterToggle;
      break;
    case FilterFormFilterType.FLAG_LIST:
      FilterComponent = FilterFlagsList;
      break;
    default:
      return null;
  }
  if (renderWithDropdown) {
    const FilterWithDropdown = withDropdown(FilterComponent);
    return <FilterWithDropdown {...props} />;
  }
  return <FilterComponent {...props} />;
};

export default React.memo(Filter);
