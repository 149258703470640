/**
 * @generated SignedSource<<6f80b10b5ebc1fbf7c3294b885797da0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilterFormEthnicitiyListQuery$variables = Record<PropertyKey, never>;
export type FilterFormEthnicitiyListQuery$data = {
  readonly ethnicities: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type FilterFormEthnicitiyListQuery = {
  response: FilterFormEthnicitiyListQuery$data;
  variables: FilterFormEthnicitiyListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "visible",
        "value": true
      }
    ],
    "concreteType": "Ethnicity",
    "kind": "LinkedField",
    "name": "ethnicities",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": "ethnicities(visible:true)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterFormEthnicitiyListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FilterFormEthnicitiyListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8f519227aa3656f5f19715e9f718ab73",
    "id": null,
    "metadata": {},
    "name": "FilterFormEthnicitiyListQuery",
    "operationKind": "query",
    "text": "query FilterFormEthnicitiyListQuery {\n  ethnicities(visible: true) {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b068ff49a99dc130d59fbdc05fdbf0e";

export default node;
