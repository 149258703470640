import React, { useEffect, useState } from 'react';
import {
  graphql,
  PreloadedQuery,
  useLazyLoadQuery,
  usePaginationFragment,
  usePreloadedQuery,
} from 'react-relay';
import xor from 'lodash/xor';
import classnames from 'classnames';

import CreatorsStub from '../CreatorsStub/CreatorsStub';
import { CreatorsListQuery } from '../CreatorsList';

import styles from './CreatorsListContainer.pcss';
import CreatorCard from './CreatorCard/CreatorCard';
import CreatorsBulkActions from './CreatorsBulkActions/CreatorsBulkActions';

import LoaderHandlerWithHooks from 'Organisms/LoaderHandler/NewLoaderHandler';
import Text from 'Components/ui/Text/Text';
import { CreatorsListContainer_campaign$key } from 'GraphTypes/CreatorsListContainer_campaign.graphql';
import {
  ProjectOrder,
  AdvertiserProjectStage,
  CreatorBadge,
  CreatorsListQuery as QueryType,
  DateInterval,
} from 'GraphTypes/CreatorsListQuery.graphql';
import { Checkbox } from 'Components/ui/Checkbox';
import { CreatorsListContainerQuery } from 'GraphTypes/CreatorsListContainerQuery.graphql';
import { amplitude } from 'Helpers/amplitude';

const COUNT = 10;
const OFFSET = 70;

interface Props {
  campaignId: string;
  organizationId?: string;
  isAdminView: boolean;
  shortlisted?: boolean;
  isBulkAction: boolean;
  invitedByOrganizationMember?: boolean;
  outreach?: boolean;
  isLongTermCampaign?: boolean;
  orderBy?: ProjectOrder;
  stages?: AdvertiserProjectStage[];
  badges?: CreatorBadge[];
  textQuery?: string;
  archived: boolean;
  canCreateMultipleDeals: boolean;
  createdByAdmin?: boolean | null;
  creatorsStages?: AdvertiserProjectStage[];
  defaultStages?: AdvertiserProjectStage[];
  archivedStages?: AdvertiserProjectStage[];
  portfolioTypeName: string;
  portfolioCategoryName: string;
  screeningQuestionIds?: string[];
  withPreviousCollaborations?: boolean;
  searchQuery?: any;
  queryReference: PreloadedQuery<QueryType>;
  draftDeadlineIntervals: DateInterval[];
  publicationDeadlineIntervals: DateInterval[];
}

const CreatorsListContainer: React.FC<Props> = (props) => {
  const {
    organizationId,
    campaignId,
    isAdminView,
    creatorsStages,
    defaultStages,
    archivedStages,
    portfolioTypeName,
    portfolioCategoryName,
    canCreateMultipleDeals,
    isLongTermCampaign,
    queryReference,
    isBulkAction,
    shortlisted,
    textQuery,
    stages,
    archived,
    badges,
    createdByAdmin,
    outreach,
    invitedByOrganizationMember,
    withPreviousCollaborations,
    draftDeadlineIntervals,
    publicationDeadlineIntervals,
    screeningQuestionIds,
    searchQuery,
  } = props;
  const [portfolioIndex, setPortfolioIndex] = useState<number>(0);
  const [idsForBulkAction, setIdsForBulkAction] = useState<string[]>([]);

  const queryData = usePreloadedQuery<QueryType>(CreatorsListQuery, queryReference);

  const bulkData = useLazyLoadQuery<CreatorsListContainerQuery>(
    graphql`
      query CreatorsListContainerQuery(
        $campaignId: ID!
        $shortlisted: Boolean
        $textQuery: String
        $stages: [AdvertiserProjectStage!]
        $archived: Boolean
        $badges: [CreatorBadge!]
        $createdByAdmin: Boolean
        $outreach: Boolean
        $invitedByOrganizationMember: Boolean
        $withPreviousCollaborations: Boolean
        $draftDeadlineIntervals: [DateInterval!]
        $publicationDeadlineIntervals: [DateInterval!]
        $screeningQuestionIds: [ID!]
        $searchQuery: SearchQueryInput
      ) {
        campaign(id: $campaignId) {
          projects(
            shortlisted: $shortlisted
            textQuery: $textQuery
            stages: $stages
            archived: $archived
            badges: $badges
            createdByAdmin: $createdByAdmin
            invitedByOrganizationMember: $invitedByOrganizationMember
            outreach: $outreach
            withPreviousCollaborations: $withPreviousCollaborations
            draftDeadlineIntervals: $draftDeadlineIntervals
            publicationDeadlineIntervals: $publicationDeadlineIntervals
            screeningQuestionIds: $screeningQuestionIds
            searchQuery: $searchQuery
            orderBy: LAST_ADDED
          ) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    `,
    {
      campaignId,
      shortlisted,
      textQuery,
      stages,
      archived,
      badges,
      createdByAdmin,
      outreach,
      invitedByOrganizationMember,
      withPreviousCollaborations,
      draftDeadlineIntervals,
      publicationDeadlineIntervals,
      screeningQuestionIds,
      searchQuery,
    }
  );

  useEffect(() => {
    setIdsForBulkAction([]);
  }, [
    shortlisted,
    textQuery,
    stages,
    archived,
    badges,
    createdByAdmin,
    outreach,
    invitedByOrganizationMember,
    withPreviousCollaborations,
    draftDeadlineIntervals,
    publicationDeadlineIntervals,
    screeningQuestionIds,
    searchQuery,
  ]);

  const idsForBulkSelectAll: Array<string> =
    bulkData?.campaign?.projects?.edges?.map((edge) => edge?.node?.id || '') || [];

  const fragmentData = usePaginationFragment<QueryType, CreatorsListContainer_campaign$key>(
    graphql`
      fragment CreatorsListContainer_campaign on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        orderBy: { type: "ProjectOrder" }
        organizationId: { type: "ID!" }
        campaignId: { type: "ID!" }
        stages: { type: "[AdvertiserProjectStage!]" }
        shortlisted: { type: "Boolean" }
        textQuery: { type: "String" }
        archived: { type: "Boolean", defaultValue: false }
        badges: { type: "[CreatorBadge!]" }
        createdByAdmin: { type: "Boolean" }
        invitedByOrganizationMember: { type: "Boolean" }
        outreach: { type: "Boolean" }
        screeningQuestionIds: { type: "[ID!]" }
        withPreviousCollaborations: { type: "Boolean" }
        searchQuery: { type: "SearchQueryInput" }
        draftDeadlineIntervals: { type: "[DateInterval!]", defaultValue: [] }
        publicationDeadlineIntervals: { type: "[DateInterval!]", defaultValue: [] }
      )
      @refetchable(queryName: "CreatorsListContainerPaginationList") {
        campaign(id: $campaignId) {
          activation {
            paused
          }
          projects(
            first: $count
            after: $cursor
            orderBy: $orderBy
            shortlisted: $shortlisted
            textQuery: $textQuery
            stages: $stages
            archived: $archived
            badges: $badges
            createdByAdmin: $createdByAdmin
            invitedByOrganizationMember: $invitedByOrganizationMember
            outreach: $outreach
            withPreviousCollaborations: $withPreviousCollaborations
            draftDeadlineIntervals: $draftDeadlineIntervals
            publicationDeadlineIntervals: $publicationDeadlineIntervals
            screeningQuestionIds: $screeningQuestionIds
            searchQuery: $searchQuery
          ) @connection(key: "Projects_projects", filters: []) {
            edges {
              node {
                id
                creator {
                  id
                }
                ...CreatorCard_project @arguments(organizationId: $organizationId)
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      }
    `,
    queryData
  );

  const items = Array.from(fragmentData?.data.campaign?.projects.edges || []);
  const totalCount = bulkData.campaign?.projects.edges?.length || 0;

  if (items?.length === 0) {
    return <Text msg="new_campaign.no_creators" />;
  }

  const handleIdsClear = () => {
    setIdsForBulkAction([]);
  };

  const handleSelectAll = () => {
    amplitude.sendEvent<486>({
      id: '486',
      category: 'campaign',
      name: 'bulk_action_select_all',
      param: {
        campaign_id: campaignId,
        mode: idsForBulkAction.length === idsForBulkSelectAll.length ? 'deselect' : 'select',
        selection_size: idsForBulkAction.length,
      },
    });

    if (idsForBulkAction.length === idsForBulkSelectAll.length) {
      handleIdsClear();
    } else {
      setIdsForBulkAction(idsForBulkSelectAll);
    }
  };

  const cards = items?.map((item, index) => {
    if (!item?.node) return null;

    const { id } = item.node;
    const onCardHover: (cardIndex: number) => void = (cardIndex) => setPortfolioIndex(cardIndex);
    const creatorId = item?.node?.creator?.id || '';

    const isSelected = idsForBulkAction.includes(id);

    const handleProjectSelect = (newId: string) => {
      const prevCount = idsForBulkAction.length;
      const newList = xor(idsForBulkAction, [newId]);
      const newCount = newList.length;
      amplitude.sendEvent<491>({
        name: 'bulk_creator_selected',
        category: 'campaign',
        id: '491',
        param: {
          project_id: item?.node?.id,
          mode: newCount > prevCount ? 'select' : 'deselect',
        },
      });
      setIdsForBulkAction(newList);
    };

    return (
      <CreatorCard
        isBulkAction={isBulkAction}
        project={item.node}
        isAdminView={isAdminView}
        organizationId={organizationId}
        campaignId={campaignId}
        key={id}
        isSelected={isSelected}
        isPortfolioOpen={index === portfolioIndex}
        onMouseEnter={() => onCardHover(index)}
        creatorId={creatorId}
        creatorsStages={creatorsStages}
        defaultStages={defaultStages}
        archivedStages={archivedStages}
        portfolioTypeName={portfolioTypeName}
        portfolioCategoryName={portfolioCategoryName}
        canCreateMultipleDeals={canCreateMultipleDeals}
        isLongTermCampaign={isLongTermCampaign}
        onSelect={handleProjectSelect}
      />
    );
  });

  return (
    <div className={styles.creatorsContainerRoot}>
      <div
        className={classnames(styles.creatorsCount, { [styles.pointer]: isBulkAction })}
        onClick={isBulkAction ? handleSelectAll : undefined}
      >
        {isBulkAction && (
          <Checkbox
            variant={idsForBulkAction.length !== totalCount ? 'indeterminate' : 'normal'}
            checked={idsForBulkAction.length > 0}
            className={styles.checkbox}
          />
        )}
        <Text
          type="md"
          color="dark"
          formatValues={{ count: totalCount || '-' }}
          msg={isBulkAction ? 'campaign.creators_bulk_select' : 'campaign.creators_count'}
        />
      </div>
      <LoaderHandlerWithHooks
        relay={fragmentData}
        offset={OFFSET}
        count={COUNT}
        items={items}
        preloader={<CreatorsStub />}
      >
        <div className={styles.cards}>{cards}</div>
      </LoaderHandlerWithHooks>
      {!!idsForBulkAction.length && isBulkAction && (
        <CreatorsBulkActions
          idsForBulkAction={idsForBulkAction}
          onClear={handleIdsClear}
          allSelected={idsForBulkAction.length === idsForBulkSelectAll.length}
          campaignId={campaignId}
        />
      )}
    </div>
  );
};

export default CreatorsListContainer;
