import { FiltersFormContextType } from './FiltersFormContext';

type FiltersFormReducerType = (
  state: FiltersFormContextType,
  action: ModalActions
) => FiltersFormContextType;

export enum FiltersFormActionTypes {
  SET_FILTER = 'SET_FILTER',
  MERGE_FILTERS = 'MERGE_FILTERS',
  RESET_FILTERS = 'RESET_FILTERS',
  SET_LIST = 'SET_LIST',
  SET_ADMIN_STATUS = 'SET_ADMIN_STATUS',
}

const FiltersFormReducer: FiltersFormReducerType = (state, action) => {
  switch (action.type) {
    case FiltersFormActionTypes.SET_LIST: {
      return {
        ...state,
        filterList: action.payload,
      };
    }
    case FiltersFormActionTypes.SET_ADMIN_STATUS: {
      return {
        ...state,
        isAdmin: action.payload,
      };
    }
    case FiltersFormActionTypes.SET_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.value,
        },
        visibleValues: {
          ...state.visibleValues,
          [action.payload.name]: action.payload.visibleValues,
        },
      };
    }
    case FiltersFormActionTypes.MERGE_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload.filters,
        },
        visibleValues: {
          ...state.visibleValues,
          ...action.payload.visibleValues,
        },
      };
    }
    case FiltersFormActionTypes.RESET_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
        visibleValues: {},
      };
    }
    default:
      return state;
  }
};

export { FiltersFormReducer, FiltersFormReducerType };
