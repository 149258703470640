/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';
import { ComponentType } from 'react';

// const isRollbarActive = false;
const isSentryActive = process.env.NODE_ENV !== 'development';
const isConsoleNeeded = process.env.NODE_ENV === 'development';

type ErrorType = 'error' | 'warning' | 'info' | 'debug' | 'log';

type SentryTag =
  | {
      type: string;
      [key: string]: string;
    }
  | undefined;

type ErrorExtra = Record<string, unknown>;

class ErrorHandlerInstance {
  // rollbar: Rollbar | null;
  constructor() {
    // this.rollbar = null;
    // if (isRollbarActive) {
    //   this.rollbar = new Rollbar({
    //     accessToken: '8802bbfc8bf7460993afbade63624ffe',
    //     enabled: process.env.NODE_ENV !== 'development',
    //     captureUncaught: true,
    //     captureUnhandledRejections: true,
    //     payload: {
    //       environment: process.env.DEPLOY_ENV,
    //     },
    //     reportLevel: 'error',
    //   });
    // }
    if (isSentryActive) {
      Sentry.init({
        dsn: 'https://3e25ecf1ca15496eb74c31bd0e50096a@o4504552807989248.ingest.sentry.io/4504608661897216',
        integrations: [browserTracingIntegration()],
        tracesSampleRate: 0.15,
        release: process.env.CLIENT_VERSION || 'unknown',
        ignoreErrors: [
          'ResizeObserver loop limit exceeded',
          'ResizeObserver loop completed with undelivered notifications.',
          'No error message',
          'No transport is open',
          "Couldn't connect via any available transport",
          "Cannot read properties of null (reading 'readyState')",
          "Cannot set properties of undefined (setting 'onclose')",
        ],
        environment: process.env.NODE_ENV === 'development' ? 'development' : 'production',
      });
    }
  }
  public enableProfiler(rootElement: ComponentType<Record<string, any>>) {
    if (isSentryActive) {
      return Sentry.withProfiler(rootElement);
    }
    return rootElement;
  }
  public userIdentity(id: string, email: string) {
    // if (this.rollbar) {
    //   this.rollbar.configure({
    //     payload: {
    //       person: {
    //         id,
    //         email,
    //       },
    //     },
    //   });
    // }
    Sentry.setUser({ id, email });
    if (isConsoleNeeded) {
      console.info('Error handler identified user as ', email, id);
    }
  }
  private capture(level: ErrorType, description: string, extra?: ErrorExtra, tags?: SentryTag) {
    if (isSentryActive) {
      Sentry.withScope((scope) => {
        if (extra) {
          Object.keys(extra).forEach((key) => {
            scope.setExtra(key, extra[key]);
          });
        }
        scope.setLevel(level);
        if (tags) {
          scope.setTags(tags);
        }
        Sentry.captureException(new Error(description));
      });
    }
    // if (this.rollbar) {
    //   const additionalData = {
    //     data: extra,
    //   };
    //   this.rollbar[level](description, additionalData);
    // }
    if (isConsoleNeeded) {
      console.info(`[${level.toUpperCase()}] ${description}`, extra);
    }
  }
  public error(description: string, extra?: ErrorExtra, tags?: SentryTag) {
    this.capture('error', description, extra, tags);
  }
  public warn(description: string, extra?: ErrorExtra, tags?: SentryTag) {
    this.capture('warning', description, extra, tags);
  }
  public info(description: string, extra?: ErrorExtra, tags?: SentryTag) {
    this.capture('info', description, extra, tags);
  }
  public debug(description: string, extra?: ErrorExtra, tags?: SentryTag) {
    this.capture('debug', description, extra, tags);
  }
  public breadcrumb(
    message: string,
    level?: ErrorType,
    data?: {
      [key: string]: unknown;
    }
  ) {
    if (isSentryActive) {
      Sentry.addBreadcrumb({
        message,
        level: level || 'log',
        data,
      });
    }
    if (isConsoleNeeded) {
      console.info(`[BREADCRUMB] ${message}`);
    }
  }
}

const ErrorHandler = new ErrorHandlerInstance();

export default ErrorHandler;
