import React, { useEffect } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { useParams, useNavigate } from 'react-router-dom';

import CampaignContainer from './CampaignContainer/CampaignContainer';

import { getCampaignTypeLink } from 'Util/links';
import Page from 'Templates/Page/Page';
import Spinner from 'Atoms/Spinner/Spinner';
import { BRIEF, CREATORS, APPLICANTS, COMPLETED } from 'Constants/general';
import { CampaignQuery as QueryType } from 'GraphTypes/CampaignQuery.graphql';
import { trackTime as custifyTrackTime } from 'Util/custify';

const CampaignQuery = graphql`
  query CampaignQuery($id: ID!) {
    currentUser {
      id
      admin
      permissions
      email
      organization {
        id
        paymentAccount {
          currency
        }
        subscription {
          planId
          effectiveLimits {
            maxBcaConnections
            maxHiredCreators
          }
          canCreateFacebookAds
          canBrowseCreators
          canUsePaidSocial
          canViewCreatorPrices
          canChatWithCreators
          canCreateMultipleDeals
          canFilterCreatorsByBadge
          canUseApprovalTool
          canManageLonglists
        }
      }
    }
    campaigns {
      totalCount
    }
    campaign(id: $id) {
      id
      name
      type
      platform
      preferredContentType
      preferredCreatorCategory
      publishingRequired
      briefSubmission {
        acceptance {
          id
        }
      }
      counters {
        launchedProjects
      }
      completion {
        id
      }
      organization {
        id
      }
      creator {
        id
        admin
        permissions
        organization {
          paymentAccount {
            currency
          }
          subscription {
            planId
            effectiveLimits {
              maxBcaConnections
            }
            canCreateFacebookAds
            canBrowseCreators
            canUsePaidSocial
            canViewCreatorPrices
            canChatWithCreators
            canCreateMultipleDeals
            canFilterCreatorsByBadge
            canUseApprovalTool
            canManageLonglists
          }
        }
      }
      ...InviteCreators_campaign
      ...CampaignInfo_campaign
    }
  }
`;

const Campaign: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    custifyTrackTime('Campaigns');
    return () => {
      _ctrack.stopTrackTime();
    };
  }, []);

  if (!id) return null;

  const data = useLazyLoadQuery<QueryType>(CampaignQuery, { id });

  useEffect(() => {
    if (!data.campaign?.briefSubmission && data.campaign?.id) {
      const link = getCampaignTypeLink({ campaignId: data.campaign?.id, path: BRIEF });
      navigate(link, { replace: true });
    }
  }, [data.campaign?.briefSubmission, data.campaign?.id]);

  if (!data) return <Spinner style={{ margin: '40px auto' }} />;

  const admin = data.currentUser?.admin;
  const campaign = data.campaign;

  const user = data.currentUser;
  const creator = admin ? data.campaign?.creator : data.currentUser;
  const campaignsCount = data.campaigns?.totalCount;
  const email = user?.email;

  const campaignId = campaign?.id;
  const publishingRequired = campaign?.publishingRequired;
  const briefSubmission = campaign?.briefSubmission;
  const launchedProjects = campaign?.counters?.launchedProjects;
  const isCompleted = campaign?.completion?.id;

  const organizationId = campaign?.organization?.id;

  const defaultType = isCompleted ? COMPLETED : launchedProjects ? CREATORS : APPLICANTS;

  return (
    <Page>
      <CampaignContainer
        campaign={campaign}
        admin={!!admin}
        user={user}
        defaultType={defaultType}
        creator={creator}
        email={email}
        campaignsCount={campaignsCount}
        publishingRequired={publishingRequired}
        organizationId={organizationId}
      />
    </Page>
  );
};

export default Campaign;
