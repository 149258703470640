import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import track from 'react-tracking';
import compact from 'lodash/compact';
import { useSearchParams } from 'react-router-dom';

import styles from './CampaignTabs.pcss';
import ShipmentTabItem from './ShipmentTabItem/ShipmentTabItem';

import ls from 'Util/localStorage';
import { amplitude } from 'Helpers/amplitude';
import Tabs from 'Molecules/Tabs/Tabs';
import { CampaignTabsQuery as QueryType } from 'GraphTypes/CampaignTabsQuery.graphql';
import { CampaignQuery$data } from 'GraphTypes/CampaignQuery.graphql';
import {
  RESULTS,
  CREATORS,
  AWAITING_LIST,
  ARCHIVED,
  INSTAGRAM,
  FACEBOOK,
  TIKTOK,
  APPLICANTS,
  SHIPMENT,
  COMPLETED,
} from 'Constants/general';

const CampaignTabsQuery = graphql`
  query CampaignTabsQuery($id: ID!) {
    campaign(id: $id) {
      id
      private
      completion {
        id
      }
      type
      shopifyAuthorization {
        id
      }
      showShipmentTab
      organization {
        paymentAccount {
          currency
        }
        shopifyAuthorization {
          id
        }
        shopifyAuthorizations {
          edges {
            node {
              id
            }
          }
        }
      }
      brief {
        ... on V2Brief {
          productDelivery
        }
      }
      platform
      publications: creatives {
        totalCount
      }
      applicants: projects(
        stages: [ACCEPTED]
        archived: false
        withUnreadEvents: true
        orderBy: LAST_ADDED
      ) {
        totalCount
      }
      hired: projects(
        stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED]
        archived: false
        withUnreadEvents: true
        orderBy: LAST_ADDED
      ) {
        totalCount
      }
      completed: projects(
        stages: [COMPLETED]
        archived: false
        withUnreadEvents: true
        orderBy: LAST_ADDED
      ) {
        totalCount
      }
    }
  }
`;

interface Props {
  className?: string;
  section: string;
  campaignId: string;
  isAdminView: boolean;
  hasAcceptance: boolean;
  isCompleted: boolean;
  publishingRequired: boolean;
  currentUser: CampaignQuery$data['currentUser'];
  onSectionChange: (section: string) => void;
}

let tabs: JSX.Element;

const CampaignTabs: React.FC<Props> = (props) => {
  const { className, section, campaignId, currentUser, onSectionChange } = props;
  const [params, _] = useSearchParams();
  const preopenedProjectId = params.get('projectId');
  if (preopenedProjectId !== undefined && preopenedProjectId !== null) {
    ls.set('openCreatorChat', preopenedProjectId);
  }
  const canBrowseCreators = !!currentUser?.organization?.subscription?.canBrowseCreators;

  const data = useLazyLoadQuery<QueryType>(CampaignTabsQuery, { id: campaignId });

  if (!data) return tabs ? React.cloneElement(tabs, { currentId: section }) : null;

  const campaign = data.campaign;
  const platform = campaign?.platform;
  const campaignType = campaign?.type;
  const publicationsCount = campaign?.publications.totalCount;
  const productDelivery = campaign?.brief?.productDelivery;
  const showShipmentTab = campaign?.showShipmentTab;
  const hasShopifyAuth = Number(campaign?.organization?.shopifyAuthorizations?.edges?.length) > 0;
  const hasShopifyCampaignAuth = !!campaign?.shopifyAuthorization?.id;

  const resultTabPrefix = campaignType === 'CONTENT_CREATION' ? '.content' : '.performance';

  const items = compact([
    canBrowseCreators
      ? {
          textData: { msg: 'filter_mark.awaiting_list' },
          id: AWAITING_LIST,
        }
      : null,
    {
      textData: { msg: 'filter_mark.applicants' },
      id: APPLICANTS,
      count: campaign?.applicants.totalCount,
      notificationActive: true,
    },
    {
      textData: { msg: 'filter_mark.creator' },
      id: CREATORS,
      count: campaign?.hired.totalCount,
      notificationActive: true,
    },
    {
      textData: { msg: 'filter_mark.completed' },
      id: COMPLETED,
      count: campaign?.completed.totalCount,
      notificationActive: true,
    },
    {
      textData: { msg: 'filter_mark.archived_list' },
      id: ARCHIVED,
      withDivider: true,
    },
    productDelivery &&
    productDelivery === 'BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS' &&
    showShipmentTab
      ? {
          textData: { msg: 'filter_mark.shipment' },
          tooltip: !hasShopifyCampaignAuth ? (
            <ShipmentTabItem
              campaignId={campaignId}
              section={section}
              hasShopifyAuth={hasShopifyAuth}
              onSectionChange={onSectionChange}
            />
          ) : null,
          id: SHIPMENT,
        }
      : null,
    Number(publicationsCount) > 0 ||
    platform === FACEBOOK ||
    platform === INSTAGRAM ||
    platform === TIKTOK
      ? {
          textData: { msg: `filter_mark${resultTabPrefix}` },
          id: RESULTS,
        }
      : null,
  ]);

  const handleSectionSelect = (id: string) => {
    if (id === RESULTS) {
      amplitude.sendEvent<336>({
        id: '336',
        category: 'campaign',
        name: 'perfomance_click',
        param: { campaign_id: campaignId || '' },
      });
    }
    if (id === ARCHIVED) {
      amplitude.sendEvent({
        id: 249,
        category: 'pageview',
        name: 'campaign_archived',
        param: { campaign_id: campaignId || '' },
      });
    }
    if (id === SHIPMENT) {
      amplitude.sendEvent<315>({
        id: '315',
        category: 'campaign',
        name: 'shipment_click',
        param: { campaign_id: `${campaignId}` },
      });
    }

    if (id === COMPLETED) {
      amplitude.sendEvent<460>({
        id: '460',
        category: 'campaign',
        name: 'completed_tab_click',
        param: undefined,
      });
    }

    onSectionChange(id);
  };

  tabs = (
    <Tabs
      className={styles.tabs}
      currentItemId={section}
      items={items}
      onChange={handleSectionSelect}
    />
  );

  return <div className={`${className} ${styles.root}`}>{tabs}</div>;
};

export default track({
  section: 'campaign_tabs',
})(CampaignTabs);
