import React from 'react';
import { useIntl } from 'react-intl';

import styles from './FilterParameter.pcss';

import Tag from 'Components/ui/Tag/Tag';
import Icon from 'Components/ui/Icon/Icon';

interface Props {
  isAudience?: boolean;
  filterKey: string;
  value: string | string[];
  onRemove: (key: string) => void;
  titleLabel?: string;
  reverseValues?: boolean;
  percentValue?: string;
}

const FilterParameter: React.FC<Props> = (props) => {
  const { isAudience, value, onRemove, filterKey, titleLabel, reverseValues, percentValue } = props;
  const intl = useIntl();

  const handleResetClick = () => {
    onRemove(filterKey);
  };

  let text = '';

  if (titleLabel) {
    text = `${intl.formatMessage({ id: titleLabel })}:`;
  }

  if (Array.isArray(value)) {
    if (value[0] && value[1]) {
      text = `${text}${intl.formatMessage(
        { id: `search_section.search_params.${filterKey}.from_and_to` },
        { from: value[0], to: value[1] }
      )}`;
    } else if (value[0] && !value[1]) {
      const key = reverseValues ? 'to' : 'from';
      text = `${text}${intl.formatMessage(
        { id: `search_section.search_params.${filterKey}.${key}` },
        { [key]: value[0] }
      )}`;
    } else {
      const key = reverseValues ? 'from' : 'to';
      text = `${text}${intl.formatMessage(
        { id: `search_section.search_params.${filterKey}.${key}` },
        { [key]: value[1] }
      )}`;
    }
  } else {
    text = `${text}${value}`;
  }

  if (percentValue !== null && !Number.isNaN(Number(percentValue))) {
    text = `${text} (>${(Number(percentValue) * 100).toFixed(0)}%)`;
  }

  return (
    <Tag
      color={isAudience ? 'lightBeige' : 'lightOcean'}
      button={
        <div onClick={handleResetClick} className={styles.removeButton}>
          <Icon name="Close-small" size={16} />
        </div>
      }
      buttonPosition={'right'}
      needMargin={false}
      text={text}
    />
  );
};

export default React.memo(FilterParameter);
