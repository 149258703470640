/**
 * @generated SignedSource<<d0ddb204a1ed9e9296f4e050d680a15d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilterFormHairListQuery$variables = Record<PropertyKey, never>;
export type FilterFormHairListQuery$data = {
  readonly hairTypes: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type FilterFormHairListQuery = {
  response: FilterFormHairListQuery$data;
  variables: FilterFormHairListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "HairType",
    "kind": "LinkedField",
    "name": "hairTypes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterFormHairListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FilterFormHairListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ff5a4bd1fbdf9e2f3d8d7a39a9bb88eb",
    "id": null,
    "metadata": {},
    "name": "FilterFormHairListQuery",
    "operationKind": "query",
    "text": "query FilterFormHairListQuery {\n  hairTypes {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "e34a67d6c9b0e5dbe93a737fbe598a13";

export default node;
