import React, { useContext, useEffect } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import track, { useTracking } from 'react-tracking';
import pickBy from 'lodash/pickBy';
import find from 'lodash/find';
import { isMobile } from 'react-device-detect';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

import { useSessionActions } from 'Hooks/useSessionActions';
import ls from 'Util/localStorage';
import { identifyUser, addHubspotScript } from 'Analytics/engines/Hubspot';
import { ADVERTISER, RUB, CONTRACTOR } from 'Constants/general';
import { UserProviderQuery as QueryType } from 'GraphTypes/UserProviderQuery.graphql';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlagsContainer';
import { identify as custifyIdentify } from 'Util/custify';
import MobileBanner from 'Molecules/MobileBanner/MobileBanner';
import CreatorMobileBanner from 'Molecules/CreatorMobileBanner/CreatorMobileBanner';
import ErrorHandler from 'Util/errorHandler';
import useDashly from 'Hooks/useDashly';
import { GuideTourContext } from 'Containers/GuideTour';

const isProduction = process.env.DEPLOY_ENV === 'production';

const sizeList = [
  { value: '1-10', hsValue: 'company_size_10' },
  { value: '11-50', hsValue: 'company_size_50' },
  { value: '51-200', hsValue: 'company_size_200' },
  { value: '201-500', hsValue: 'company_size_500' },
  { value: '>501', hsValue: 'company_size_501' },
];

const countryList = [
  { value: 'RU', hsValue: null },
  { value: 'US', hsValue: 'region_na' },
  { value: 'CA', hsValue: 'region_cad' },
  { value: 'GB', hsValue: 'region_ukwe' },
];

const UserProviderQuery = graphql`
  query UserProviderQuery {
    currentUser {
      id
      createdAt
      email
      admin
      type
      dashlyHash
      advertiserProfile {
        firstName
        lastName
        phoneNumber
        companyRole
      }
      contractorProfile {
        firstName
        lastName
      }
      paymentAccount {
        currency
      }
      organization {
        id
        subscription {
          planId
        }
        advertisingCountry {
          iso2Code
        }
        counters {
          psLicensedCreators
        }
        name
        size
        test
        onboardingFlowCompleted
        businessDescription
        paymentAccount {
          currency
        }
        websiteUrl
        source
        verticals
        requestedDemo
        subscription {
          planId
        }
      }
      featureFlags {
        featureId
      }
    }
  }
`;

const UserProvider: React.FC = () => {
  const tracking = useTracking();
  const { initFeatureFlags } = useContext(FeatureFlagsContext);
  const { setUserId } = useContext(GuideTourContext);
  const { auth: authDashly, identify: identifyDashly } = useDashly();

  const data = useLazyLoadQuery<QueryType>(UserProviderQuery, {});

  const { initSubscription } = useSessionActions();

  const currentUser = data?.currentUser;
  const userType = data?.currentUser?.type;
  const userId = data?.currentUser?.id;

  useEffect(() => {
    if (!userId || !userType || userType === 'UNKNOWN') return;
    initSubscription();
  }, [userType, userId]);

  if (!currentUser) return null;

  const email = currentUser.email;
  const createdAt = currentUser.createdAt;
  const dashlyHash = currentUser.dashlyHash;
  const advertiserProfile = currentUser.advertiserProfile;
  const phoneNumber = advertiserProfile?.phoneNumber;
  const isAdmin = !!currentUser?.admin;
  const planId = currentUser?.organization?.subscription?.planId || null;
  const organizationName = currentUser?.organization?.name || null;

  if (isMobile && userType === ADVERTISER) {
    return <MobileBanner />;
  }

  if (isMobile && userType === CONTRACTOR) {
    return <CreatorMobileBanner />;
  }

  initFeatureFlags(currentUser?.featureFlags, currentUser?.id);

  if (
    currentUser?.featureFlags?.some((flag) => flag.featureId === 'outreach_tool') &&
    isProduction &&
    !isAdmin
  ) {
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 0.05,
    });
    amplitude.add(sessionReplayTracking);
  }

  ls.set('userType', userType);
  ls.set('admin', isAdmin);

  if (userId) {
    tracking.trackEvent({
      event: 'identity',
      payload: {
        id: userId,
        email,
        createdAt,
        phoneNumber,
      },
    });
  } else {
    tracking.trackEvent({
      event: 'identity',
      payload: {},
    });
  }

  if (dashlyHash) {
    authDashly(userId, dashlyHash);
  }

  const dashlyPropertiesArr = [];

  if (userType === CONTRACTOR) {
    if (currentUser?.contractorProfile) {
      const profile = currentUser?.contractorProfile;
      dashlyPropertiesArr.push({
        key: '$name',
        value: `${profile.firstName} ${profile.lastName}`,
      });
    }
    if (amplitude) {
      const identify = new amplitude.Identify();
      identify.set('User type', userType).set('host', document.location.host);
      amplitude.identify(identify);
    }
  } else {
    if (currentUser?.advertiserProfile) {
      dashlyPropertiesArr.push({
        key: '$name',
        value: `${currentUser?.advertiserProfile?.firstName} ${currentUser?.advertiserProfile?.lastName}`,
      });
    }
    if (advertiserProfile?.phoneNumber) {
      dashlyPropertiesArr.push({
        key: '$phone',
        value: advertiserProfile?.phoneNumber,
      });
    }
    dashlyPropertiesArr.push({
      key: 'organization_name',
      value: organizationName,
    });
  }

  if (email) dashlyPropertiesArr.push({ key: '$email', value: email });

  if (userId) {
    identifyDashly(dashlyPropertiesArr);
    ErrorHandler.userIdentity(userId, email);
    amplitude.setUserId(userId);
  }

  tracking.trackEvent({});

  if (userType === ADVERTISER) {
    setUserId(userId);
    const organization = currentUser.organization;
    const organizationId = organization?.id;
    const admin = currentUser.admin;
    const currency = organization?.paymentAccount?.currency;
    const testFlag = !!organization?.test;

    const isoCode = organization?.advertisingCountry?.iso2Code;

    const regionValue = find(countryList, (item) => {
      return item.value === isoCode;
    });

    const featureFlags = currentUser?.featureFlags?.map((flag) => flag.featureId);
    if (amplitude) {
      const identify = new amplitude.Identify();
      identify
        .set('currency', currency || '-')
        .set('admin', !!admin)
        .set('planId', planId || '-')
        .set('organizationId', organizationId || '-')
        .set('isTestOrganization', testFlag)
        .set('User type', userType)
        .set('Feature flags', featureFlags || [])
        .set('host', document.location.host);
      amplitude.identify(identify);
    }
    custifyIdentify({
      userId: currentUser.id,
      email: currentUser.email,
      company_id: organization?.id,
    });

    if (currency === RUB || !isProduction) {
      return null;
    }

    const organizationSize = organization?.size;
    const organizationSizeItem = find(sizeList, (item) => {
      return (item.value = organizationSize);
    });

    const companySize = organizationSizeItem && organizationSizeItem.hsValue;

    const data = {
      email,
      firstName: advertiserProfile?.firstName,
      lastName: advertiserProfile?.lastName,
      website: organization?.websiteUrl,
      company_size_custom_: companySize,
      region_custom_: regionValue?.hsValue,
      phone: phoneNumber,
      company: organization?.name,
      google_client_id:
        document?.cookie?.match(/_ga=(.+?);/) &&
        document?.cookie
          ?.match(/_ga=(.+?);/)[1]
          .split('.')
          .slice(-2)
          .join('.'),
    };

    const userData = pickBy(data, (item) => item !== undefined && item !== '' && item !== null);

    const callback = () => {
      identifyUser(userData);
    };

    addHubspotScript(callback, !!regionValue?.hsValue);
  }

  return null;
};

export default track()(UserProvider);
