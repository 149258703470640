import { graphql } from 'react-relay';

const FilterFormEthnicitiyListQuery = graphql`
  query FilterFormEthnicitiyListQuery {
    ethnicities(visible: true) {
      id
      name
    }
  }
`;

export default FilterFormEthnicitiyListQuery;
