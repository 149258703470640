import React, { ReactEventHandler, useState, useCallback } from 'react';
import cn from 'classnames';
import classnames from 'classnames';

import styles from './Accordion.pcss';

import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';

interface Props {
  items: AccordionItem[];
  className?: string;
  titleClassName?: string;
  onlyOneOpen?: boolean;
  handleOpenItem?: (openIndex: number) => void;
  shrinkTitle?: boolean;
  withoutSeperaton?: boolean;
}

const Accordion: React.FC<Props> = (props) => {
  const {
    items,
    className,
    titleClassName,
    onlyOneOpen = false,
    handleOpenItem,
    withoutSeperaton,
    shrinkTitle,
  } = props;
  const [openState, setOpenState] = useState<boolean[]>(() => {
    return items.map((item) => !!item.openedByDefault);
  });

  const handleToggle = useCallback<ReactEventHandler<HTMLDetailsElement>>((e) => {
    const index = +(e.currentTarget.dataset.index || 0);
    const open = e.currentTarget.open;
    setOpenState((prev) => {
      const result = [...prev];
      if (handleOpenItem) {
        handleOpenItem(index);
      }
      if (onlyOneOpen && open) {
        const falseOpen = result.map(() => false);
        falseOpen[index] = open;
        return falseOpen;
      }
      result[index] = open;
      return result;
    });
  }, []);

  const arrowIcon = <Icon name="Arrow-small-down" className={styles.icon} />;

  return (
    <div className={cn(styles.root, className)}>
      {items.map((item, key) => {
        const arrowPosition: NonNullable<AccordionItem['arrowPosition']> =
          item.arrowPosition || 'right';
        return (
          <details
            key={key}
            className={classnames({ [styles.withoutSeperaton]: withoutSeperaton })}
            data-index={key}
            onToggle={handleToggle}
            open={openState[key]}
          >
            <summary
              className={classnames(
                styles.title,
                {
                  [styles.shrinkTitle]: shrinkTitle,
                  [styles.withRightArrow]: arrowPosition === 'right',
                  [styles.withLeftArrow]: arrowPosition === 'left',
                },
                titleClassName
              )}
            >
              {arrowPosition === 'left' && arrowIcon}
              {typeof item.title === 'string' ? (
                <Text msg={item.title} color="inherit" size="inherit" weight="inherit" />
              ) : (
                <div>{item.title}</div>
              )}
              {arrowPosition === 'right' && arrowIcon}
            </summary>
            <div
              className={styles.content}
              style={openState[key] ? undefined : { animation: 'none' }}
            >
              {typeof item.content === 'string' ? (
                <Text msg={item.content} color="inherit" size="inherit" weight="inherit" />
              ) : (
                item.content
              )}
            </div>
          </details>
        );
      })}
    </div>
  );
};

export default Accordion;

export type AccordionItem = {
  disabled?: boolean;
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  arrowPosition?: 'left' | 'right';
  openedByDefault?: boolean;
};
