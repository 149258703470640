import React from 'react';
import classNames from 'classnames';

import styles from './FilterFlatList.pcss';

import Text from 'Components/ui/Text/Text';

interface Props {}

const FilterFlatListItems: React.FC<Props> = ({
  value,
  renderItem,
  handleChangeValue,
  itemsQuery,
  queryData,
  items,
}) => {
  const queryRequestEntity: string[] = itemsQuery?.default.operation.selections.map(
    (field: ListProps['queryData']) => field.name
  );

  const itemsList = itemsQuery ? queryData[queryRequestEntity[0]] : items || [];
  const list = itemsList.edges ? itemsList.edges.map((edge) => edge.node) : itemsList;
  return (
    <>
      {list.map((item) => {
        const isActive = value.includes(item.id);
        const handleSelect = () => {
          handleChangeValue(item.id, item.name);
        };
        if (renderItem) {
          return renderItem({ ...item, isActive, handleSelect });
        }
        return (
          <div
            key={item.id}
            className={classNames(styles.listItem, { [styles.active]: isActive })}
            onClick={handleSelect}
          >
            <Text type="md" text={item.name} className={styles.listItemText} />
          </div>
        );
      })}
    </>
  );
};

export default React.memo(FilterFlatListItems);
