import { graphql } from 'react-relay';

const FilterFormCitiesListQuery = graphql`
  query FilterFormCitiesListQuery($query: String!) {
    cities(first: 20, query: $query) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default FilterFormCitiesListQuery;
