import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
  ClipboardEvent,
} from 'react';
import classNames from 'classnames';
import Handlebars from 'handlebars';

import styles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsTemplate, SequenceStep } from '../InviteOutreachCreatorsContext';

import menuStyles from './EditorControl.pcss';
import AddImageControl from './AddImageControl/AddImageControl';
import AddLinkControl from './AddLinkControl/AddLinkControl';

import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Icon from 'Components/ui/Icon/Icon';
import Tooltip from 'Atoms/Tooltip/Tooltip';

interface Props {
  variables: TemplateSpecification;
  template?: InviteOutreachCreatorsTemplate;
  onChangeData: (data: Partial<SequenceStep>) => void;
  isActive: boolean;
  index: number;
}

interface LinkEditMenu {
  show: boolean;
  linkText: string | null;
  linkUrl: string | null;
  linkNode: HTMLElement | null;
  position: {
    top: number;
    left: number;
  };
  isVariable: boolean;
}

const TemplateEditor: React.FC<Props> = (props) => {
  const { variables, template, onChangeData, isActive } = props;

  const editorEl = useRef<HTMLDivElement | null>(null);
  const subjectInputEl = useRef<HTMLDivElement | null>(null);
  const lastFocusedEl = useRef<HTMLDivElement | null>(null);
  const [_, setEditorContent] = useState(template?.htmlBody || '');
  const [templateSubject, setTemplateSubject] = useState(template?.subject || '');
  const [errorText, setErrorText] = useState<string | null>(null);
  const [validTemplate, setTemplateValidation] = useState(
    Boolean(template?.subject && template?.htmlBody)
  );
  const lastUsedVariable = useRef<number>(new Date().getTime());
  const [linkEditMenu, setLinkEditMenu] = useState<LinkEditMenu>({
    show: false,
    linkText: '',
    linkUrl: '',
    linkNode: null,
    position: { top: 0, left: 0 },
    isVariable: false,
  });
  const savedSelection = useRef<Range | null>(null);

  useEffect(() => {
    if (template?.htmlBody && editorEl.current) {
      editorEl.current.innerHTML = template?.htmlBody.replace(new RegExp('\r?\n', 'g'), '<br />');
      document.execCommand('defaultParagraphSeparator', false, 'br');
    }
  }, [editorEl.current]);

  useEffect(() => {
    if (template?.subject && subjectInputEl.current) {
      subjectInputEl.current.innerHTML = template?.subject;
    }
    const handleSubjectKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        subjectInputEl.current?.focus();
      }
    };
    if (subjectInputEl.current) {
      subjectInputEl.current.addEventListener('keydown', handleSubjectKeydown);
    }

    return () => {
      subjectInputEl.current?.removeEventListener('keydown', handleSubjectKeydown);
    };
  }, [subjectInputEl.current]);

  useEffect(() => {
    if (!editorEl.current?.innerHTML) {
      setTemplateValidation(false);
      return;
    }
    try {
      if (editorEl.current?.innerText.trim().length > 0) {
        const templateString = Handlebars.compile(editorEl.current.innerHTML);
        templateString(variables);
      }
    } catch (e) {
      setTemplateValidation(false);
    }
  }, [editorEl.current?.innerHTML, editorEl.current?.innerText]);

  useEffect(() => {
    const focusEventHandler = () => {
      if (document.activeElement === editorEl.current) {
        lastFocusedEl.current = editorEl.current;
      } else if (document.activeElement === subjectInputEl.current) {
        lastFocusedEl.current = subjectInputEl.current;
      }
    };
    document.querySelectorAll('div[contenteditable]').forEach((el) => {
      el.addEventListener('focus', focusEventHandler);
    });
    return () => {
      document.querySelectorAll('div[contenteditable]').forEach((el) => {
        el.removeEventListener('focus', focusEventHandler);
      });
    };
  }, []);

  useEffect(() => {
    const subjectText = templateSubject.trim().replace('<br>', '');
    const body = editorEl.current?.innerText.trim() || '';
    if (subjectText.length > 0 && body.length > 0) {
      setTemplateValidation(true);
    } else {
      setTemplateValidation(false);
    }
  }, [templateSubject, editorEl.current?.innerText]);

  useEffect(() => {
    onChangeData({
      validTemplate,
    });
  }, [validTemplate]);

  const formatText = (command: string, value?: string) => {
    if (checkIfCursorOnVariable()) {
      setCursorOverElement();
    }
    document.execCommand(command, false, value);
  };

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      savedSelection.current = selection.getRangeAt(0);
    }
  };
  const restoreSelection = () => {
    const selection = window.getSelection();
    if (selection && savedSelection.current) {
      selection.removeAllRanges();
      selection.addRange(savedSelection.current);
    }
  };

  const handleInput = () => {
    if (editorEl.current) {
      const contentHtml = editorEl.current.innerHTML.replace(new RegExp('\r?\n', 'g'), '<br />');
      setEditorContent(contentHtml);
      onChangeData({
        template: {
          ...template,
          htmlBody: contentHtml,
          htmlText: editorEl.current.innerText.trim(),
        },
      });
    }
  };

  const handleKeyInput = (e: KeyboardEvent<HTMLDivElement>) => {
    //
  };

  const checkIfCursorOnVariable = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      let currentNode = range.commonAncestorContainer;
      while (
        (currentNode && currentNode.nodeType === Node.TEXT_NODE) ||
        (currentNode as Element).tagName === 'B' ||
        (currentNode as Element).tagName === 'STRIKE' ||
        (currentNode as Element).tagName === 'U' ||
        (currentNode as Element).tagName === 'I'
      ) {
        if (currentNode.parentNode) {
          currentNode = currentNode.parentNode;
        }
      }

      return (
        currentNode.nodeType === Node.ELEMENT_NODE &&
        (currentNode as Element).getAttribute('variable') === 'true'
      );
    }
    return false;
  };

  const setCursorOverElement = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      let currentNode = range.commonAncestorContainer;
      if (currentNode && currentNode.nodeType === Node.TEXT_NODE) {
        if (currentNode.parentNode) {
          currentNode = currentNode.parentNode;
        }
      }
      const newRange = document.createRange();
      newRange.selectNode(currentNode);
      selection.removeAllRanges();
      selection.addRange(newRange);
    }
  };

  const insertHtml = (html: string) => {
    formatText('insertHtml', `${html}`);
  };

  const formatTextToBold = () => {
    formatText('bold');
  };
  const formatTextToItalic = () => {
    formatText('italic');
  };
  const formatTextToUnderline = () => {
    formatText('underline');
  };
  const formatTextToStrike = () => {
    formatText('strikeThrough');
  };
  const formatTextToOrderedList = () => {
    formatText('insertOrderedList');
  };
  const formatTextToUnorderedList = () => {
    formatText('insertUnorderedList');
  };

  const handleAddImage = (url: string, alt?: string) => {
    if (!editorEl.current) return;
    editorEl.current.focus();
    restoreSelection();
    insertHtml(
      `<img src="${url}" alt="${
        alt || ''
      }" style="height: 300px; width: 400px; object-fit: contain" />&nbsp;`
    );
  };

  const handleAddLink = (url: string, text?: string) => {
    if (!editorEl.current) return;
    editorEl.current.focus();
    restoreSelection();
    insertHtml(`<a href="${url}" target="_blank">${text || url}</a>&nbsp;`);
  };

  const handleChangeTemplateSubject = () => {
    if (subjectInputEl.current) {
      setTemplateSubject(subjectInputEl.current.innerHTML);
      onChangeData({
        template: {
          ...template,
          subject: subjectInputEl.current.innerText.trim(),
        },
      });
      setErrorText(null);
    }
  };

  const insertVariable = (variable: string) => {
    lastFocusedEl.current?.focus();
    if (document.activeElement === subjectInputEl.current) {
      if (variable === 'action_url') return;
    }
    let handlebar = `<span contenteditable="false" data-variable="true">{{${variable}}}</span>&nbsp;`;
    if (variable === 'action_url') {
      handlebar = `<a contenteditable="false" data-variable="true" href="{{${variable}}}" target="_blank">Apply</a>&nbsp;`;
      insertHtml(handlebar);
      return;
    }
    insertHtml(handlebar);
  };

  const handleSelectVariable = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    insertVariable(e.target.value);
    lastUsedVariable.current = new Date().getTime();
    return false;
  };

  const handleSelectHeading = (e: ChangeEvent<HTMLSelectElement>) => {
    formatText('formatBlock', e.target.value);
  };

  const openLinkEditMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    const linkNode = e.target as HTMLElement;
    e.preventDefault();
    if (linkNode && linkNode.tagName === 'A' && editorEl.current) {
      const linkRect = linkNode.getBoundingClientRect();
      const editorRect = editorEl.current.getBoundingClientRect();

      let top = linkRect.bottom - editorRect.top + 70;
      let left = linkRect.left - editorRect.left;

      const menuWidth = 300;
      const menuHeight = 180;

      if (left + menuWidth > editorRect.width) {
        left = editorRect.width - menuWidth - 50;
      }
      if (left < 0) {
        left = 10;
      }
      if (top + menuHeight > editorRect.height) {
        top = linkRect.top - editorRect.top - menuHeight + 30;
      }

      setLinkEditMenu({
        show: true,
        linkText: linkNode.textContent,
        linkUrl: linkNode.getAttribute('href'),
        linkNode: linkNode,
        position: { top, left },
        isVariable: Boolean(linkNode.getAttribute('variable')),
      });
    }
  };

  const updateLink = () => {
    if (linkEditMenu.linkNode) {
      const { linkNode, linkText, linkUrl } = linkEditMenu;
      linkNode.textContent = linkText;
      linkNode.setAttribute('href', linkUrl || '');
      closeLinkEditMenu();
    }
  };

  const closeLinkEditMenu = () => {
    setLinkEditMenu({
      show: false,
      linkText: '',
      linkUrl: '',
      linkNode: null,
      position: { top: 0, left: 0 },
      isVariable: false,
    });
  };

  const handleLinkTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLinkEditMenu({ ...linkEditMenu, linkText: e.target.value });
  };

  const handleLinkUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLinkEditMenu({ ...linkEditMenu, linkUrl: e.target.value });
  };

  const handlePasteEditor = (e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  };

  const validationTemplateMsg = validTemplate
    ? undefined
    : templateSubject
      ? 'invite_outreach_creators_modal.editor.template_validation.body'
      : 'invite_outreach_creators_modal.editor.template_validation.subject';

  return (
    <div className={classNames(styles.editorContainer, { [styles.hidden]: !isActive })}>
      <div className={styles.editorContent}>
        <div className={styles.editorWrapper}>
          <div className={classNames(styles.buttonPanel, styles.onTop)}>
            <Text
              type="md"
              msg="invite_outreach_creators_modal.editor.template_subject"
              className={styles.subjectTitle}
            />
            <div
              ref={subjectInputEl}
              contentEditable
              onInput={handleChangeTemplateSubject}
              className={styles.subjectInput}
            />
            <div className={styles.buttonPanelRow}>
              <Tooltip place="bottom" id="creatorActivity" tooltipMsg={validationTemplateMsg}>
                <div className={validTemplate ? styles.validTemplate : styles.invalidTemplate}>
                  {validTemplate ? (
                    <Icon name="Check" size={14} color="white" />
                  ) : (
                    <Text type="md" text={1} />
                  )}
                </div>
              </Tooltip>
            </div>
          </div>
          <div
            ref={editorEl}
            onClick={openLinkEditMenu}
            contentEditable
            onInput={handleInput}
            onKeyDown={handleKeyInput}
            onPaste={handlePasteEditor}
            className={styles.editor}
          />
          {linkEditMenu.show && (
            <div
              style={{
                top: `${linkEditMenu.position.top}px`,
                left: `${linkEditMenu.position.left}px`,
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }}
              className={classNames(menuStyles.buttonMenu, {
                [menuStyles.show]: linkEditMenu.show,
              })}
            >
              <Input
                label="Link URL *"
                forceLabelShow
                hideCloseIcon
                bordered
                placeholder="https://..."
                labelClassName={menuStyles.label}
                className={menuStyles.menuInput}
                value={linkEditMenu.linkUrl || ''}
                disabled={linkEditMenu.isVariable}
                onChange={handleLinkUrlChange}
              />
              <Input
                label="Link text"
                forceLabelShow
                hideCloseIcon
                bordered
                placeholder="Any text"
                labelClassName={menuStyles.label}
                className={menuStyles.menuInput}
                value={linkEditMenu.linkText || ''}
                onChange={handleLinkTextChange}
              />
              <div className={menuStyles.buttonContainer}>
                <AlterButton
                  hover={false}
                  className={menuStyles.button}
                  text="Save"
                  onClick={updateLink}
                />
                <AlterButton
                  hover={false}
                  className={menuStyles.button}
                  text="Cancel"
                  onClick={closeLinkEditMenu}
                />
              </div>
            </div>
          )}
          <div className={styles.buttonPanel}>
            <div className={styles.buttonBetweenRow}>
              <div className={styles.buttonPanelRow}>
                <button
                  className={classNames(styles.control, styles.variable)}
                  onClick={() => insertVariable('creator_name')}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <span data-variable="true">Creator name</span>
                </button>
                <button
                  className={classNames(styles.control, styles.variable)}
                  onClick={() => insertVariable('brand_name')}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <span data-variable="true">Brand name</span>
                </button>
                <button
                  className={classNames(styles.control, styles.variable)}
                  onClick={() => insertVariable('action_url')}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <span data-variable="true">Apply link</span>
                </button>
                <div className={styles.editorSelectWrapper}>
                  <select
                    className={classNames(styles.editorSelect, styles.variables)}
                    onChange={handleSelectVariable}
                    tabIndex={-1}
                    key={lastUsedVariable.current}
                  >
                    <option selected disabled hidden>
                      {'{{variables}}'}
                    </option>
                    {Object.keys(variables || []).map((item: keyof TemplateSpecification) => {
                      return (
                        <option key={item} disabled={!variables[item]} value={item}>
                          <Text
                            type="md"
                            msg={`invite_outreach_creators_modal.editor.variable.${item}`}
                          />
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className={styles.editorSelectWrapper}>
                  <select
                    className={classNames(styles.editorSelect, styles.headers)}
                    onChange={handleSelectHeading}
                  >
                    <option selected disabled hidden></option>
                    <option value="p">Paragraph</option>
                    <option value="h1">Heading 1</option>
                    <option value="h2">Heading 2</option>
                    <option value="h3">Heading 3</option>
                    <option value="h4">Heading 4</option>
                    <option value="h5">Heading 5</option>
                    <option value="h6">Heading 6</option>
                  </select>
                </div>
                <button className={styles.control} onClick={formatTextToBold}>
                  <Icon name="Bold" size={20} />
                </button>
                <button className={styles.control} onClick={formatTextToItalic}>
                  <Icon name="Italic" />
                </button>
                <button className={styles.control} onClick={formatTextToUnderline}>
                  <Icon name="Underline" />
                </button>
                <button className={styles.control} onClick={formatTextToStrike}>
                  <Icon name="Strikethrough" />
                </button>
                <div className={styles.delimiter} />
                <button className={styles.control} onClick={formatTextToOrderedList}>
                  <Icon name="Orderd-list" />
                </button>
                <button className={styles.control} onClick={formatTextToUnorderedList}>
                  <Icon name="Bullited-list" />
                </button>
                <AddLinkControl saveSelection={saveSelection} onClick={handleAddLink} />
                <AddImageControl saveSelection={saveSelection} onClick={handleAddImage} />
              </div>
            </div>
          </div>
          {errorText && <Text type="md" className={styles.errorText} text={errorText} />}
        </div>
      </div>
    </div>
  );
};

export default TemplateEditor;
